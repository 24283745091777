import styled from 'styled-components'

const CourseDemoStyled = styled.div`
  width: 100%;
  max-height: 600px;
  overflow: hidden;
  overflow-y: auto;
`

export default CourseDemoStyled
