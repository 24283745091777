"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../../../elements/elements/Button/constants");
const theme_1 = require("../../theme");
const font_1 = require("../../font");
const genStyle_1 = require("../genStyle");
const styleSchema = {
    icon: {
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
    button: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryColor }),
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
    hover: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.focusedColor }),
    },
    disabled: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.inactiveColor }),
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
    border: {
        borderStyle: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderStyle }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
        borderRadius: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderRadius }),
    },
    padding: {
        paddingTop: (0, genStyle_1.genStyle)({ value: 16 }),
        paddingLeft: (0, genStyle_1.genStyle)({ value: 48 }),
    },
};
const fontSchema = {
    base: Object.assign(Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base), { color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.lightTextColor }) }),
};
exports.default = { [constants_1.TYPE]: { style: styleSchema, font: fontSchema } };
