import { VideoSchemaType } from '@leenda/editor/lib/brand'
import styled from 'styled-components'

import { hover } from 'services/Branding/common.styled'
import { ElementStyleCss } from 'services/Branding/types'

const PlayStyled = styled.div<{ $styles: ElementStyleCss<VideoSchemaType>; $full: boolean }>`
  transition: 0.2s;
  display: flex;
  width: ${({ $full }) => ($full ? '56px' : '32px')};
  height: ${({ $full }) => ($full ? '56px' : '32px')};
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-left: 3px;
  border-radius: 50%;
  font-size: 40px;
  cursor: pointer;
  color: white;
  background-color: ${({ $styles }) => $styles.play?.backgroundColor};
  ${hover}
`

export default PlayStyled
