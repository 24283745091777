import cn from 'classnames'
import React from 'react'

import s from './LayoutRow.module.scss'

interface ILayoutRow {
  styleType?: 'default' | 'clear' | 'sub' | 'project'
  justify?: 'between' | 'center' | 'left' | 'right'
  children?: React.ReactNode
  horizontalFree?: boolean
  minHeight?: number
}

const LayoutRow: React.FC<ILayoutRow> = ({
  children,
  styleType = 'default',
  justify,
  minHeight,
  horizontalFree,
}) => {
  const cnRoot = cn(s.root, s[styleType], justify && s[justify], horizontalFree && s.horizontalFree)

  return (
    <div className={cnRoot} style={{ minHeight }}>
      {children}
    </div>
  )
}

export default LayoutRow
