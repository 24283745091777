import { SlateElementType } from '@leenda/rich-text'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import {
  getSelectedFormat,
  getSetToolbarForm,
  getStyles,
  getUpdateFormat,
  useRichTextContext,
} from 'components/form/RichText/useRichTextContext'
import { IconButton } from 'components/uiKit/Button'
import { KitSize } from 'components/uiKit/KitTypes'
import TextArea from 'components/uiKit/TextArea'
import { t } from 'services/Translation'

import { useToolbarPosition } from '../../useToolbarPosition'
import s from './AnnotationForm.module.scss'

const AnnotationForm: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null)
  const styles = useRichTextContext(getStyles)
  const textRef = useRef<HTMLTextAreaElement | null>(null)
  const selectedFormat = useRichTextContext(getSelectedFormat)
  const [value, setValue] = useState<string>(selectedFormat.inline?.value || '')
  const [hiddenDivRef, setHiddenDivRef] = useState<HTMLDivElement | null>(null)
  const setToolbarForm = useRichTextContext(getSetToolbarForm)
  const onUpdateFormat = useRichTextContext(getUpdateFormat)

  const getNumericLineHeight = (element: HTMLElement): number => {
    const lineHeight = getComputedStyle(element).lineHeight
    if (lineHeight === 'normal') {
      const fontSize = parseInt(getComputedStyle(element).fontSize, 10)
      return fontSize * 1.2
    }
    return parseFloat(lineHeight)
  }
  const handleClose = useCallback(() => setToolbarForm('default'), [setToolbarForm])

  const onSubmit = () => {
    onUpdateFormat(SlateElementType.annotation, value)
    handleClose()
  }

  const onDelete = () => {
    onUpdateFormat(SlateElementType.annotation, null)
    handleClose()
  }

  useEffect(() => {
    if (value && hiddenDivRef) {
      hiddenDivRef.textContent = value
    }
  }, [hiddenDivRef, value])

  useEffect(() => {
    setValue(selectedFormat.inline?.value)
  }, [selectedFormat.inline?.value])

  useToolbarPosition(ref)

  return (
    <div className={s.root} ref={ref}>
      <IconButton
        icon='builderInputBack'
        name='cancel'
        onClick={handleClose}
        size={KitSize.S}
        styleType='secondary'
      />
      <TextArea
        autoSize={{ minRows: 1, maxRows: 16 }}
        lineHeight={hiddenDivRef ? getNumericLineHeight(hiddenDivRef) : undefined}
        name='annotation'
        onChange={setValue}
        placeholder={t('input.placeholder.addAnnotation')}
        ref={textRef}
        size='mini'
        style={{ ...styles?.base, backgroundColor: 'var(--brand-primaryBackgroundColor)' }}
        styleType='ghost'
        value={value}
        autoFocus
        fluid
      />
      <div className={s.buttons}>
        {selectedFormat.inline?.value && (
          <IconButton
            icon='otherBrokenLink'
            name='delete'
            onClick={onDelete}
            size={KitSize.S}
            styleType='ghost'
          />
        )}
        <IconButton
          disabled={!value}
          icon={value ? 'iconSend' : 'iconSendDisabled'}
          name='save'
          onClick={onSubmit}
          size={KitSize.S}
          styleType='ghost'
        />
      </div>
      <div className={s.hiddenElement} ref={setHiddenDivRef} style={styles?.base} />
    </div>
  )
}

export default AnnotationForm
