import { DeviceMode } from '@leenda/editor'
import { SidebarFontSchemaType, SidebarSchemaType } from '@leenda/editor/lib/brand'
import { SectionTypeEnum } from '@leenda/editor/lib/elements'
import lodash from 'lodash'

import { ISectionTreeNode } from 'components/sections/ProjectStructure/utils'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { EMPTY_OBJECT } from 'constants/commonConstans'
import { BrandType, ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { Section } from 'services/Store/Project/types'

const FONTS_SECTION = ['color', 'fontFamily', 'fontWeight', 'fontStyle', 'fontSize', 'lineHeight']

export const NameMap = {
  [SectionTypeEnum.landing]: (pt: (key: keyof BrandType['labels']) => string) =>
    pt('courseStructure.landing'),
  [SectionTypeEnum.test]: (pt: (key: keyof BrandType['labels']) => string) =>
    pt('courseStructure.test'),
  [SectionTypeEnum.cover]: (pt: (key: keyof BrandType['labels']) => string) =>
    pt('courseStructure.cover'),
  [SectionTypeEnum.chapter]: (pt: (key: keyof BrandType['labels']) => string) =>
    pt('courseStructure.chapter'),
}

export const getIconData = ({
  type,
  styles,
  font,
  nestedOffset,
  disabled,
}: {
  type: SectionTypeEnum
  styles: ElementStyleCss<SidebarSchemaType>
  font: ElementFontCss<SidebarFontSchemaType>
  nestedOffset?: number
  disabled?: boolean
}) => {
  const IconSectionsMap = {
    [SectionTypeEnum.landing]: <Icon name='contentsLanding' size={KitSize.S} />,
    [SectionTypeEnum.test]: <Icon name='contentsTest' size={KitSize.S} />,
    [SectionTypeEnum.chapter]: <Icon name='contentsChapter' size={KitSize.S} />,
    [SectionTypeEnum.cover]: null,
  }

  const showIconMap = {
    [SectionTypeEnum.chapter]: styles?.chapterIcons.showIcons,
    [SectionTypeEnum.landing]: styles?.sectionIcons.showIcons,
    [SectionTypeEnum.test]: styles?.testIcons.showIcons,
    [SectionTypeEnum.cover]: false,
  }

  const chapterFont = lodash.pick(font?.chapter, FONTS_SECTION)
  const sectionFont = lodash.pick(font?.section, FONTS_SECTION)

  // const { marginBottom: _paddingTopChapter, ...chapterFont } = font?.chapter
  // const { marginBottom: _paddingTopSection, ...sectionFont } = font?.section

  const fontIconMap = {
    [SectionTypeEnum.chapter]: chapterFont,
    [SectionTypeEnum.landing]: sectionFont,
    [SectionTypeEnum.test]: sectionFont,
    [SectionTypeEnum.cover]: EMPTY_OBJECT,
  }

  const IconImageMap = {
    [SectionTypeEnum.chapter]: styles?.chapterIcons.backgroundImage,
    [SectionTypeEnum.landing]: styles?.sectionIcons.backgroundImage,
    [SectionTypeEnum.test]: styles?.testIcons.backgroundImage,
    [SectionTypeEnum.cover]: null,
  }

  const showIcon = showIconMap[type]
  const IconImage = IconImageMap[type]
  const icon = IconSectionsMap[type]
  const styleFontSection = {
    ...fontIconMap[type],
    ...(disabled && { color: styles.item_disable.color }),
  }

  const iconStyles = {
    ...(IconImage && { backgroundImage: IconImage }),
    marginRight: styles?.margins.offsetFromIcon,
    marginLeft: nestedOffset,
  }

  const sectionNameStyles = { ...styles?.root, ...(!showIcon && { marginLeft: nestedOffset }) }

  return { showIcon, IconImage, styleFontSection, icon, sectionNameStyles, iconStyles }
}

export const getSectionHasFirstBlock = (nodes: ISectionTreeNode<Section>[]) => {
  return nodes?.find(({ item }) => {
    if (!item.isChapter) {
      const { blocks = [], blocksOrder = [] } = item || {}
      const blockNumbers = blocks
        .filter(({ isHide }) => !isHide)
        .map(({ uuid }) => blocksOrder.findIndex((id) => uuid === id))?.[0]
      const firstBlockId = blocksOrder[blockNumbers]
      if (firstBlockId) {
        return item
      }
    }
    return undefined
  })?.item
}

export const parseStyleValue = (value?: string | number) => {
  if (typeof value === 'string') {
    return parseFloat(value) || 0
  }
  return value || 0
}
