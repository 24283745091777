import cn from 'classnames'
import React from 'react'

import { KitSize } from 'components/uiKit/KitTypes'
import Loader from 'components/uiKit/Loader'
import { testProps } from 'utils/test/qaData'

import s from './Button.module.scss'

type ButtonDefaultType = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

export interface IButtonPropsType extends ButtonDefaultType {
  /**
   * String for QA auto tests.
   */
  name: string
  /**
   * Whether the button is active or not.
   */
  active?: boolean
  /**
   * The size of the button.
   */
  size?: KitSize
  /**
   * Whether the button should take up the full width of its container.
   */
  fluid?: boolean
  /**
   * The style type of the button.
   */
  styleType?: 'primary' | 'secondary' | 'ghost' | 'accent' | 'free'
  /**
   * Whether the button is in a loading state.
   */
  loading?: boolean
  /**
   * Whether the button should have rounded edges.
   */
  round?: boolean
  /**
   * Whether the button is disabled or not.
   */
  disabled?: boolean
  /**
   * Whether the button should inherit its color from its parent.
   */
  inheritColor?: boolean
  /**
   * The content of the button. Can be text, an Icon, or both.
   */
  children: React.ReactNode
  testData?: string
}

const Button = React.forwardRef<HTMLButtonElement, IButtonPropsType>(
  (
    {
      name,
      active,
      size = KitSize.M,
      fluid,
      styleType = 'primary',
      loading,
      round,
      disabled = false,
      children,
      inheritColor,
      testData,
      type = 'button',
      ...htmlProps
    },
    ref,
  ) => {
    const { value, onClick, className, tabIndex, ...restHtmlProps } = htmlProps

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      !disabled && !loading && onClick?.(e)
    }

    const buttonClasses = cn(s.button, className, s[styleType], {
      [s[size]]: styleType !== 'free',
      [s.disabled]: disabled,
      [s.loading]: loading,
      [s.active]: active,
      [s.fluid]: fluid,
      [s.round]: round,
      [s.inheritColor]: inheritColor,
    })

    return (
      <button
        {...restHtmlProps}
        {...testProps({ el: 'button', name, value, disabled: Boolean(disabled), testData })}
        className={buttonClasses}
        name={name}
        onClick={handleClick}
        ref={ref}
        tabIndex={disabled ? -1 : tabIndex || 0}
        type={type}
        value={value}
      >
        {styleType === 'free' ? (
          children
        ) : (
          <div className={cn(s.content, fluid && s.fluid)}>{children}</div>
        )}
        {loading && (
          <Loader
            color={styleType === 'primary' || styleType === 'accent' ? 'background' : 'primary'}
            name='buttonLoader'
            styleType='svg'
          />
        )}
      </button>
    )
  },
)

Button.displayName = 'Button'

export default Button
