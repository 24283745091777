import { FileUsage } from '@leenda/editor/lib/files'

import { IControlProps } from 'components/controls/Field/Field.types'
import FileLoader from 'components/uiKit/FileLoader'
import { IFieldFileCommon } from 'components/uiKit/FileLoader/FileLoader'

export interface IFieldFileExtended extends IFieldFileCommon {
  value: FileUsage
}

const FieldFile: React.FC<IControlProps<IFieldFileExtended>> = ({
  value,
  onChange,
  name,
  fileType,
  defaultValue,
  onFocus,
  onBlur,
  ...rest
}) => {
  return (
    <FileLoader
      fileType={fileType}
      name={name}
      onBlur={onBlur}
      // @ts-ignore
      onChange={onChange}
      onFocus={onFocus}
      source='file'
      value={value ?? defaultValue}
      {...rest}
    />
  )
}

export default FieldFile
