import { SCHEMA } from '@leenda/editor/lib/brand'

import { genField } from 'components/controls/Field'
import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.courseStructure.style, {
  chapterIcons: {
    META: { label: t('elements.courseStructure.tags.icon') },
    backgroundImage: {
      label: t('elements.courseStructure.schema.chapterIcon'),
      params: { preview: false, resettable: true },
    },
  },
  sectionIcons: {
    backgroundImage: {
      label: t('elements.courseStructure.schema.landingIcon'),
      params: { preview: false, resettable: true },
    },
  },
  testIcons: {
    backgroundImage: {
      label: t('elements.courseStructure.schema.testIcon'),
      params: { preview: false, resettable: true },
    },
  },
  item: {
    META: { label: t('elements.courseStructure.tags.section') },
    color: { label: t('elements.courseStructure.schema.disabledBg') },
  },
  item_hover: {
    backgroundColor: { label: t('elements.courseStructure.schema.hoverBg') },
  },
  progress: {
    META: { label: t('elements.courseStructure.tags.progress') },
    backgroundColor: { label: t('input.label.progressBackground') },
    color: { label: t('input.label.progressCompleted') },
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
  },
  offsetFromIcon: {
    META: { label: t('elements.courseStructure.tags.margins') },
    marginRight: { label: t('input.label.iconIndents') },
  },
  offsetFromSection: {
    META: { hidden: true },
    marginTop: { label: t('elements.courseStructure.schema.sectionIndents') },
  },
  offsetFromProgress: {
    META: { hidden: true },
    marginRight: { label: t('elements.courseStructure.schema.progressIndents') },
  },
  offsetFromCollapse: {
    META: { hidden: true },
    marginRight: { label: t('elements.courseStructure.schema.collapseIndents') },
  },
  collapseIcon: {
    META: { label: t('course.sidebar.tags.collapse') },
    backgroundImage: {
      label: t('course.sidebar.tags.collapse.collapseIconClosed'),
      params: {
        preview: false,
        resettable: true,
      },
    },
    backgroundOpen: genField({
      type: 'fileId',
      layout: 'vertical',
      label: t('course.sidebar.tags.collapse.collapseIconOpen'),
      params: { preview: false, fileType: 'image' },
    }),
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.courseStructure.font, {
  chapter: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.courseStructure.tags.chapter') },
  },
  section: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.courseStructure.tags.section') },
  },
  description: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.courseStructure.tags.description') },
  },
})
