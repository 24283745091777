import { GroupingItem, GroupingGroup } from '@leenda/editor/lib/elements'
import { useState, useEffect, useMemo } from 'react'

import { DeviceMode } from 'services/Store/Project/enums'
import { shuffle } from 'utils/shuffle'

import { useElementCompleted } from '../../hooks/useElementCompleted'
import { useElementRootStyles } from '../../hooks/useElementRootStyles'
import GroupingContext from './GroupingContext'
import s from './GroupingElement.module.scss'
import { GroupingElementType } from './GroupingElement.types'
import DefaultElement from './components/DefaultView/DefaultElement'
import { ValidationMap } from './components/DefaultView/PortalGroupingItem/PortalGroupingItem'
import MobileElement from './components/MobileView/MobileElement'
import { getCardsData, sortSourceByPreviewList } from './helper'

const GroupingElement: GroupingElementType = ({ element, styles, mode, block, font }) => {
  const items = element.value.items as GroupingGroup[]
  const [shuffledAnswers, setShuffledAnswers] = useState<GroupingItem[]>([])
  const [validationMap, setValidationMap] = useState<ValidationMap>({})
  const [testResult, setTestResult] = useState<{ [key: string]: boolean }>({})
  const [retry, setRetry] = useState(0)
  const [destination, setDestination] = useState<GroupingGroup[]>([])
  const hasAnswerImage = element.value.hasAnswerImage
  const hasGroupImage = element.value.hasGroupImage
  const isMobile = mode.deviceMode === DeviceMode.mobile

  const rootStyles = useElementRootStyles(styles.root)

  const contextValue = {
    destination,
    hasGroupImage,
    hasAnswerImage,
    styles,
    mode,
    retry,
    shuffledAnswers,
    testResult,
    validationMap,
    font,
    setDestination,
    setShuffledAnswers,
    setRetry,
    setTestResult,
    setValidationMap,
  }

  const setDataCards = (
    shuffledSource: GroupingItem[],
    dest: GroupingGroup[],
    validationMap: ValidationMap,
  ) => {
    setShuffledAnswers(shuffledSource)
    setValidationMap(validationMap)
    setDestination(dest)
  }

  // for shuffle only than, when added or removed any answer of any cards (allAnswersOfCarts.length)
  const allAnswersOfCarts = useMemo(() => {
    const { dest, source, validationMap } = getCardsData(items)
    const sortSource = sortSourceByPreviewList(source, shuffledAnswers)
    setDataCards(sortSource, dest, validationMap)
    return source
  }, [items])

  useEffect(() => {
    const { dest, source, validationMap } = getCardsData(items)
    const shuffledSource = shuffle(source)
    setDataCards(shuffledSource, dest, validationMap)
    // dependency allAnswersOfCarts.length need for shuffle
    // when change quantity(allAnswersOfCarts.length) answers of cards
  }, [allAnswersOfCarts.length, retry])

  const testResultNotEmpty = Object.keys(testResult).length > 0

  const completed = testResultNotEmpty && !shuffledAnswers.length

  useElementCompleted(block?.uuid || '', element.id, completed)

  return (
    <div className={s.root} style={rootStyles}>
      <GroupingContext.Provider value={contextValue}>
        {isMobile ? <MobileElement /> : <DefaultElement />}
      </GroupingContext.Provider>
    </div>
  )
}
export default GroupingElement
