import { TestSingleChoiceSchemaType } from '@leenda/editor/lib/brand'
import styled from 'styled-components'

import { border } from 'services/Branding/common.styled'
import { ElementStyleCss } from 'services/Branding/types'

import MarkStyled from './MarkStyled'

const RadioStyled = styled.label<{
  $styles: ElementStyleCss<TestSingleChoiceSchemaType>
  $disabled?: boolean
}>`
  position: relative;
  display: flex;
  max-width: 100%;
  align-items: center;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  padding: ${({ $styles }) => $styles.text.padding};
  ${({ $styles }) => border($styles.border)}
  background-color: ${({ $styles }) => $styles?.item?.backgroundColor};
  input {
    position: absolute;
    width: 0;
    height: 0;
    cursor: pointer;
    opacity: 0;
  }
  &:hover {
    background-color: ${({ $styles }) => $styles.item_hover.backgroundColor};
  }

  &:hover ${MarkStyled} {
    border-color: ${({ $disabled, $styles }) => !$disabled && $styles.radio.color};
  }
`

export default RadioStyled
