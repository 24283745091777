import RealtimeField from 'components/controls/RealtimeField'
import Collapse from 'components/uiKit/Collapse'
import { Typography } from 'components/uiKit/Typography'
import { FULL_COLLAPSE_PADDING } from 'constants/styles'
import { t } from 'services/Translation'
import { chainName } from 'utils/form'

import { ChartValueFormType } from './ChartTypes'
import { description, type, x, y, items } from './chartFormConfig'

const ChartForm: ChartValueFormType = ({ name }) => {
  return (
    <>
      <Collapse
        header={t('elements.chart.form.settings')}
        iconPosition='right'
        name='settings'
        paddings={FULL_COLLAPSE_PADDING}
        initialOpen
      >
        <RealtimeField config={chainName(name, type)} />
        <RealtimeField config={chainName(name, description)} />
        <RealtimeField config={chainName(name, x)} />
        <RealtimeField config={chainName(name, y)} />
      </Collapse>
      <Collapse
        header={t('elements.chart.form.points')}
        iconPosition='right'
        name='label'
        paddings={FULL_COLLAPSE_PADDING}
        initialOpen
      >
        <Typography styleType='hint'>{t('elements.chart.form.axisHint')}</Typography>
        <RealtimeField config={chainName(name, items)} />
      </Collapse>
    </>
  )
}

export default ChartForm
