import { IControlProps } from 'components/controls/Field/Field.types'
import ColorPicker, { IColorPickerProps } from 'components/uiKit/ColorPicker'

const FieldColorPicker: React.FC<IControlProps<IColorPickerProps>> = ({
  name,
  onChange,
  ...rest
}) => {
  const handleOnChange = (value: string | null) => onChange({ name, value })

  return <ColorPicker {...rest} name={name} onChange={handleOnChange} />
}

export default FieldColorPicker
