import { useCourseSidebarData } from 'components/editor-v3/cource/controls/CourseSidebar/context/selectors'
import { t } from 'services/Translation'

import { StructureItemCourse } from '../StructureItemCourse'
import s from './StructureSearch.module.scss'

interface ISearchResultProps {
  searchValue: string
  onSelect: (sectionId: string) => void
}

const StructureSearchResult = ({ searchValue, onSelect }: ISearchResultProps) => {
  const { sections } = useCourseSidebarData()
  const resultSections = sections.filter(
    (e) => !e.isChapter && e.name?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()),
  )

  return (
    <div>
      <div className={s.title}>
        {t('input.label.result')}
        <span className={s.searchNum}>{resultSections.length}</span>
      </div>
      <div className={s.resultsContent}>
        {resultSections.map((item) => {
          return (
            <div className={s.resultItem} key={item.id}>
              <StructureItemCourse item={item} onSelect={onSelect} searchValue={searchValue} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default StructureSearchResult
