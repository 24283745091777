import { TestSingleChoiceSchemaType } from '@leenda/editor/lib/brand'
import styled from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

const LabelStyled = styled.div<{ $styles: ElementStyleCss<TestSingleChoiceSchemaType> }>`
  max-width: 100%;
  flex-grow: 1;
  padding: 0 8px;
  word-break: break-word;
  overflow: hidden;
`

export default LabelStyled
