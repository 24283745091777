import { LabeledGraphicSchemaType } from '@leenda/editor/lib/brand'
import styled from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

const PointStyled = styled.div<{ $styles: ElementStyleCss<LabeledGraphicSchemaType> }>`
  position: relative;
`

export default PointStyled
