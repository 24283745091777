import { SidebarSchemaType } from '@leenda/editor/lib/brand'
import { CourseStructureItem } from '@leenda/editor/lib/elements'
import lodash from 'lodash'
import React, { useMemo } from 'react'

import Progress, { ProgressType } from 'components/editor-v3/cource/components/Progress'
import { useCourseSidebarData } from 'components/editor-v3/cource/controls/CourseSidebar/context/selectors'
import { parseStyleValue } from 'components/editor-v3/cource/controls/CourseSidebar/utils'
import {
  getNextSection,
  ISectionTreeNode,
  listToTree,
} from 'components/sections/ProjectStructure/utils'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { ElementStyleCss } from 'services/Branding/types'
import { testPropsEl } from 'utils/test/qaData'

import s from './SectionStatus.module.scss'

const HEIGHT_STATUS_BAR_HEIGHT = 24
const DEFAULT_OFFSET_FROM_CIRCLE = 4

const getStyleLine = ({
  styles,
  direction,
  showLine,
}: {
  styles: ElementStyleCss<SidebarSchemaType>
  direction?: 'top' | 'bottom'
  showLine?: boolean
}) => {
  if (!showLine) {
    return
  }

  const borderWidthStyle = styles?.verticalProgress.borderWidth || 1
  const hideBorder = styles?.verticalProgress.borderStyle === 'none'
  const borderWidth = parseStyleValue(borderWidthStyle)
  const sectionBetween = styles?.margins.offsetYFromSection || 0
  const bottom =
    HEIGHT_STATUS_BAR_HEIGHT -
    borderWidth +
    DEFAULT_OFFSET_FROM_CIRCLE +
    (hideBorder ? borderWidth : 0)
  const top =
    -HEIGHT_STATUS_BAR_HEIGHT -
    sectionBetween * 2 -
    borderWidth -
    DEFAULT_OFFSET_FROM_CIRCLE +
    (hideBorder ? borderWidth : 0)
  const left = hideBorder
    ? Math.ceil(HEIGHT_STATUS_BAR_HEIGHT / 2 - borderWidth / 2)
    : Math.ceil(HEIGHT_STATUS_BAR_HEIGHT / 2 - borderWidth / 2 - borderWidth)

  return {
    bottom: direction === 'bottom' ? top : bottom,
    top: direction === 'bottom' ? bottom : top,
    borderWidth,
    borderColor: styles?.verticalProgress.borderColor,
    left,
  }
}

interface ISectionStatusProps {
  node: ISectionTreeNode<CourseStructureItem>
  open?: boolean
  disabled: boolean
  hiddenProgress?: boolean
}

const SectionStatus: React.FC<ISectionStatusProps> = ({
  node,
  disabled,
  open,
  hiddenProgress = false,
}) => {
  const { item, isLast } = node
  const { styles, getSectionProgress, sections } = useCourseSidebarData()
  const sectionTree = listToTree(sections)
  const nextSection = getNextSection(sectionTree, item.id)
  const progress = useMemo(() => getSectionProgress(item.id), [getSectionProgress, item.id])
  const progressStyle = lodash.pick(styles?.verticalProgress, [
    'borderStyle',
    'borderColor',
    'borderWidth',
    'backgroundColor',
  ])
  const hasParent = item.lvl > 0
  const hasChildren = Boolean(node.children?.length)
  const showBottomLine =
    (item.lvl === 0 ? open : true) &&
    (hasChildren || (!isLast && hasParent && (nextSection?.item.lvl || 0) !== 0))
  const styleLine = getStyleLine({ styles, direction: 'top', showLine: hasParent })
  const styleLineBottom = getStyleLine({ styles, direction: 'bottom', showLine: showBottomLine })
  const strokeWidth = useMemo(
    () => parseStyleValue(styles?.verticalProgress?.borderWidth),
    [styles?.verticalProgress?.borderWidth],
  )

  return (
    <div
      {...testPropsEl('sectionStatus', { value: progress, name: item.name }, { id: item.id })}
      className={s.root}
      style={{ borderWidth: strokeWidth, borderStyle: styles?.verticalProgress.borderStyle }}
    >
      {!disabled && (
        <div className={s.status} style={progressStyle}>
          {!hiddenProgress && (
            <div className={s.progress}>
              {progress >= 100 ? (
                <div className={s.statusDone} style={{ color: styles?.verticalProgress.color }}>
                  <Icon name='alertCheck' />
                </div>
              ) : progress > 0 ? (
                <Progress
                  customStyles={{
                    stroke: styles.verticalProgress?.color,
                    trail:
                      progressStyle.borderStyle === 'none'
                        ? styles?.verticalProgress.backgroundColor
                        : styles?.verticalProgress.borderColor,
                  }}
                  name='sectionStatus'
                  percent={progress}
                  size='medium'
                  strokeWidth={strokeWidth}
                  styleType={ProgressType.circle}
                />
              ) : null}
            </div>
          )}
          {styleLine && <div className={s.line} style={styleLine} />}
          {styleLineBottom && <div className={s.line} style={styleLineBottom} />}
        </div>
      )}
      {disabled && (
        <>
          {!hiddenProgress && (
            <div className={s.lockIcon} style={progressStyle}>
              <Icon
                name='builderLock'
                size={KitSize.XS}
                style={{ color: 'var(--brand-secondaryTextColor)' }}
              />
            </div>
          )}
          {styleLine && <div className={s.line} style={styleLine} />}
          {styleLineBottom && <div className={s.line} style={styleLineBottom} />}
        </>
      )}
    </div>
  )
}

export default SectionStatus
