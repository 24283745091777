import { TestMultipleChoiceSchemaType } from '@leenda/editor/lib/brand'
import React, { useCallback, useId } from 'react'

import { ElementStyleCss } from 'services/Branding/types'
import { testPropsEl } from 'utils/test/qaData'

import CheckboxStyled from '../../styled/CheckboxStyled'
import LabelStyled from '../../styled/LabelStyled'
import MarkStyled from '../../styled/MarkStyled'

// import s from './Checkbox.module.scss'

interface ICheckboxProps {
  disabled?: boolean
  checked: boolean
  onChange?: (value: boolean) => void
  setIsValid?: (value: boolean) => void
  styles: ElementStyleCss<TestMultipleChoiceSchemaType>
  value: string
  testData: string
  isEditor?: boolean
  children: React.ReactNode
}

const Checkbox: React.FC<ICheckboxProps> = ({
  disabled,
  checked,
  onChange,
  setIsValid,
  children,
  styles,
  value,
  testData,
  isEditor,
}) => {
  const id = useId()
  const handleOnChange = useCallback(() => onChange?.(!checked), [checked, onChange])
  const handleSetIsValid = useCallback(() => setIsValid?.(!checked), [checked, setIsValid])

  return (
    <CheckboxStyled
      {...testPropsEl('courseCheckbox', { value }, { checked, testData })}
      // className={cn(s.root, { [s.disabled]: disabled, [s.checked]: checked })}
      $disabled={disabled}
      $styles={styles}
      htmlFor={id}
    >
      <input
        checked={checked}
        disabled={disabled}
        id={isEditor ? undefined : id}
        onChange={handleOnChange}
        type='checkbox'
      />
      <MarkStyled
        {...testPropsEl('checkmarkCheckbox', { value }, { checked, testData })}
        $checked={checked}
        $disabled={disabled}
        $styles={styles}
        // className={s.checkmark}
        onClick={isEditor ? handleSetIsValid : undefined}
      />
      <LabelStyled /* className={s.label} */ $styles={styles}>{children}</LabelStyled>
    </CheckboxStyled>
  )
}

export default React.memo(Checkbox)
