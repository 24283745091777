import { CourseStructureSchemaType } from '@leenda/editor/lib/brand'
import { CourseStructureItem, SectionTypeEnum } from '@leenda/editor/lib/elements'
import lodash from 'lodash'
import { useMemo } from 'react'

import { parseStyleValue } from 'components/editor-v3/cource/controls/CourseSidebar/utils'
import {
  getNextSection,
  ISectionTreeNode,
  listToTree,
} from 'components/sections/ProjectStructure/utils'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { ElementStyleCss } from 'services/Branding/types'
import { Section } from 'services/Store/Project/types'
import { testPropsEl } from 'utils/test/qaData'

import Progress from '../Progress'
import s from './SectionStatus.module.scss'

interface ISectionStatusProps {
  node: ISectionTreeNode<CourseStructureItem>
  open?: boolean
  disabled: boolean
  styles: ElementStyleCss<CourseStructureSchemaType>
  sections: Section[]
  getSectionProgress: (id: string) => number
}

const HEIGHT_STATUS_BAR_HEIGHT = 24
const DEFAULT_OFFSET_FROM_CIRCLE = 4

const getStyleLine = ({
  styles,
  direction,
  showLine,
}: {
  styles: ElementStyleCss<CourseStructureSchemaType>
  direction?: 'top' | 'bottom'
  showLine?: boolean
}) => {
  if (!showLine) {
    return
  }

  const borderWidthStyle = styles?.progress.borderWidth || 1
  const hideBorder = styles?.progress.borderStyle === 'none'
  const borderWidth = parseStyleValue(borderWidthStyle)
  const sectionBetween = parseStyleValue(styles.offsetFromSection.marginTop)
  const bottom =
    HEIGHT_STATUS_BAR_HEIGHT -
    borderWidth +
    DEFAULT_OFFSET_FROM_CIRCLE +
    (hideBorder ? borderWidth : 0)
  const top =
    -HEIGHT_STATUS_BAR_HEIGHT -
    sectionBetween * 2 -
    borderWidth -
    DEFAULT_OFFSET_FROM_CIRCLE +
    (hideBorder ? borderWidth : 0)
  const left = hideBorder
    ? Math.ceil(HEIGHT_STATUS_BAR_HEIGHT / 2 - borderWidth / 2)
    : Math.ceil(HEIGHT_STATUS_BAR_HEIGHT / 2 - borderWidth / 2 - borderWidth)

  return {
    bottom: direction === 'bottom' ? top : bottom,
    top: direction === 'bottom' ? bottom : top,
    borderWidth,
    borderColor: styles?.progress.borderColor,
    left,
  }
}

export const ICON_BG = (
  styles: ElementStyleCss<CourseStructureSchemaType>,
  type: SectionTypeEnum,
) =>
  ({
    [SectionTypeEnum.chapter]: styles.chapterIcons.backgroundImage,
    [SectionTypeEnum.landing]: styles.sectionIcons.backgroundImage,
    [SectionTypeEnum.test]: styles.testIcons.backgroundImage,
    [SectionTypeEnum.cover]: undefined,
  })[type]

export const SectionStatus: React.FC<ISectionStatusProps> = ({
  node,
  open,
  disabled,
  styles,
  sections,
  getSectionProgress,
}) => {
  const { item, isLast } = node
  const sectionTree = listToTree(sections)
  const progress = useMemo(() => getSectionProgress(item.id), [getSectionProgress, item.id])
  const nextSection = getNextSection(sectionTree, item.id)

  const progressStyle = lodash.pick(styles?.progress, [
    'borderStyle',
    'borderColor',
    'borderWidth',
    'backgroundColor',
  ])

  const hasParent = item.lvl > 0
  const hasChildren = Boolean(node.children?.length)
  const showBottomLine =
    (open && hasChildren) || (!isLast && hasParent && nextSection?.item.lvl !== 0)
  const topLineStyle = getStyleLine({ styles, direction: 'top', showLine: hasParent })
  const bottomLineStyle = getStyleLine({ styles, direction: 'bottom', showLine: showBottomLine })

  const borderWidth = useMemo(
    () => parseStyleValue(styles?.progress?.borderWidth),
    [styles?.progress?.borderWidth],
  )

  return (
    <div
      {...testPropsEl('sectionStatus', { value: progress, name: item.name }, { id: item.id })}
      className={s.root}
      style={{ borderWidth, borderStyle: styles?.progress.borderStyle }}
    >
      {!disabled && (
        <div className={s.status} style={progressStyle}>
          <div className={s.progress}>
            {progress >= 100 ? (
              <div className={s.statusDone} style={{ color: styles?.progress.color }}>
                <Icon name='alertCheck' />
              </div>
            ) : progress > 0 ? (
              <Progress
                name='sectionStatus'
                percent={progress}
                stroke={styles.progress?.color}
                strokeWidth={borderWidth}
                trail={
                  progressStyle.borderStyle === 'none'
                    ? styles?.progress.backgroundColor
                    : styles?.progress.borderColor
                }
              />
            ) : null}
          </div>
          {topLineStyle && <div className={s.line} style={topLineStyle} />}
          {bottomLineStyle && <div className={s.line} style={bottomLineStyle} />}
        </div>
      )}
      {disabled && (
        <>
          <div className={s.lockIcon} style={progressStyle}>
            <Icon
              name='builderLock'
              size={KitSize.XS}
              style={{ color: 'var(--brand-secondaryTextColor)' }}
            />
          </div>
          {topLineStyle && <div className={s.line} style={topLineStyle} />}
          {bottomLineStyle && <div className={s.line} style={bottomLineStyle} />}
        </>
      )}
    </div>
  )
}
