import { gql } from 'gql/__generated__'

export const fontsGroupAll = gql(`
  query FontsGroupAll($companyId: ID!) {
    data: fontsGroupAll(companyId: $companyId) {
      ...FontGroupFullSchema
    }
  }
`)

export const fontGroupById = gql(`
  query FontGroupById($companyId: ID!, $id: ID!) {
    data: fontsGroupById(companyId: $companyId, id: $id) {
      ...FontGroupFullSchema
    }
  }
`)
