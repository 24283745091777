import { SlateElementType, SlateMark } from '@leenda/rich-text'
import cn from 'classnames'
import { hasActiveAiConfig, leftAiTokens } from 'entities/AiConfig/helpers'
import { useMemo, useRef } from 'react'

import { IconButton } from 'components/uiKit/Button'
import Checkbox from 'components/uiKit/Checkbox'
import ColorPicker from 'components/uiKit/ColorPicker'
import Divider from 'components/uiKit/Divider'
import Dropdown, { Tooltip } from 'components/uiKit/Dropdown'
import DropdownMenu from 'components/uiKit/DropdownMenu'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { IMenuOption } from 'components/uiKit/Menu'
import Select from 'components/uiKit/Select'
import { PROJECT_PATHS } from 'constants/paths'
import { AiTextPromptTogglesEnum } from 'gql/__generated__/graphql'
import { useMyInfo } from 'gql/actions.helpers'
import { usePathParams } from 'routes/hooks'
import { genColorOptions } from 'services/Branding/constants/fields'
import { useBrandTheme } from 'services/Branding/hooks'
import { t } from 'services/Translation'

import { ALL_RT_CONTROLS } from '../RichTextConstants'
import {
  getUpdateFormat,
  getSetToolbarForm,
  getSelectedFormat,
  useRichTextContext,
  getSetPrompt,
} from '../useRichTextContext'
import HideControl from './HideControl'
import s from './Toolbar.module.scss'
import CheckboxTool from './components/CheckboxTool'
import OtherSettings from './components/OtherSettings/OtherSettings'
import SymbolItem from './components/SymbolItem'
import { AI_OPTIONS, marks, symbols, TEXT_TYPE_OPTIONS } from './toolbarOptions'
import { useToolbarPosition } from './useToolbarPosition'

const MAX_AI_TEXT_LENGTH = 2000

const Toolbar: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null)
  const { companyId } = usePathParams(PROJECT_PATHS.editor)
  const theme = useBrandTheme()
  const colorOptions = useMemo(() => genColorOptions(theme), [theme])
  const { profile } = useMyInfo(companyId)
  const company = profile?.company
  const toMatchSelected = (() => {
    try {
      const selection = window.getSelection()
      return (selection?.getRangeAt(0).toString().length || 0) > MAX_AI_TEXT_LENGTH
    } catch (error) {
      return false
    }
  })()
  const disabledAi = leftAiTokens(company?.aiConfigs) <= 0
  const hasAi = hasActiveAiConfig(company?.aiConfigs)
  const overlayMessage = useMemo(() => {
    if (disabledAi) {
      return t('elements.richText.ai.tooltip')
    }

    return t('elements.richText.ai.toMatchSelected')
  }, [disabledAi])
  const selectedFormat = useRichTextContext(getSelectedFormat)
  const onUpdateFormat = useRichTextContext(getUpdateFormat)
  const setToolbarForm = useRichTextContext(getSetToolbarForm)
  const setPrompt = useRichTextContext(getSetPrompt)

  const textTypeValue =
    TEXT_TYPE_OPTIONS.find((item) => selectedFormat[item.value])?.value ||
    SlateElementType.elementDefault

  const onLinkClick = () => setToolbarForm(SlateElementType.link)

  const onCrossLinkClick = () => setToolbarForm(SlateElementType.crossLink)

  const onAnnotationClick = () => setToolbarForm(SlateElementType.annotation)

  const onAiClick = ({
    value,
  }: IMenuOption<AiTextPromptTogglesEnum | 'prepromptGroup' | 'customGroup'>) => {
    setToolbarForm('ai')
    setPrompt(value as AiTextPromptTogglesEnum)
  }

  useToolbarPosition(ref)

  return (
    <div className={s.root} ref={ref}>
      <HideControl enabled={ALL_RT_CONTROLS} name='blockElement'>
        <Select
          name='textType'
          offset={[4, -10]}
          onChange={(value) => onUpdateFormat(value)}
          options={TEXT_TYPE_OPTIONS}
          size={KitSize.S}
          styleType='ghost'
          value={textTypeValue}
        />
        <Divider indents={{ margin: '0 8px' }} size={KitSize.S} styleType='vertical' />
      </HideControl>
      <HideControl enabled={ALL_RT_CONTROLS} name={['color', 'backgroundColor']}>
        <div className={cn(s.groupControl, s.color)}>
          <HideControl enabled={ALL_RT_CONTROLS} name='color'>
            <ColorPicker
              icon='otherFont'
              name={SlateMark.color}
              offset={[4, 0]}
              onChange={(value) => onUpdateFormat(SlateMark.color, value)}
              options={colorOptions}
              placement='bottomLeft'
              size={KitSize.S}
              value={selectedFormat[SlateMark.color]}
            />
          </HideControl>
          <HideControl enabled={ALL_RT_CONTROLS} name='backgroundColor'>
            <ColorPicker
              icon='otherBg'
              name={SlateMark.backgroundColor}
              offset={[4, 0]}
              onChange={(value) => onUpdateFormat(SlateMark.backgroundColor, value)}
              options={colorOptions}
              placement='bottomLeft'
              size={KitSize.S}
              value={selectedFormat[SlateMark.backgroundColor]}
              transparent
            />
          </HideControl>
        </div>
        <Divider indents={{ margin: '0 8px' }} size={KitSize.S} styleType='vertical' />
      </HideControl>

      <HideControl enabled={ALL_RT_CONTROLS} name={marks}>
        <div className={s.groupControl}>
          {marks.map((mark) => (
            <HideControl enabled={ALL_RT_CONTROLS} key={mark} name={mark}>
              <CheckboxTool name={mark} />
            </HideControl>
          ))}
        </div>
        <Divider indents={{ margin: '0 8px' }} size={KitSize.S} styleType='vertical' />
      </HideControl>

      <HideControl enabled={ALL_RT_CONTROLS} name={['link', 'crossLink']}>
        <div className={s.groupControl}>
          <HideControl enabled={ALL_RT_CONTROLS} name='link'>
            <Checkbox
              name='link'
              onChange={onLinkClick}
              value={selectedFormat.inline?.type == SlateElementType.link}
              hideCheckbox
            >
              <Icon name='link2' size={KitSize.S} />
            </Checkbox>
          </HideControl>
          <HideControl enabled={ALL_RT_CONTROLS} name='crossLink'>
            <Tooltip overlay={t('uiKit.tooltip.crossLink')} trigger={['hoverWithoutContent']}>
              <Checkbox
                name='crossLink'
                onChange={onCrossLinkClick}
                value={selectedFormat.inline?.type == SlateElementType.crossLink}
                hideCheckbox
              >
                <Icon name='interfaceEssentialAnchor' size={KitSize.S} />
              </Checkbox>
            </Tooltip>
          </HideControl>
        </div>
      </HideControl>
      <HideControl enabled={ALL_RT_CONTROLS} name='code'>
        <CheckboxTool name='code' />
        <Divider indents={{ margin: '0 8px' }} size={KitSize.S} styleType='vertical' />
      </HideControl>
      <HideControl enabled={ALL_RT_CONTROLS} name='annotation'>
        <Tooltip overlay={t('uiKit.tooltip.annotation')} trigger={['hoverWithoutContent']}>
          <Checkbox
            name='annotation'
            onChange={onAnnotationClick}
            value={selectedFormat.inline?.type == SlateElementType.annotation}
            hideCheckbox
          >
            <Icon name='annotation' size={KitSize.S} />
          </Checkbox>
        </Tooltip>
        <Divider indents={{ margin: '0 8px' }} size={KitSize.S} styleType='vertical' />
      </HideControl>
      <HideControl enabled={ALL_RT_CONTROLS} name='insertSymbol'>
        <Dropdown
          offset={[4, 95]}
          overlay={
            <div className={s.symbols}>
              {symbols.map((symbol, index) => (
                <SymbolItem key={index} name={symbol} />
              ))}
            </div>
          }
          placement='bottomRight'
          trigger={['click']}
        >
          {({ open }) => (
            <Tooltip overlay={t('uiKit.tooltip.symbols')} trigger={['hoverWithoutContent']}>
              <IconButton
                active={open}
                icon='builderSymbol'
                name='symbol'
                size={KitSize.S}
                styleType='ghost'
              />
            </Tooltip>
          )}
        </Dropdown>
        <Divider indents={{ margin: '0 8px' }} size={KitSize.S} styleType='vertical' />
      </HideControl>
      {hasAi && (
        <HideControl enabled={ALL_RT_CONTROLS} name='ai'>
          <Tooltip
            disabled={!(disabledAi || toMatchSelected)}
            overlay={overlayMessage}
            placement='topRight'
          >
            <DropdownMenu
              name='AiSelect'
              offset={[4, 50]}
              onClick={onAiClick}
              options={AI_OPTIONS}
              placement='bottomRight'
              size={KitSize.S}
            >
              {({ open }) => (
                <Tooltip overlay={t('uiKit.tooltip.aiRichtextToolbar')}>
                  <IconButton
                    active={open}
                    disabled={disabledAi || toMatchSelected}
                    icon='builderAi'
                    name='aiButton'
                    size={KitSize.S}
                    styleType='ghost'
                    inheritColor
                  />
                </Tooltip>
              )}
            </DropdownMenu>
          </Tooltip>
          <Divider indents={{ margin: '0 8px' }} size={KitSize.S} styleType='vertical' />
        </HideControl>
      )}
      <Dropdown offset={[4, 6]} overlay={<OtherSettings />} placement='bottomRight'>
        {({ open }) => (
          <IconButton
            active={open}
            icon='iconsOtherMore'
            name='otherMore'
            size={KitSize.S}
            styleType='ghost'
          />
        )}
      </Dropdown>
    </div>
  )
}

export default Toolbar
