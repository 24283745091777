import { PreviewMode } from 'services/Store/Project/enums'

import { TestSingleChoiceElementType } from '../TestSingleChoice.types'
import TestSingleChoiceElementEditor from './TestSingleChoiceElementEditor'
import TestSingleChoiceElementPreview from './TestSingleChoiceElementPreview'

const COMPONENTS_MAP: Partial<Record<PreviewMode, TestSingleChoiceElementType>> = {
  editor: TestSingleChoiceElementEditor,
}

const TestSingleChoiceElement: TestSingleChoiceElementType = ({ mode, ...rest }) => {
  const Component = COMPONENTS_MAP[mode.previewMode] || TestSingleChoiceElementPreview
  return <Component mode={mode} {...rest} />
}

export default TestSingleChoiceElement
