import { gql } from 'gql/__generated__'

export const fontGroupCreate = gql(`
  mutation FontsGroupCreate($companyId: ID!, $payload: FontsGroupCreateInput!) {
    data: fontsGroupCreate(companyId: $companyId, payload: $payload) {
      data {
        id
        name
        isSerif
        companyId
        isArchived
        createdAt
        updatedAt
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const fontGroupArchive = gql(`
  mutation FontsGroupArchive($companyId: ID!, $ids: [ID!]!) {
    fontsGroupsArchive(companyId: $companyId, ids: $ids) {
      data {
        id
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const fontGroupEdit = gql(`
  mutation FontsGroupEdit($companyId: ID!, $payload: FontsGroupEditInput!) {
    data: fontsGroupEdit(companyId: $companyId, payload: $payload) {
      data {
        id
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)
