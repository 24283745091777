import { useCallback } from 'react'

import { useProjectT } from 'services/ProjectLabels'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getStartContinueTrigger } from 'services/Store/Project/triggers'
import { testProps } from 'utils/test/qaData'

import BaseButtonElement from '../BaseButtonElement'
import { ButtonElementType } from '../ButtonElement.types'
import { getButtonLabel } from '../helper'
import IconStyled from '../styled/IconStyled'

const CourseButtonElement: ButtonElementType = ({ element, styles, mode, font }) => {
  const pt = useProjectT()
  const { label, secondLabel, position } = element.value
  const dispatch = useProjectDispatch()
  const config = useProjectContext(getStartContinueTrigger)
  const labelButton = getButtonLabel(
    {
      'project/courseContinue': secondLabel || pt('elements.button.continue.label'),
      'project/courseStart': label || pt('elements.button.start.label'),
    },
    config.action,
  )

  const handleClick = useCallback(() => dispatch(config.actionCreator()), [dispatch, config])

  return (
    <BaseButtonElement
      action={element.value.action}
      font={font}
      icon={<IconStyled {...testProps({ el: 'iconCourseButton' })} $styles={styles} />}
      iconPosition={position === 'hide' ? undefined : position}
      label={labelButton}
      mode={mode}
      onClick={handleClick}
      styles={styles}
    />
  )
}

export default CourseButtonElement
