import { FileImageUsage } from '@leenda/editor/lib/files'
import { useMemo } from 'react'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import Divider from 'components/uiKit/Divider'
import { Typography } from 'components/uiKit/Typography'
import { FIELD_BOTTOM_INDENT } from 'components/uiKit/Typography/constants'
import { genColorOptions } from 'services/Branding/constants/fields'
import { useBrandTheme } from 'services/Branding/hooks'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { chainName, getParent } from 'utils/form'

import { SplitterValueFormType } from './SplitterElement.types'

const genField = genDataField<Block>()

const position = (name: string) =>
  genField({
    name: `${name}.position`,
    type: 'number',
    label: t('elements.splitter.form.splitter'),
    defaultValue: 50,
    layout: 'horizontal',
    rules: [{ max: 100 }],
    params: { min: 0, max: 100, width: 64 },
  })

const leftBlockImage = genField({
  name: 'leftBlock.image',
  type: 'file',
  layout: 'horizontal',
  params: {
    fileType: 'image',
    nullable: true,
    preview: true,
  },
})

export const leftBlockBg = genField({
  name: 'leftBlock.background',
  type: 'color',
  label: t('input.label.blackout'),
  layout: 'horizontal',
  useParams: () => {
    const theme = useBrandTheme()
    const options = useMemo(() => genColorOptions(theme), [theme])
    return {
      options,
    }
  },
})

export const leftAccessibility = genField({
  name: 'leftBlock.image.accessibility',
  type: 'text',
  label: t('input.label.accessibility'),
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<FileImageUsage>(name, block)
    return { placeholder: t('input.placeholder.altText'), hidden: !parent?.id }
  },
})

// --------------------------------

const rightBlockImage = genField({
  name: 'rightBlock.image',
  type: 'file',
  layout: 'horizontal',
  params: {
    fileType: 'image',
    nullable: true,
    preview: true,
  },
})

export const rightBlockBg = genField({
  name: 'rightBlock.background',
  type: 'color',
  label: t('input.label.blackout'),
  layout: 'horizontal',
  useParams: () => {
    const theme = useBrandTheme()
    const options = useMemo(() => genColorOptions(theme), [theme])
    return {
      options,
    }
  },
})

export const rightAccessibility = genField({
  name: 'rightBlock.image.accessibility',
  type: 'text',
  label: t('input.label.accessibility'),
  layout: 'horizontal',
  rules: [
    { whitespace: true, message: t('input.error.whitespace') },
    { max: 250, message: t('input.error.maxSymbols', { number: 250 }) },
  ],
  params: (block, { name }) => {
    const { parent } = getParent<FileImageUsage>(name, block)
    return { placeholder: t('input.placeholder.altText'), hidden: !parent?.id }
  },
})

const SplitterForm: SplitterValueFormType = ({ name }) => (
  <Padding sides>
    <Typography indents={FIELD_BOTTOM_INDENT} styleType='hint'>
      {t('elements.splitter.form.info')}
    </Typography>
    <RealtimeField config={position(name)} />
    <RealtimeField config={chainName(name, leftBlockImage)} />
    <RealtimeField config={chainName(name, leftBlockBg)} />
    <RealtimeField config={chainName(name, leftAccessibility)} />
    <Divider />
    <RealtimeField config={chainName(name, rightBlockImage)} />
    <RealtimeField config={chainName(name, rightBlockBg)} />
    <RealtimeField config={chainName(name, rightAccessibility)} />
  </Padding>
)

export default SplitterForm
