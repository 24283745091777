export const getSilentAudioDataUrl = async (duration = 1, sampleRate = 44100): Promise<string> => {
  const numOfChannels = 1 // Mono audio
  const numOfSamples = Math.floor(duration * sampleRate)

  // Create an empty buffer for the WAV file
  const buffer = new ArrayBuffer(44 + numOfSamples * 2)
  const view = new DataView(buffer)

  // Write WAV file header
  // "RIFF" chunk descriptor
  writeString(view, 0, 'RIFF')
  view.setUint32(4, 36 + numOfSamples * 2, true) // File size minus 8 bytes
  writeString(view, 8, 'WAVE')

  // "fmt " sub-chunk
  writeString(view, 12, 'fmt ')
  view.setUint32(16, 16, true) // Subchunk1Size (16 for PCM)
  view.setUint16(20, 1, true) // AudioFormat (1 for PCM)
  view.setUint16(22, numOfChannels, true) // NumChannels
  view.setUint32(24, sampleRate, true) // SampleRate
  view.setUint32(28, sampleRate * numOfChannels * 2, true) // ByteRate
  view.setUint16(32, numOfChannels * 2, true) // BlockAlign
  view.setUint16(34, 16, true) // BitsPerSample

  // "data" sub-chunk
  writeString(view, 36, 'data')
  view.setUint32(40, numOfSamples * numOfChannels * 2, true) // Subchunk2Size

  // Write silent audio samples (zeros)
  let offset = 44
  for (let i = 0; i < numOfSamples; i++) {
    view.setInt16(offset || 0, 0, true) // Zero amplitude for silence
    offset += 2
  }

  // Create a Blob from the buffer
  const blob = new Blob([buffer], { type: 'audio/wav' })

  // Convert Blob to base64 data URL
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      resolve(reader.result as string)
    }
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}

// Helper function to write strings into the DataView
const writeString = (view: DataView, offset: number, string: string) => {
  for (let i = 0; i < string.length; i++) {
    view.setUint8(offset + i, string.charCodeAt(i))
  }
}
