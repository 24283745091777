"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../../../elements/elements/Slider/constants");
const font_1 = require("../../font");
const theme_1 = require("../../theme");
const genStyle_1 = require("../genStyle");
const styleSchema = {
    activeColor: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryColor }),
    },
    inactiveColor: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.neutralColor }),
    },
    hover: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.focusedColor }),
    },
    progress: {
        marginLeft: (0, genStyle_1.genStyle)({ value: 8 }),
        height: (0, genStyle_1.genStyle)({ value: 6 }),
        width: (0, genStyle_1.genStyle)({ value: 6 }),
    },
    icons: {
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.lightTextColor }),
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryColor }),
        sliderPosition: (0, genStyle_1.genStyle)({ value: 'slide' }),
    },
    leftArrow: {
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
    rightArrow: {
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
    indents: {
        margin: (0, genStyle_1.genStyle)({ value: [8, 8, 8] }),
    },
};
const fontSchema = {
    base: Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base),
};
exports.default = { [constants_1.TYPE]: { style: styleSchema, font: fontSchema } };
