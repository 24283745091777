"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.TYPE = void 0;
exports.TYPE = 'audio';
exports.DEFAULT_VALUE = {
    sourceType: 'file',
    playbackRate: 1,
    url: null,
    file: null,
    controls: true,
    autoPlay: false,
    repeat: false,
    coverImage: null,
    duration: null,
    blackout: 'rgba(255, 255, 255, 0.1)',
    volume: 50,
    percent: 0,
    captions: null,
    enableCCByDefault: false,
};
