import { TabsSchemaType } from '@leenda/editor/lib/brand'
import styled from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

const TabControlsStyled = styled.div<{ $styles: ElementStyleCss<TabsSchemaType> }>`
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: ${({ $styles }) => `calc(-${$styles.border.borderWidth} - 1px)`};

  overflow: hidden;

  &:hover {
    .arrowLeft,
    .arrowRight {
      display: flex;
    }
  }
`

export default TabControlsStyled
