import { useCallback, useMemo } from 'react'

import { listToTree } from 'components/sections/ProjectStructure/utils'
import { SectionTypeEnum } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import {
  getDisabledSections,
  getProgress,
  getSection,
  getSections,
} from 'services/Store/Project/selectors'
import { useNavigationAction } from 'services/Store/Project/triggers'
import store from 'services/Store/store'

import { CourseStructureElementType } from './CourseStructure.types'
import CourseStructureTree from './CourseStructureTree/CourseStructureTree'

const LIST_SECTIONS = [SectionTypeEnum.chapter, SectionTypeEnum.landing, SectionTypeEnum.test]

const CourseStructureElement: CourseStructureElementType = ({ styles, element, font }) => {
  const sections = useProjectContext(getSections, LIST_SECTIONS) || element.value.sectionsDefault
  const onNavigate = useNavigationAction()
  const section = useProjectContext(getSection)

  const state = store.getState().project
  const treeSections = useMemo(() => listToTree(sections), [sections])

  const onSectionPick = useCallback((sectionId: string) => onNavigate({ sectionId }), [onNavigate])
  const getSectionProgress = useCallback((id: string) => getProgress(state, id), [state])
  const getDisabledSection = useCallback((id: string) => getDisabledSections(state)[id], [state])

  return (
    <CourseStructureTree
      font={font}
      getDisabledSection={getDisabledSection}
      getSectionProgress={getSectionProgress}
      nodes={treeSections}
      onSectionPick={onSectionPick}
      section={section}
      sections={sections}
      styles={styles}
      value={element.value}
    />
  )
}

export default CourseStructureElement
