import { SidebarSchemaType } from '@leenda/editor/lib/brand'
import {
  CollapseIconPosition,
  CourseStructureItem,
  ProgressIconPosition,
} from '@leenda/editor/lib/elements'
import cn from 'classnames'
import { useMemo } from 'react'

import { Description } from 'components/editor-v3/cource/controls/CourseSidebar/components/Description'
import { useCourseSidebarData } from 'components/editor-v3/cource/controls/CourseSidebar/context/selectors'
import { getIconData } from 'components/editor-v3/cource/controls/CourseSidebar/utils'
import { ISectionTreeNode } from 'components/sections/ProjectStructure/utils'
import CollapseIcon from 'components/uiKit/Collapse/CollapseIcon'
import { ElementStyleCss } from 'services/Branding/types'

import SectionStatus from '../SectionStatus/SectionStatus'
import { StructureItemCourse } from '../StructureItemCourse'
import s from './StructureNodeCourse.module.scss'

export const LEVEL_INDENT = 12
interface ITreeNodeProps {
  toggle?(active?: boolean): void
  open: boolean
  node: ISectionTreeNode<CourseStructureItem>
  onClick?: () => void
}

const getGridTemplateStyle = (styles: ElementStyleCss<SidebarSchemaType>, showIcon?: boolean) => {
  let gridTemplateColumnsValue = ''
  const topRow: string[] = []
  const bottomRow: string[] = []
  const progressValue = 'minmax(min-content, max-content) '
  const iconValue = 'minmax(min-content, max-content) '
  const sectionNameValue = 'minmax(min-content, auto) '

  if (styles.collapse.collapsePosition === CollapseIconPosition.leftOutside) {
    topRow.push('collapse')
    bottomRow.push('.')
    gridTemplateColumnsValue += iconValue
  }
  if (styles.verticalProgress.progressPosition === ProgressIconPosition.left) {
    topRow.push('progress')
    bottomRow.push('line')
    gridTemplateColumnsValue += progressValue
  }
  if (styles.collapse.collapsePosition === CollapseIconPosition.left) {
    topRow.push('collapse')
    bottomRow.push('.')
    gridTemplateColumnsValue += iconValue
  }

  if (showIcon) {
    topRow.push('icon')
    bottomRow.push('.')
    gridTemplateColumnsValue += iconValue
  }

  topRow.push('section')
  bottomRow.push('description')
  gridTemplateColumnsValue += sectionNameValue

  if (styles.collapse.collapsePosition === CollapseIconPosition.right) {
    topRow.push('collapse')
    bottomRow.push('.')
    gridTemplateColumnsValue += iconValue
  }
  if (styles.verticalProgress.progressPosition === ProgressIconPosition.right) {
    topRow.push('progress')
    bottomRow.push('line')
    gridTemplateColumnsValue += progressValue
  }
  if (styles.collapse.collapsePosition === CollapseIconPosition.rightOutside) {
    topRow.push('collapse')
    bottomRow.push('.')
    gridTemplateColumnsValue += iconValue
  }

  const preparedTemplateAreas = [topRow, bottomRow]

  const getGridTemplateAreas = (gridLayout: string[][]) =>
    gridLayout.map((row) => `"${row.join(' ')}"`).join(' ')

  const gridTemplateAreasValue = getGridTemplateAreas(preparedTemplateAreas)

  return {
    gridTemplateAreas: gridTemplateAreasValue,
    gridTemplateColumns: gridTemplateColumnsValue.trim(),
  }
}

const StructureNodeCourse: React.FC<ITreeNodeProps> = ({ open, node, onClick, toggle }) => {
  const { item } = node
  const { styles, font, getActiveSection, getDisabledSection } = useCourseSidebarData()
  const active = getActiveSection(item)
  const nestedOffset = item.lvl * LEVEL_INDENT
  const disabled = useMemo(() => getDisabledSection(item.id), [getDisabledSection, item.id])
  const iconPosition = [CollapseIconPosition.right, CollapseIconPosition.rightOutside].includes(
    styles.collapse.collapsePosition as CollapseIconPosition,
  )
    ? 'right'
    : 'left'
  const customIconCollapse = open
    ? styles.collapseIcon.backgroundOpen
    : styles.collapseIcon.backgroundImage

  const collapseIcon = (
    <div className={s.collapseIcon} onClick={() => toggle?.()}>
      {item.isChapter &&
        toggle &&
        styles.collapse.collapsePosition !== CollapseIconPosition.none &&
        (customIconCollapse ? (
          <div className={s.customIcon} style={{ backgroundImage: customIconCollapse }} />
        ) : (
          <CollapseIcon iconPosition={iconPosition} open={open} />
        ))}
    </div>
  )

  const onClickHandler = () => {
    if (disabled) {
      return
    }
    if (item.isChapter) {
      toggle?.()
    } else {
      onClick?.()
    }
  }

  const rootCn = cn(s.root, {
    [s.cursorOpen]: item.isChapter && open,
    [s.cursorClose]: item.isChapter && !open,
    [s.cursorPointer]: !item.isChapter,
    [s.active]: active,
    [s.disabled]: disabled,
  })

  const spaceBetweenFromSections = styles?.margins.offsetYFromSection
  const collapseOffset = styles?.margins.offsetFromCollapse
  const statusOffset = styles?.margins.offsetFromProgress
  const { showIcon } = getIconData({ type: item.type, styles, font, disabled, nestedOffset })
  const rootStyles = {
    paddingBottom: spaceBetweenFromSections,
    ...getGridTemplateStyle(styles, showIcon),
    ...styles.root,
  }

  return (
    <div className={rootCn} onClick={onClickHandler} style={rootStyles}>
      {/* row1 */}
      {styles.collapse.collapsePosition === CollapseIconPosition.leftOutside && (
        <div className={s.collapse} style={{ marginRight: collapseOffset }}>
          {collapseIcon}
        </div>
      )}
      {styles.verticalProgress.progressPosition === ProgressIconPosition.left && (
        <div className={s.sectionStatus} style={{ marginRight: statusOffset }}>
          <SectionStatus disabled={disabled} node={node} open={open} />
        </div>
      )}
      {styles.collapse.collapsePosition === CollapseIconPosition.left && (
        <div className={s.nodeIndent} style={{ marginRight: collapseOffset }}>
          {collapseIcon}
        </div>
      )}
      <StructureItemCourse disabled={disabled} item={node.item} nestedOffset={nestedOffset} />
      {styles.collapse.collapsePosition === CollapseIconPosition.right && (
        <div className={cn(s.nodeIndent, s.collapse)} style={{ marginLeft: collapseOffset }}>
          {collapseIcon}
        </div>
      )}
      {styles.verticalProgress.progressPosition === ProgressIconPosition.right && (
        <div className={s.sectionStatus} style={{ marginLeft: statusOffset }}>
          <SectionStatus disabled={disabled} node={node} open={open} />
        </div>
      )}
      {styles.collapse.collapsePosition === CollapseIconPosition.rightOutside && (
        <div className={cn(s.nodeIndent, s.collapse)} style={{ marginLeft: collapseOffset }}>
          {collapseIcon}
        </div>
      )}
      {/* row2 */}
      {styles?.value.showDescription && (
        <div className={s.description}>
          <Description childrenChapter={node.children?.length || 0} font={font} item={item} />
        </div>
      )}
    </div>
  )
}

export default StructureNodeCourse
