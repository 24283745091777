import { getFontValue } from '@leenda/editor/lib/brand/utils'

import { FontCreateInput } from 'gql/__generated__/graphql'
import { t } from 'services/Translation'

export const FONT_WEIGHT_OPTIONS = [
  { label: 'Thin', value: 100 },
  { label: 'Extra Light', value: 200 },
  { label: 'Light', value: 300 },
  { label: 'Regular', value: 400 },
  { label: 'Medium', value: 500 },
  { label: 'Semi Bold', value: 600 },
  { label: 'Bold', value: 700 },
  { label: 'Extra Bold', value: 800 },
  { label: 'Black', value: 900 },
]

export const FONT_ERROR_DICTIONARY = {
  NAME_NOT_UNIQUE: t('input.error.fontNameExist'),
  NAME_NOT_ALLOWED: t('input.error.fontNameExist'),
}

export const validateFontFamilyCss = (style: unknown) => {
  try {
    const { id, isSerif, name } = getFontValue(String(style))
    if (id) {
      return `'${id}', ${isSerif ? 'serif' : 'sans-serif'}`
    } else if (name && FONT_FAMILY_FALLBACK[name]) {
      return `${name}, ${FONT_FAMILY_FALLBACK[name] || 'sans-serif'}`
    } else if (typeof style === 'string') {
      return style
    } else {
      return 'Inter, sans-serif'
    }
  } catch (e) {
    return String(style)
  }
}

const FONT_FAMILY_FALLBACK: Record<string, string> = {
  Inter: 'sans-serif',
  Arial: 'sans-serif',
  Verdana: 'sans-serif',
  Helvetica: 'sans-serif',
  Tahoma: 'sans-serif',
  'Trebuchet MS': 'sans-serif',
  'Times New Roman': 'serif',
  Georgia: 'serif',
  Garamond: 'serif',
  'Courier New': 'monospace',
  Montserrat: 'sans-serif',
  'Brush Script MT': 'cursive',
}

export const nameToWeight = (name?: string) => {
  if (name?.toLowerCase().includes('extra') && name?.toLowerCase().includes('light')) {
    return 200
  }
  if (name?.toLowerCase().includes('semi') && name?.toLowerCase().includes('bold')) {
    return 600
  }
  if (name?.toLowerCase().includes('extra') && name?.toLowerCase().includes('bold')) {
    return 800
  }
  if (name?.toLowerCase().includes('thin')) {
    return 100
  }
  if (name?.toLowerCase().includes('light')) {
    return 300
  }
  if (name?.toLowerCase().includes('regular')) {
    return 400
  }
  if (name?.toLowerCase().includes('medium')) {
    return 500
  }
  if (name?.toLowerCase().includes('bold')) {
    return 700
  }
  if (name?.toLowerCase().includes('black')) {
    return 900
  }
  return null
}

export const fontsSort = <T extends { weight?: number | null; isItalic?: boolean | null }>(
  a: T,
  b: T,
) => {
  if (a.weight !== b.weight) {
    return (a.weight || 0) - (b.weight || 0)
  } else {
    if (a.isItalic && !b.isItalic) {
      return 1
    } else if (!a.isItalic && b.isItalic) {
      return -1
    } else {
      return 0
    }
  }
}

export const uniqFont = (item?: FontCreateInput) =>
  `${Number(item?.weight)}|${Boolean(item?.isItalic)}`

const WEIGHT_STRINGS = ['extra', 'semi', 'thin', 'light', 'regular', 'medium', 'bold', 'black']
const STYLE_STRINGS = ['italic', 'normal', 'oblique']
const EXTRA_SYMBOLS = ['-', '_']
const BLANK_SYMBOLS = [...WEIGHT_STRINGS, ...STYLE_STRINGS, ...EXTRA_SYMBOLS]
export const cleanFontGroupName = (filename: string) =>
  filename
    .split('.')
    .slice(0, -1)
    .join('.')
    .replace(new RegExp(BLANK_SYMBOLS.join('|'), 'gi'), '')
    .trim()
    .replace(/\s+/g, ' ')
