import { SliderSchemaType } from '@leenda/editor/lib/brand'
import { useContext } from 'use-context-selector'

import { ElementStyleCss } from 'services/Branding/types'
import { DeviceMode } from 'services/Store/Project/enums'
import { testProps } from 'utils/test/qaData'

import { ArrowPosition } from '../Slider.types'
import SliderContext from '../SliderContext'
import { ReactComponent as Left } from '../assets/left.svg'
import { ReactComponent as Right } from '../assets/right.svg'
import ArrowStyled from '../styled/ArrowStyled'
import s from './Arrows.module.scss'
import { getMargins } from './getMargins'

interface IArrowsProps {
  children?: React.ReactNode
  styles: ElementStyleCss<SliderSchemaType>
}

const Arrows: React.FC<IArrowsProps> = ({ children, styles }) => {
  const {
    leftArrowNoLoop,
    rightArrowNoLoop,
    mode,
    setTranslation,
    translation,
    slideIndex,
    items,
    prevSlide,
    nextSlide,
    hidden,
  } = useContext(SliderContext)
  const isMobile = mode.deviceMode === DeviceMode.mobile

  const margins = getMargins(String(styles.indents.margin))
  const isProgressPosition = styles.icons.sliderPosition === ArrowPosition.progress

  const handleLeftArrow = () => {
    if (!leftArrowNoLoop) {
      prevSlide()

      if (slideIndex === 0 && hidden) {
        setTranslation(hidden)
      } else if (slideIndex <= hidden + 1 && hidden) {
        const translate = slideIndex - 2 >= 0 ? slideIndex - 2 : 0
        setTranslation(translate)
      }
    }
  }

  const handleRightArrow = () => {
    if (!rightArrowNoLoop) {
      nextSlide()
      if (slideIndex >= items.length - 2 - hidden && translation < hidden && hidden) {
        setTranslation((prev: number) => prev + 1)
      } else if (slideIndex === items.length - 1 && hidden) {
        setTranslation(0)
      }
    }
  }

  return (
    <div
      className={s.arrows}
      style={{
        marginTop: styles.icons.sliderPosition === ArrowPosition.slide ? undefined : margins.bottom,
        paddingBottom: isProgressPosition ? (isMobile ? 4 : 8) : undefined,
      }}
    >
      <ArrowStyled
        {...testProps({
          el: 'sliderElNav',
          name: 'left',
          activeIndex: slideIndex,
          disabled: leftArrowNoLoop,
        })}
        $disabled={leftArrowNoLoop}
        $styles={styles}
        onClick={handleLeftArrow}
        style={{ marginRight: margins.right }}
      >
        {styles.leftArrow.backgroundImage ? (
          <div className={s.icon} style={styles.leftArrow} />
        ) : (
          <Left />
        )}
      </ArrowStyled>
      {children}
      <ArrowStyled
        {...testProps({
          el: 'sliderElNav',
          name: 'right',
          activeIndex: slideIndex,
          disabled: rightArrowNoLoop,
        })}
        $disabled={rightArrowNoLoop}
        $styles={styles}
        onClick={handleRightArrow}
        style={{ marginLeft: margins.left }}
      >
        {styles.rightArrow.backgroundImage ? (
          <div className={s.icon} style={styles.rightArrow} />
        ) : (
          <Right />
        )}
      </ArrowStyled>
    </div>
  )
}

export default Arrows
