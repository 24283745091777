import { textToRtValue } from '@leenda/rich-text'

import { TestAnswerFeedback } from 'components/editor-v2/EditorElements/decorators/TestAnswerFeedback/TestAnswerFeedback'
import { useElementRootStyles } from 'components/editor-v2/EditorElements/hooks/useElementRootStyles'
import { useShuffleRadioOrCheckboxItems } from 'components/editor-v2/EditorElements/hooks/useShuffleRadioOrCheckboxItems'
import { RichTextViewer } from 'components/form/RichText/RichTextViewer'
import { setQuestion } from 'services/Store/Project/actions'
import { PreviewMode, SectionTypeEnum } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getBlockState, getSection } from 'services/Store/Project/selectors'
import { extendTestData } from 'utils/test/qaData'

import { TestSingleChoiceElementType } from '../TestSingleChoice.types'
import Radio from './Radio'
import s from './TestSingleChoiceElement.module.scss'

const TestSingleChoiceElementPreview: TestSingleChoiceElementType = ({
  element,
  mode,
  styles,
  state,
  block,
  font,
}) => {
  const isPdf = mode.previewMode === PreviewMode.pdf
  const { id: elementId, value } = element
  const dispatch = useProjectDispatch()
  const section = useProjectContext(getSection)
  const blockResult = useProjectContext(getBlockState, block?.uuid)?.result

  const valueItems = value.items || []

  const isViewMode = mode.previewMode !== PreviewMode.editor
  const disabled = Boolean(!isViewMode || blockResult)
  const isRandom = value.isRandom && isViewMode && !isPdf
  const isValidated = Boolean(blockResult)
  const isVisible = Boolean(
    isValidated &&
      (section?.test?.isValidationVisible ||
        (section?.type === SectionTypeEnum.landing && block?.test?.isResult)),
  )

  const validIds = isVisible && valueItems.filter((item) => item.isValid).map((item) => item.value)
  const randomIndexes = useShuffleRadioOrCheckboxItems(valueItems.length, isRandom)

  const onInternalChange = (value: string) => {
    dispatch(
      setQuestion({
        elementId,
        value: { value: [value] },
        isReady: true,
        blockId: block?.uuid || '',
      }),
    )
  }

  const rootStyles = useElementRootStyles(styles.root)

  return (
    <div style={rootStyles}>
      {valueItems.map((item, index) => {
        const randomIndex = randomIndexes[index]
        const itemToShow = isRandom ? valueItems[randomIndex] : item
        const currentIds = state?.value || []
        const isIncludeValidId = validIds && validIds.includes(itemToShow?.value)
        const isIncludeCurrentId = validIds && currentIds?.includes(itemToShow?.value)
        const isVisibleAnswer = isIncludeCurrentId || isIncludeValidId

        return (
          <div className={s.wrapItem} key={index} style={{ marginBottom: styles.offset.marginTop }}>
            <TestAnswerFeedback
              deviceMode={mode.deviceMode}
              isTrueAnswer={isIncludeValidId}
              isVisible={!!validIds}
              showAnswerForCurrentElement={!!isVisibleAnswer}
            >
              <Radio
                checked={currentIds.includes(itemToShow?.value)}
                disabled={disabled}
                onChange={onInternalChange}
                styles={styles}
                testData={extendTestData({
                  index,
                  label: itemToShow?.label ? (itemToShow?.label[0] as any).children[0].text : '',
                })}
                value={itemToShow?.value}
              >
                <RichTextViewer styles={font} value={itemToShow?.label || textToRtValue('')} />
              </Radio>
            </TestAnswerFeedback>
          </div>
        )
      })}
    </div>
  )
}

export default TestSingleChoiceElementPreview
