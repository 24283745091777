"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.TYPE = void 0;
const translation_1 = require("../../../translation");
const rich_text_1 = require("@leenda/rich-text");
const types_1 = require("./types");
exports.TYPE = 'card';
exports.DEFAULT_VALUE = {
    front: {
        label: (0, rich_text_1.textToRtValue)((0, translation_1.t)('elements.card.placeholder'), rich_text_1.SlateElementType.heading3),
        image: null,
        scaling: types_1.ScalingCardEnum.fill,
    },
    back: {
        label: (0, rich_text_1.textToRtValue)((0, translation_1.t)('elements.card.placeholder')),
        image: null,
        scaling: types_1.ScalingCardEnum.fill,
    },
};
