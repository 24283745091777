import { SidebarFontSchemaType, SidebarSchemaType } from '@leenda/editor/lib/brand'
import { CourseStructureItem } from '@leenda/editor/lib/elements/elements/CourseStructure'
import { ReactNode, useCallback } from 'react'
import { createContext } from 'use-context-selector'

import { ISectionTreeNode } from 'components/sections/ProjectStructure/utils'
import { VOID } from 'constants/commonConstans'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { setSidebar, setSidebarSearch } from 'services/Store/Project/actions'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getSidebar } from 'services/Store/Project/selectors'
import { useNavigationAction } from 'services/Store/Project/triggers'

export type ISidebarContext = {
  sectionTree: ISectionTreeNode<CourseStructureItem>[]
  sections: CourseStructureItem[]
  sectionId: string
  styles: ElementStyleCss<SidebarSchemaType>
  font: ElementFontCss<SidebarFontSchemaType>
  search?: string
  getActiveSection: (item: CourseStructureItem) => boolean
  onSearchResultSectionPick: (sectionId: string) => void
  onSelectSection: (sectionId: string) => void
  getDisabledSection: (id: string) => boolean
  getSectionProgress: (id: string) => number
}

const SidebarContext = createContext<ISidebarContext>({
  sectionTree: [],
  sections: [],
  sectionId: '',
  styles: {} as ElementStyleCss<SidebarSchemaType>,
  font: {} as ElementFontCss<SidebarFontSchemaType>,
  search: '',
  getActiveSection: (_item: CourseStructureItem) => false,
  onSearchResultSectionPick: VOID,
  onSelectSection: VOID,
  getDisabledSection: (_id: string) => false,
  getSectionProgress: (_id: string) => 0,
})

export const SidebarProvider: React.FC<{
  sections: CourseStructureItem[]
  children: ReactNode
  sectionId: string
  styles: ElementStyleCss<SidebarSchemaType>
  font: ElementFontCss<SidebarFontSchemaType>
  sectionTree: ISectionTreeNode<CourseStructureItem>[]
  getDisabledSection: (id: string) => boolean
  getSectionProgress: (id: string) => number
}> = ({
  children,
  sections,
  sectionId,
  font,
  styles,
  sectionTree,
  getDisabledSection,
  getSectionProgress,
}) => {
  const dispatch = useProjectDispatch()
  const { search } = useProjectContext(getSidebar)
  const getActiveSection = (item: CourseStructureItem) => item.id === sectionId
  const onClose = useCallback(() => dispatch(setSidebar({ open: false })), [dispatch])

  const onNavigate = useNavigationAction()

  const onSelectSection = useCallback(
    (sectionId: string) => {
      onNavigate({ sectionId })
      onClose()
    },
    [onClose, onNavigate],
  )

  const onSearchResultSectionPick = useCallback(
    (sectionId: string) => {
      dispatch(setSidebarSearch({ search: '' }))
      onSelectSection(sectionId)
    },
    [dispatch, onSelectSection],
  )

  return (
    <SidebarContext.Provider
      value={{
        sections,
        sectionTree,
        sectionId,
        getActiveSection,
        font,
        styles,
        search,
        onSearchResultSectionPick,
        onSelectSection,
        getDisabledSection,
        getSectionProgress,
      }}
    >
      {children}
    </SidebarContext.Provider>
  )
}

export default SidebarContext
