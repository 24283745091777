import React from 'react'

import { KitSize } from 'components/uiKit/KitTypes'
import { usePermissions } from 'services/Permission/PermissionContext'
import { throwLocal } from 'utils/dev'

import { IControlProps, IFieldConfig, IFieldMeta, IOnChange } from './Field.types'
import FieldsMap from './FieldsMap'
import FieldLayout from './Layout'

interface IFieldProps<D> {
  config: IFieldConfig<D>
  meta: IFieldMeta
  Parent: React.FC<{ config: IFieldConfig<D>; onChange?: IOnChange }>
  params: Record<string, unknown>
  label?: React.ReactNode
  value: unknown | null
  defaultValue?: unknown
  size?: KitSize
  required?: boolean
  onChange: IOnChange
  onBlur?: () => void
  onFocus?: (fieldName?: string) => void
}

const Field = <D,>({
  config,
  meta,
  Parent,
  params,
  label,
  defaultValue,
  size,
  onFocus,
  required,
  ...rest
}: IFieldProps<D>) => {
  const { name, layout } = config
  const { disabled, hidden, error, touched, commonError } = meta
  const permissions = usePermissions()
  const disabledByPermission =
    config.permission?.disabled && !permissions.has(config.permission?.disabled)
  const hiddenByPermission =
    config.permission?.visible && !permissions.has(config.permission?.visible)
  const readonlyByPermission =
    config.permission?.readonly && !permissions.has(config.permission?.readonly)

  const finalParams = {
    size,
    ...rest,
    value: config.getValue ? config.getValue(rest.value) : rest.value,
    ...params,
    disabled: disabled || disabledByPermission || (params?.disabled as boolean),
    readOnly: readonlyByPermission || params.readOnly,
    error: Boolean(touched && error) || commonError,
    defaultValue: config.getValue
      ? config.getValue(config.defaultValue ?? defaultValue)
      : (config.defaultValue ?? defaultValue),
    ...((config.type === 'array' || config.type === 'group') && { fields: config.fields, Parent }),
    ...(config.type === 'custom' && { component: config.component, Parent }),
  }

  const handleOnFocus = (e: unknown) => {
    if (typeof params.onFocus === 'function') {
      params.onFocus(e)
    }
    if (name) {
      onFocus?.(name)
    }
  }

  if (hidden || hiddenByPermission) {
    return null
  }

  const FieldComponent = FieldsMap[config.type] as React.FC<IControlProps<{ name?: unknown }>>

  if (!FieldComponent) {
    throwLocal(`Field type "${config.type}" is not supported ${JSON.stringify(config, null, 2)}`)
    return null
  }

  return (
    <FieldLayout
      empty={config.type === 'array' || config.layout === 'off'}
      info={config.info}
      label={label}
      layout={layout}
      meta={meta}
      name={name}
      required={required}
      size={size}
    >
      <FieldComponent name={name || ''} {...finalParams} onFocus={handleOnFocus} />
    </FieldLayout>
  )
}

export default Field
