import { TabsSchemaType } from '@leenda/editor/lib/brand'
import styled from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

const TabListStyled = styled.div<{ $styles: ElementStyleCss<TabsSchemaType> }>`
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-left: 0;
  border-bottom: ${({ $styles }) =>
    `${$styles.border.borderWidth} ${$styles.border.borderStyle} ${$styles.border.borderColor}`};
`

export default TabListStyled
