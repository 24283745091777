import { ButtonSchemaType } from '@leenda/editor/lib/brand'
import styled from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

const IconStyled = styled.div<{ $styles: ElementStyleCss<ButtonSchemaType> }>`
  width: 1em;
  height: 1em;
  margin: 0;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: ${({ $styles }) => $styles.icon.backgroundImage};
`

export default IconStyled
