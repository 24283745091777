import { SCHEMA } from '@leenda/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.testMatching.style, {
  icon: {
    META: { label: t('elements.testMatching.tags.answer') },
    backgroundImage: {
      label: t('elements.testMatching.schema.dragIcon'),
      params: { preview: false },
    },
  },
  pair: {
    META: { hidden: true },
    backgroundColor: {},
    borderRadius: { params: { min: 1 } },
  },
  border: {
    META: { hidden: true },
    borderColor: {},
    borderWidth: { params: { min: 1, max: 8 } },
  },
  hover: {
    META: { hidden: true },
    backgroundColor: {
      label: t('style.hoverColor'),
      params: {
        transparent: true,
        resettable: true,
      },
    },
  },
  mobileIcon: {
    META: { label: t('elements.testMatching.tags.answerSelection') },
    backgroundImage: {
      label: t('elements.testMatching.schema.jumpIcon'),
      params: { preview: false },
    },
  },
  selected: {
    META: { hidden: true },
    backgroundColor: {
      label: t('elements.testMatching.schema.selectedBg'),
    },
  },
  indents: {
    META: { label: t('elements.testMatching.tags.indents') },
    padding: { label: t('input.label.innerAnswerIndents'), params: { min: 0 } },
    marginBottom: { label: t('input.label.answerIndents') },
    marginRight: { label: t('input.label.iconIndents') },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.testMatching.font, FONT_SCHEMA_FIELDS)
