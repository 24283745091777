import { MenuPositionType, SidebarMenuType } from '@leenda/editor/lib/brand/types'
import { CONFIG_COURSE } from '@leenda/editor/lib/elements'
import { useCallback, useEffect } from 'react'

import { listToTree } from 'components/sections/ProjectStructure/utils'
import { Drawer } from 'components/uiKit/Drawer'
import { useElementFontCss, useElementStyleCss } from 'services/Branding/hooks'
import { ScrollContainerEnum } from 'services/Scroll/enums'
import { scroll } from 'services/Store/Project/actions'
import { setSidebar } from 'services/Store/Project/actions'
import { DeviceMode, SectionTypeEnum } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import {
  getDisabledSections,
  getProgress,
  getProject,
  getSectionId,
  getSections,
  getSidebar,
} from 'services/Store/Project/selectors'
import { IBlockMode } from 'services/Store/Project/types'
import store from 'services/Store/store'

import CourseSidebar from './CourseSidebar'
import CourseSidebarLayout from './components/CourseSidebarLayout/CourseSidebarLayout'
import { SidebarProvider } from './context/SidebarContext'

interface ICourseSidebarProps {
  mode: IBlockMode
}

const CourseSidebarContainer: React.FC<ICourseSidebarProps> = ({ mode }) => {
  const styles = useElementStyleCss(CONFIG_COURSE.sidebar.type, mode.deviceMode)
  const state = store.getState().project
  const dispatch = useProjectDispatch()
  const { open } = useProjectContext(getSidebar)
  const sections = useProjectContext(getSections, [
    SectionTypeEnum.chapter,
    SectionTypeEnum.landing,
    SectionTypeEnum.test,
  ])
  const sectionId = useProjectContext(getSectionId)
  const project = useProjectContext(getProject)
  const percent = useProjectContext(getProgress)
  // const getDisabledSection = useProjectContext(getIsSectionDisabled)
  const getDisabledSection = (id: string) => getDisabledSections(state)[id]
  const getSectionProgress = (id: string) => getProgress(state, id)
  const font = useElementFontCss(CONFIG_COURSE.sidebar.type, mode.deviceMode)
  const menuPosition = styles.menu.position as MenuPositionType
  const drawer = styles.menu.type === SidebarMenuType.drawer
  const width = mode.deviceMode === DeviceMode.mobile ? '100%' : styles.value.width || '100%'
  const sectionTree = listToTree(sections)
  const isDrawer = mode.deviceMode !== DeviceMode.desktop || drawer

  const onClose = useCallback(() => dispatch(setSidebar({ open: false })), [dispatch])

  useEffect(() => {
    if (open) {
      dispatch(
        scroll({
          container: ScrollContainerEnum.sectionNav,
          id: sectionId,
          intersection: true,
        }),
      )
    }
  }, [sectionId, dispatch, open])

  const sidebar = (
    <SidebarProvider
      font={font}
      getDisabledSection={getDisabledSection}
      getSectionProgress={getSectionProgress}
      sectionId={sectionId}
      sections={sections}
      sectionTree={sectionTree}
      styles={styles}
    >
      <CourseSidebarLayout mode={mode} styles={styles} width={width}>
        <CourseSidebar percent={percent} project={project} projectName={project?.name} />
      </CourseSidebarLayout>
    </SidebarProvider>
  )

  return isDrawer ? (
    <Drawer
      onClose={onClose}
      open={open}
      placement={menuPosition}
      position='absolute'
      showBg={drawer && styles.value.showOverlay}
      width={width}
      destroyOnClose
    >
      {sidebar}
    </Drawer>
  ) : (
    sidebar
  )
}

export default CourseSidebarContainer
