import { TabsSchemaType } from '@leenda/editor/lib/brand'
import styled, { css } from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

const TabContentStyled = styled.div<{
  $styles: ElementStyleCss<TabsSchemaType>
  $hidden: boolean
}>`
  display: flex;
  height: 100%;
  flex-direction: column;
  border-width: ${({ $styles }) => $styles.border.borderWidth};
  border-style: ${({ $styles }) => $styles.border.borderStyle};
  border-color: ${({ $styles }) => $styles.border.borderColor};
  border-top: 0 solid transparent;
  border-bottom-right-radius: ${({ $styles }) => $styles.border.borderRadius};
  border-bottom-left-radius: ${({ $styles }) => $styles.border.borderRadius};
  background-color: ${({ $styles }) => $styles.content.backgroundColor};
  padding: ${({ $styles }) => $styles.content.padding};

  ${({ $hidden }) =>
    $hidden &&
    css`
      display: none;
    `}
`

export default TabContentStyled
