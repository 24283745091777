import { SliderFontSchemaType, SliderSchemaType } from '@leenda/editor/lib/brand'
import { ProgressType } from '@leenda/editor/lib/elements'
import { useSize } from 'ahooks'
import cn from 'classnames'
import React, { useEffect, useMemo, useRef } from 'react'
import { useContext } from 'use-context-selector'

import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { useProjectT } from 'services/ProjectLabels'
import { DeviceMode } from 'services/Store/Project/enums'
import { testProps } from 'utils/test/qaData'

import { ArrowPosition } from '../Slider.types'
import SliderContext from '../SliderContext'
import DotStyled from '../styled/DotStyled'
import s from './Progress.module.scss'
import { getMargins } from './getMargins'

interface IDotProps {
  number: number
  styles: ElementStyleCss<SliderSchemaType>
  handleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

interface IDotsProps {
  type?: ProgressType
  includeSlide?: {
    first: boolean
    last: boolean
  }
  styles: ElementStyleCss<SliderSchemaType>
  font?: ElementFontCss<SliderFontSchemaType>
}

const Dot = ({ number, styles, handleClick }: IDotProps) => {
  const { slideIndex } = useContext(SliderContext)
  const isActive = slideIndex === number

  return (
    <DotStyled
      {...testProps({ el: 'sliderElDot', index: number, isActive })}
      $selected={isActive}
      $styles={styles}
      onClick={handleClick}
    />
  )
}

const Progress = ({ type, includeSlide, styles, font }: IDotsProps) => {
  const pt = useProjectT()
  const dotsRef = useRef<HTMLDivElement>(null)
  const { items, slideIndex, mode, goToSlide, translation, setTranslation, setHidden } =
    useContext(SliderContext)
  const isMobile = mode.deviceMode === DeviceMode.mobile
  const { width: dotSizeWidth = 0 } = useSize(dotsRef) || {}
  const margins = getMargins(String(styles.indents.margin))
  const isSlidePosition = styles.icons.sliderPosition === ArrowPosition.slide
  const { width, marginLeft } = styles.progress
  const marginInt = parseInt(String(marginLeft)) || 8
  const widthInt = parseInt(String(width)) || 6
  const dotsWidth = useMemo(
    () => items.length * (widthInt + marginInt),
    [widthInt, marginInt, items.length],
  )

  const numbering =
    items.length - Object.values(includeSlide || {}).reduce((res, val) => +!val + res, 0)

  let label
  if (includeSlide && !includeSlide?.first && slideIndex === 0) {
    label = pt('elements.slider.start')
  } else if (!includeSlide?.last && slideIndex + 1 === items.length) {
    label = pt('elements.slider.end')
  } else {
    label = `${!includeSlide?.first ? slideIndex : slideIndex + 1}/${numbering}`
  }
  const hidden = useMemo(
    () =>
      dotsWidth > dotSizeWidth
        ? Math.round((dotsWidth - dotSizeWidth) / (widthInt + marginInt))
        : 0,
    [widthInt, marginInt, dotSizeWidth, dotsWidth, items.length],
  )

  const handleDotClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, number: number) => {
    const transformStyle = String(dotsRef.current?.style.transform)
    const transNum = parseInt(transformStyle.replace('translate(', '').replace('-', '')) || 0

    goToSlide(number)

    const { offsetLeft } = e.target as HTMLElement
    const isNotLastDisplayedDot =
      dotSizeWidth && dotSizeWidth + Math.abs(transNum) - offsetLeft > widthInt + marginInt
    if (!isNotLastDisplayedDot && translation < hidden) {
      setTranslation((prev: number) => prev + 1)
    }
    if (translation > 0) {
      if (
        transNum + widthInt + marginInt === offsetLeft ||
        offsetLeft === transNum ||
        offsetLeft === translation * (widthInt + marginInt) ||
        number === translation
      ) {
        setTranslation((prev: number) => prev - 1)
      }
    }
  }

  const padding =
    isMobile && isSlidePosition ? 4 : styles.icons.sliderPosition === ArrowPosition.slide ? 8 : 0
  const dotsCn = cn(s.dots, {
    [s.center]: !hidden || type === ProgressType.number,
  })

  const maskCn = cn(s.mask, {
    [s.left]: translation && slideIndex >= 1,
    [s.right]: translation === 0 && hidden,
    [s.full]: translation > 0 && slideIndex !== items.length - 1,
  })

  const translationPx = translation * (widthInt + marginInt)

  useEffect(() => {
    setHidden(hidden)
  }, [hidden])

  return (
    <div className={s.root}>
      <div
        className={dotsCn}
        ref={dotsRef}
        style={{
          paddingBottom: padding,
          transform:
            translation && type === ProgressType.dot
              ? `translate(-${translationPx}px, 0px)`
              : undefined,
          marginTop: (isMobile && isSlidePosition) || isSlidePosition ? margins.bottom : undefined,
        }}
      >
        {type === ProgressType.number ? (
          <div className={s.numbered} style={font?.base}>
            {label}
          </div>
        ) : (
          items.map((_, i) => (
            <Dot handleClick={(e) => handleDotClick(e, i)} key={i} number={i} styles={styles} />
          ))
        )}
      </div>
      {type === ProgressType.dot && <div className={maskCn} />}
    </div>
  )
}

export default Progress
