import cn from 'classnames'
import React, { useCallback } from 'react'

import { IOption, KitSize } from 'components/uiKit/KitTypes'
import { testProps } from 'utils/test/qaData'

import s from './Checkbox.module.scss'
import CheckboxItem, { CheckboxDefaultType } from './CheckboxItem'

export type Value = string | number | string[] | boolean
export type ArrayElement<A> = A extends (infer T)[] ? T : A

export interface ICheckboxOption<T extends Value | Value[] = Value> {
  label?: React.ReactNode | React.FC
  valueTrue: T
  valueFalse: T
}

export interface ICheckboxProps<T extends Value | Value[] = Value> extends CheckboxDefaultType {
  name: string
  onChange?: (value: T) => void
  value: T
  option?: ICheckboxOption<T>
  options?: IOption<ArrayElement<T>>[]
  hideCheckbox?: boolean
  children?: React.ReactNode | string | null
  disabled?: boolean
  indeterminate?: boolean
  direction?: 'vertical' | 'horizontal'
  testData?: string
  size?: KitSize
  error?: boolean
  activeColor?: string
}

const Checkbox = <T extends Value = Value>({
  name,
  onChange,
  value,
  option,
  options,
  children,
  hideCheckbox,
  disabled,
  indeterminate,
  direction = 'horizontal',
  testData,
  error,
  activeColor,
  ...rest
}: ICheckboxProps<T>) => {
  const onChangeWrapper = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value: checkboxValue, checked } = event.target
      if (option) {
        return onChange?.(value === option.valueTrue ? option.valueFalse : option.valueTrue)
      } else if (options) {
        const typedValue = (value || []) as string[]
        const newValue = checked
          ? [...typedValue, checkboxValue]
          : typedValue.filter((item) => item !== checkboxValue)

        return onChange?.(newValue as T)
      } else {
        return onChange?.(!value as T)
      }
    },
    [onChange, value, options],
  )

  if (options) {
    return (
      <div className={cn(s.root, s[direction])} {...testProps({ el: 'checkbox', name, testData })}>
        {options.map((item) => (
          <CheckboxItem
            {...rest}
            activeColor={activeColor}
            disabled={disabled}
            hideCheckbox={hideCheckbox}
            indeterminate={indeterminate}
            item={item}
            key={String(item.value)}
            name={name}
            onChange={onChangeWrapper}
            value={value}
          />
        ))}
      </div>
    )
  }

  return (
    <CheckboxItem
      {...rest}
      activeColor={activeColor}
      disabled={disabled}
      hideCheckbox={hideCheckbox}
      indeterminate={indeterminate}
      item={{ label: children, value: value as any }}
      name={name}
      onChange={onChangeWrapper}
      option={option}
      testData={testData}
      value={value}
    />
  )
}

export default Checkbox
