import { TestResultFontSchemaType } from '@leenda/editor/lib/brand'
import cn from 'classnames'

import { ElementFontCss } from 'services/Branding/types'
import { testPropsEl } from 'utils/test/qaData'

import s from './Progress.module.scss'

export enum ProgressType {
  line = 'line',
  mini = 'mini',
  circle = 'circle',
}

interface IProgress {
  name: string
  percent: number
  label?: string
  warning?: boolean
  danger?: boolean
  styleType?: ProgressType
  // TODO: remove when the course component appears
  customStyles?: { trail?: string; stroke?: string }
  reverseLabel?: boolean
  size?: 'mini' | 'default'
  font?: ElementFontCss<TestResultFontSchemaType>
  fluid?: boolean
}

const radius = { mini: 8, default: 78 }
const sizeProps = { mini: 18, default: 166 }

const Progress: React.FC<IProgress> = ({
  percent,
  warning,
  danger,
  styleType = ProgressType.line,
  name,
  label,
  customStyles,
  reverseLabel,
  font,
  size = 'default',
  fluid,
}) => {
  const dasharray = 2 * Math.PI * radius[size]
  const dashoffset = ((100 - percent) / 100) * dasharray
  const circleCoordinates = sizeProps[size] / 2

  return (
    <div
      className={cn(s.root, s[styleType], s[size], { [s.reverse]: reverseLabel })}
      {...testPropsEl('progress', { name, value: percent })}
    >
      {styleType !== ProgressType.circle ? (
        <>
          <div
            className={cn(s.progressBar, { [s.fluid]: fluid })}
            style={{ backgroundColor: customStyles?.trail }}
          >
            <div
              className={cn(s.statusBar, {
                [s.danger]: danger,
                [s.warning]: warning,
              })}
              style={{
                width: `${percent > 100 ? 100 : percent}%`,
                backgroundColor: customStyles?.stroke,
              }}
            />
          </div>
          {label && <span className={s.label}>{label}</span>}
        </>
      ) : (
        <div className={s.circle}>
          <div className={s.progressWrapper}>
            {label && (
              <div className={s.percent} style={font?.h2}>
                {label}
              </div>
            )}
            <svg className={s.circleProgress}>
              <circle
                className={s.trail}
                cx={circleCoordinates}
                cy={circleCoordinates}
                r={radius[size]}
                style={{ stroke: customStyles?.trail }}
              />
              <circle
                className={cn(s.stroke, { [s.danger]: danger, [s.warning]: warning })}
                cx={circleCoordinates}
                cy={circleCoordinates}
                r={radius[size]}
                style={{
                  strokeDasharray: dasharray,
                  strokeDashoffset: dashoffset,
                  stroke: customStyles?.stroke,
                }}
              />
            </svg>
          </div>
        </div>
      )}
    </div>
  )
}

export default Progress
