"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlipMethodCardEnum = exports.AnimationTypeCardEnum = exports.IconsAlignEnum = exports.InitialOpen = exports.ProgressDescriptionPosition = exports.MenuPositionType = exports.SidebarMenuType = void 0;
var SidebarMenuType;
(function (SidebarMenuType) {
    SidebarMenuType["fixed"] = "fixed";
    SidebarMenuType["drawer"] = "drawer";
})(SidebarMenuType || (exports.SidebarMenuType = SidebarMenuType = {}));
var MenuPositionType;
(function (MenuPositionType) {
    MenuPositionType["left"] = "left";
    MenuPositionType["right"] = "right";
})(MenuPositionType || (exports.MenuPositionType = MenuPositionType = {}));
var ProgressDescriptionPosition;
(function (ProgressDescriptionPosition) {
    ProgressDescriptionPosition["none"] = "none";
    ProgressDescriptionPosition["left"] = "left";
    ProgressDescriptionPosition["right"] = "right";
})(ProgressDescriptionPosition || (exports.ProgressDescriptionPosition = ProgressDescriptionPosition = {}));
var InitialOpen;
(function (InitialOpen) {
    InitialOpen["Expanded"] = "Expanded";
    InitialOpen["Collapsed"] = "Collapsed";
})(InitialOpen || (exports.InitialOpen = InitialOpen = {}));
var IconsAlignEnum;
(function (IconsAlignEnum) {
    IconsAlignEnum["hide"] = "hide";
    IconsAlignEnum["leftUp"] = "leftUp";
    IconsAlignEnum["rightUp"] = "rightUp";
    IconsAlignEnum["leftDown"] = "leftDown";
    IconsAlignEnum["rightDown"] = "rightDown";
})(IconsAlignEnum || (exports.IconsAlignEnum = IconsAlignEnum = {}));
var AnimationTypeCardEnum;
(function (AnimationTypeCardEnum) {
    AnimationTypeCardEnum["horizontalFlip"] = "horizontalFlip";
    AnimationTypeCardEnum["verticalFlip"] = "verticalFlip";
    AnimationTypeCardEnum["diagonalFlip"] = "diagonalFlip";
    AnimationTypeCardEnum["book"] = "book";
    AnimationTypeCardEnum["fadeEffect"] = "fadeEffect";
})(AnimationTypeCardEnum || (exports.AnimationTypeCardEnum = AnimationTypeCardEnum = {}));
var FlipMethodCardEnum;
(function (FlipMethodCardEnum) {
    FlipMethodCardEnum["click"] = "click";
    FlipMethodCardEnum["hover"] = "hover";
})(FlipMethodCardEnum || (exports.FlipMethodCardEnum = FlipMethodCardEnum = {}));
