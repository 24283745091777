import { Droppable, Draggable, DragDropContext, DropResult } from '@hello-pangea/dnd'
import { MatchingItem as MatchingItemType } from '@leenda/editor/lib/elements'
import { textToRtValue } from '@leenda/rich-text'
import cn from 'classnames'
import * as R from 'ramda'
import { useContext } from 'use-context-selector'

import AbsolutePortal from 'components/editor-v3/components/AbsolutePortal'
import RichText from 'components/form/RichText'
import { IconButton } from 'components/uiKit/Button'
import { Tooltip } from 'components/uiKit/Dropdown'
import { KitSize } from 'components/uiKit/KitTypes'
import { t } from 'services/Translation'

import TestMatchingContext from '../TestMatchingContext'
import s from './DefaultElementEditor.module.scss'
import MatchingItem from './MatchingItem'

const genName = (index: number, side: 'leftLabel' | 'rightLabel') => `portalItem.${index}.${side}`

const DefaultElementEditor: React.FC = () => {
  const {
    styles,
    value,
    isActiveElement,
    onLabelSelect,
    activeIndex,
    onChangeLabel,
    isFill,
    font,
    onChange,
    elementId,
    waiting,
  } = useContext(TestMatchingContext)

  const handleDelete = (item: MatchingItemType) => () =>
    onChange?.(
      R.assoc(
        'items',
        value.items.filter(({ value: id }) => id !== item.value),
        value,
      ),
    )

  const handleMove = ({ destination, source }: DropResult) =>
    destination &&
    onChange?.(R.assoc('items', R.move(source.index, destination.index, value.items), value))

  return (
    <DragDropContext onDragEnd={handleMove}>
      <Droppable direction='vertical' droppableId={elementId}>
        {(provided, { isDraggingOver }) => (
          <div
            className={s.matchingElement}
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ marginBottom: `-${styles.indents.marginBottom}` }}
          >
            {value.items.map((item, index) => (
              <Draggable draggableId={item.value} index={index} key={item.value}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={cn(s.row, { [s.active]: activeIndex === index.toString() })}
                    key={item.value}
                    style={{
                      ...provided.draggableProps.style,
                      marginBottom: styles.indents.marginBottom,
                    }}
                  >
                    <div className={s.item}>
                      <MatchingItem
                        font={font}
                        index={index}
                        isDragged={snapshot.isDragging}
                        mode='left'
                        styles={styles}
                      >
                        <div
                          className={s.text}
                          onMouseDown={(e) => onLabelSelect(genName(index, 'leftLabel'), e)}
                        >
                          <RichText
                            active={isActiveElement && activeIndex === genName(index, 'leftLabel')}
                            disabled={!isFill}
                            name={genName(index, 'leftLabel')}
                            onChange={(val) => onChangeLabel(val, 'leftLabel', index)}
                            styles={font}
                            value={item.leftLabel || textToRtValue('')}
                            waiting={waiting}
                          />
                        </div>
                      </MatchingItem>
                    </div>
                    <div className={s.item}>
                      <MatchingItem font={font} index={index} mode='right' styles={styles}>
                        <div
                          className={s.text}
                          onMouseDown={(e) => onLabelSelect(genName(index, 'rightLabel'), e)}
                        >
                          <RichText
                            active={isActiveElement && activeIndex === genName(index, 'rightLabel')}
                            disabled={!isFill}
                            name={genName(index, 'rightLabel')}
                            onChange={(val) => onChangeLabel(val, 'rightLabel', index)}
                            styles={font}
                            value={item.rightLabel || textToRtValue('')}
                            waiting={waiting}
                          />
                        </div>
                      </MatchingItem>
                    </div>
                    {isFill && isActiveElement && !isDraggingOver && (
                      <AbsolutePortal
                        name='deleteMultipleChoiceItem'
                        placement='right'
                        translateX='11px'
                        translateY='-50%'
                      >
                        <Tooltip overlay={t('uiKit.tooltip.deleteAnswer')}>
                          <IconButton
                            disabled={value.items.length < 3}
                            icon='otherClose'
                            name='deleteMultipleChoiceItem'
                            onClick={handleDelete(item)}
                            size={KitSize.XS}
                            styleType='secondary'
                          />
                        </Tooltip>
                      </AbsolutePortal>
                    )}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DefaultElementEditor
