import { BrandType } from 'services/Branding/types'
import { t } from 'services/Translation'

export enum ProjectLabelGroups {
  courseMenu = 'courseMenu',
  courseStructure = 'courseStructure',
  navigationButton = 'navigationButton',
  finishModal = 'finishModal',
  elements = 'elements',
  test = 'test',
  updateCourseModal = 'updateCourseModal',
}
export const KEYS_ORDER = {
  [ProjectLabelGroups.courseMenu]: ['header.courseContent', 'header.courseContentHide'],
  [ProjectLabelGroups.courseStructure]: [
    'courseStructure.landing',
    'courseStructure.test',
    'courseStructure.chapter',
    'courseStructure.cover',
    'courseStructure.chapterName',
    'courseStructure.blocks',
    'courseStructure.questions',
    'courseStructure.sections',
  ],
  [ProjectLabelGroups.navigationButton]: [
    'elements.button.continue.label',
    'elements.button.start.label',
    'navigationButton.return',
    'navigationButton.continue',
    'navigationButton.end',
  ],
  [ProjectLabelGroups.finishModal]: ['finishModal.title', 'finishModal.text', 'finishModal.button'],
  [ProjectLabelGroups.elements]: [
    'elements.button.common.label',
    'elements.button.continue.tooltip',
    'elements.embedded.notSupported',
    'elements.grouping.answer.placeholder',
    'elements.grouping.group.placeholder',
    'elements.grouping.result',
    'elements.grouping.retry',
    'elements.link.placeholder',
    'elements.slider.start',
    'elements.slider.end',
  ],
  [ProjectLabelGroups.test]: [
    'footer.try',
    'footer.from',
    'elements.testKeyboardInput.placeholder',
    'elements.test-info.from',
    'elements.test-info.limit',
    'elements.test-info.timeDisabled',
    'button.attempts',
  ],
  [ProjectLabelGroups.updateCourseModal]: [
    'updateCourseModal.title',
    'updateCourseModal.text',
    'updateCourseModal.restartButton',
  ],
}

export const HELPER: Record<keyof BrandType['labels'], { label?: string; info?: string }> = {
  'courseStructure.chapter': {
    label: t('page.projectSettings.labels.courseStructure.chapter'),
    info: t('page.projectSettings.labels.courseStructure.info'),
  },
  'courseStructure.cover': {
    label: t('page.projectSettings.labels.courseStructure.cover'),
    info: t('page.projectSettings.labels.courseStructure.info'),
  },
  'courseStructure.landing': {
    label: t('page.projectSettings.labels.courseStructure.longread'),
    info: t('page.projectSettings.labels.courseStructure.info'),
  },
  'courseStructure.test': {
    label: t('page.projectSettings.labels.courseStructure.test'),
    info: t('page.projectSettings.labels.courseStructure.info'),
  },
  'courseStructure.chapterName': {
    label: t('page.projectSettings.labels.courseStructure.chapterName'),
    info: t('page.projectSettings.labels.courseStructure.chapterName.info'),
  },
  'courseStructure.questions': {
    label: t('page.projectSettings.labels.courseStructure.questions'),
    info: t('page.projectSettings.labels.courseStructure.questions.info'),
  },
  'courseStructure.blocks': {
    label: t('page.projectSettings.labels.courseStructure.blocks'),
    info: t('page.projectSettings.labels.courseStructure.blocks.info'),
  },
  'courseStructure.sections': {
    label: t('page.projectSettings.labels.courseStructure.sections'),
    info: t('page.projectSettings.labels.courseStructure.sections.info'),
  },
  'navigationButton.continue': {
    label: t('page.projectSettings.labels.navigationButton.continue'),
    info: t('page.projectSettings.labels.navigationButton.continue.info'),
  },
  'navigationButton.end': {
    label: t('page.projectSettings.labels.navigationButton.end'),
    info: t('page.projectSettings.labels.navigationButton.end.info'),
  },
  'navigationButton.return': {
    label: t('page.projectSettings.labels.navigationButton.return'),
    info: t('page.projectSettings.labels.navigationButton.return.info'),
  },
  'header.courseContent': {
    label: t('page.projectSettings.labels.header.courseContent'),
    info: t('page.projectSettings.labels.header.courseContent.info'),
  },
  'header.courseContentHide': {
    label: t('page.projectSettings.labels.header.courseContentHide'),
    info: t('page.projectSettings.labels.header.courseContentHide.info'),
  },
  'footer.try': {
    label: t('page.projectSettings.labels.footer.try'),
    info: t('page.projectSettings.labels.footer.try.info'),
  },
  'footer.from': {
    label: t('page.projectSettings.labels.footer.from'),
    info: t('page.projectSettings.labels.footer.from.info'),
  },
  'finishModal.title': {
    label: t('page.projectSettings.labels.finishModal.title'),
  },
  'finishModal.text': {
    label: t('page.projectSettings.labels.finishModal.text'),
  },
  'finishModal.button': {
    label: t('page.projectSettings.labels.finishModal.button'),
  },
  'elements.button.common.label': {
    label: t('page.projectSettings.labels.elements.button.common.label'),
    info: t('page.projectSettings.labels.elements.button.common.label.info'),
  },
  'elements.button.continue.label': {
    label: t('page.projectSettings.labels.elements.button.continue.label'),
    info: t('page.projectSettings.labels.elements.button.continue.label.info'),
  },
  'elements.button.continue.tooltip': {
    label: t('page.projectSettings.labels.elements.button.continue.tooltip'),
    info: t('page.projectSettings.labels.elements.button.continue.tooltip.info'),
  },
  'elements.button.start.label': {
    label: t('page.projectSettings.labels.elements.button.start.label'),
    info: t('page.projectSettings.labels.elements.button.start.label.info'),
  },
  'elements.embedded.notSupported': {
    label: t('page.projectSettings.labels.elements.embedded.notSupported'),
    info: t('page.projectSettings.labels.elements.embedded.notSupported.info'),
  },
  'elements.grouping.answer.placeholder': {
    label: t('page.projectSettings.labels.elements.grouping.answer.placeholder'),
    info: t('page.projectSettings.labels.elements.grouping.answer.placeholder.info'),
  },
  'elements.grouping.group.placeholder': {
    label: t('page.projectSettings.labels.elements.grouping.group.placeholder'),
    info: t('page.projectSettings.labels.elements.grouping.group.placeholder.info'),
  },
  'elements.grouping.result': {
    label: t('page.projectSettings.labels.elements.grouping.result'),
    info: t('page.projectSettings.labels.elements.grouping.result.info'),
  },
  'elements.grouping.retry': {
    label: t('page.projectSettings.labels.elements.grouping.retry'),
    info: t('page.projectSettings.labels.elements.grouping.retry.info'),
  },
  'elements.link.placeholder': {
    label: t('page.projectSettings.labels.elements.link.placeholder'),
    info: t('page.projectSettings.labels.elements.link.placeholder.info'),
  },
  'elements.slider.start': {
    label: t('page.projectSettings.labels.elements.slider.start'),
    info: t('page.projectSettings.labels.elements.slider.start.info'),
  },
  'elements.slider.end': {
    label: t('page.projectSettings.labels.elements.slider.end'),
    info: t('page.projectSettings.labels.elements.slider.end.info'),
  },
  'elements.testKeyboardInput.placeholder': {
    label: t('page.projectSettings.labels.elements.testKeyboardInput.placeholder'),
    info: t('page.projectSettings.labels.elements.testKeyboardInput.placeholder.info'),
  },
  'elements.test-info.from': {
    label: t('page.projectSettings.labels.elements.test-info.from'),
    info: t('page.projectSettings.labels.elements.test-info.from.info'),
  },
  'elements.test-info.limit': {
    label: t('page.projectSettings.labels.elements.test-info.limit'),
    info: t('page.projectSettings.labels.elements.test-info.limit.info'),
  },
  'elements.test-info.timeDisabled': {
    label: t('page.projectSettings.labels.elements.test-info.timeDisabled'),
    info: t('page.projectSettings.labels.elements.test-info.timeDisabled.info'),
  },
  'button.attempts': {
    label: t('page.projectSettings.button.attempts'),
    info: t('page.projectSettings.button.attempts.info'),
  },
  'updateCourseModal.title': {
    label: t('page.projectSettings.labels.updateCourseModal.title'),
  },
  'updateCourseModal.text': {
    label: t('page.projectSettings.labels.updateCourseModal.text'),
  },
  'updateCourseModal.restartButton': {
    label: t('page.projectSettings.labels.updateCourseModal.button'),
  },
  'updateCourseModal.cancelButton': {
    label: t('page.projectSettings.labels.updateCourseModal.cancelButton'),
  },
}
