import cn from 'classnames'

import { testPropsEl } from 'utils/test/qaData'

import s from './Progress.module.scss'

interface IProgress {
  name: string
  percent: number
  warning?: boolean
  danger?: boolean
  strokeWidth?: number
  trail?: string
  stroke?: string
}

const DEFAULT_RADIUS = 12

const Progress: React.FC<IProgress> = ({
  percent,
  warning,
  danger,
  name,
  strokeWidth = 1,
  trail,
  stroke,
}) => {
  const adjustedRadius = DEFAULT_RADIUS - strokeWidth / 2
  const dasharray = 2 * Math.PI * adjustedRadius
  const dashoffset = ((100 - Math.min(percent, 100)) / 100) * dasharray

  return (
    <div className={s.root} {...testPropsEl('progress', { name, value: percent })}>
      <svg className={s.circleProgress}>
        <circle
          className={s.trail}
          cx={DEFAULT_RADIUS}
          cy={DEFAULT_RADIUS}
          r={adjustedRadius}
          style={{ stroke: trail, strokeWidth }}
        />
        <circle
          className={cn(s.stroke, { [s.danger]: danger, [s.warning]: warning })}
          cx={DEFAULT_RADIUS}
          cy={DEFAULT_RADIUS}
          r={adjustedRadius}
          style={{
            strokeDasharray: dasharray,
            strokeDashoffset: dashoffset,
            stroke,
            strokeWidth,
          }}
        />
      </svg>
    </div>
  )
}

export default Progress
