import { CourseStructureFontSchemaType, CourseStructureSchemaType } from '@leenda/editor/lib/brand'
import {
  CollapseIconPosition,
  CourseStructureElementValue,
  CourseStructureItem,
  ProgressIconPosition,
} from '@leenda/editor/lib/elements'
import cn from 'classnames'
import React, { useMemo } from 'react'

import { ISectionTreeNode } from 'components/sections/ProjectStructure/utils'
import CollapseIcon from 'components/uiKit/Collapse/CollapseIcon'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { Section } from 'services/Store/Project/types'

import { SectionStatus } from '../SectionStatus/SectionStatus'
import { StructureItemElement } from '../StructureItemElement'
import { useDescription } from '../StructureItemElement/StructureItemElement'
import s from './StructureNodeElement.module.scss'
import { getGridTemplateStyle, getShowIcon } from './utils'

interface ITreeNodeProps {
  toggle?(active?: boolean): void
  open: boolean
  node: ISectionTreeNode<CourseStructureItem>
  onClick?: () => void
  value: CourseStructureElementValue
  styles: ElementStyleCss<CourseStructureSchemaType>
  font: ElementFontCss<CourseStructureFontSchemaType>
  sections: Section[]
  getDisabledSection: (id: string) => boolean
  getSectionProgress: (id: string) => number
}

const LEVEL_INDENT = 30

const StructureNodeElement: React.FC<ITreeNodeProps> = ({
  open,
  node,
  onClick,
  toggle,
  value,
  styles,
  font,
  sections,
  getSectionProgress,
  getDisabledSection,
}) => {
  const { item, children } = node
  const { isChapter } = item
  const marginLeft = item.lvl * LEVEL_INDENT
  const { collapsePosition } = value
  const disabled = useMemo(() => getDisabledSection(item.id), [getDisabledSection, item.id])
  const description = useDescription(font, item, children?.length)

  const iconPosition = [CollapseIconPosition.right, CollapseIconPosition.rightOutside].includes(
    collapsePosition,
  )
    ? 'right'
    : 'left'

  const customIconCollapse = open
    ? styles.collapseIcon.backgroundOpen
    : styles.collapseIcon.backgroundImage

  const collapseIcon = (
    <div className={cn(s.collapseIcon, s[collapsePosition])} onClick={() => toggle?.()}>
      {isChapter &&
        toggle &&
        collapsePosition !== CollapseIconPosition.none &&
        (customIconCollapse ? (
          <div className={s.customIcon} style={{ backgroundImage: customIconCollapse }} />
        ) : (
          <CollapseIcon iconPosition={iconPosition} open={open} />
        ))}
    </div>
  )

  const onClickHandler = () => !disabled && (isChapter ? toggle?.() : onClick?.())

  const rootCn = cn(s.root, {
    [s.cursorOpen]: isChapter && open,
    [s.cursorClose]: isChapter && !open,
    [s.cursorPointer]: !isChapter,
    [s.disabled]: disabled,
  })

  const spaceBetweenFromSections = styles?.offsetFromSection.marginTop
  const collapseOffset = styles?.offsetFromCollapse.marginRight
  const statusOffset = styles?.offsetFromProgress.marginRight
  const showIcon = getShowIcon(item.type, value)
  const rootStyles = {
    paddingBottom: spaceBetweenFromSections,
    ...getGridTemplateStyle(value, showIcon),
    ...(styles.item_hover.backgroundColor && {
      ['--hover-node']: styles.item_hover.backgroundColor,
    }),
  }

  return (
    <div className={rootCn} onClick={onClickHandler} style={rootStyles}>
      {/* row1 */}
      {value.collapsePosition === CollapseIconPosition.leftOutside && (
        <div className={s.collapse} style={{ marginRight: collapseOffset }}>
          {collapseIcon}
        </div>
      )}
      {value.progressPosition === ProgressIconPosition.left && (
        <div className={s.sectionStatus} style={{ marginRight: statusOffset }}>
          <SectionStatus
            disabled={disabled}
            getSectionProgress={getSectionProgress}
            node={node}
            open={open}
            sections={sections}
            styles={styles}
          />
        </div>
      )}
      {value.collapsePosition === CollapseIconPosition.left && (
        <div className={s.nodeIndent} style={{ marginRight: collapseOffset, marginLeft }}>
          {collapseIcon}
        </div>
      )}
      <StructureItemElement
        disabled={disabled}
        font={font}
        item={node.item}
        marginLeft={value.collapsePosition === CollapseIconPosition.left ? 0 : marginLeft}
        showIcon={showIcon}
        styles={styles}
      />
      {value.collapsePosition === CollapseIconPosition.right && (
        <div className={cn(s.nodeIndent, s.collapse)} style={{ marginLeft: collapseOffset }}>
          {collapseIcon}
        </div>
      )}
      {value.progressPosition === ProgressIconPosition.right && (
        <div className={s.sectionStatus} style={{ marginLeft: statusOffset }}>
          <SectionStatus
            disabled={disabled}
            getSectionProgress={getSectionProgress}
            node={node}
            open={open}
            sections={sections}
            styles={styles}
          />
        </div>
      )}
      {value.collapsePosition === CollapseIconPosition.rightOutside && (
        <div className={cn(s.nodeIndent, s.collapse)} style={{ marginLeft: collapseOffset }}>
          {collapseIcon}
        </div>
      )}
      {/* row2 */}
      {value.showDescription && (
        <div
          className={s.description}
          style={{
            ...(value.collapsePosition !== CollapseIconPosition.left && { marginLeft }),
          }}
        >
          {description}
        </div>
      )}
    </div>
  )
}

export default StructureNodeElement
