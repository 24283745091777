import lodash from 'lodash'
import { ChangeEvent, MutableRefObject, useEffect, useMemo, useRef } from 'react'

import { notify } from 'components/uiKit/Notification'
import { NotificationType } from 'components/uiKit/Notification/types'
import { FileMetaFor, UpsertType } from 'gql/__generated__/graphql'
import { t } from 'services/Translation'
import { UppyType } from 'store/models'
import { FileTypeEnum, TargetUploadFileEnum } from 'utils/fileTypes'
import { testProps } from 'utils/test/qaData'

import { ACCEPT_MAP_FILES_EXTENSIONS } from './constants'
import { useFileMeta } from './useSetFileMetaUppy'
import { errorFilesExt, getAllowedExtByFiles } from './utils'

export interface IFilePickerType {
  name: string
  className?: string
  type?: string
  multiple: boolean
  params: { companyId: string; projectId?: string; fileMetaGroupId?: string; taskId?: string }
  uppy: UppyType
  createdById?: string
  createdFor: FileMetaFor | UpsertType
  targetUploadFile?: TargetUploadFileEnum
  onChange?: (event?: ChangeEvent<HTMLInputElement>) => void
  fileTypes?: FileTypeEnum[]
}

const useFilePicker = ({
  name,
  multiple,
  fileTypes,
  uppy,
  params,
  type,
  createdFor,
  targetUploadFile,
  createdById,
  onChange,
}: IFilePickerType) => {
  const ref = useRef<HTMLInputElement | null>(null)
  const cbRef = useRef() as MutableRefObject<(event: ChangeEvent<HTMLInputElement>) => void>
  const accept = useMemo(() => {
    return fileTypes?.reduce((acc, item) => {
      if (item in ACCEPT_MAP_FILES_EXTENSIONS) {
        acc = `${acc} ${ACCEPT_MAP_FILES_EXTENSIONS[item].reduce((ac, ext) => `${ac}.${ext}, `, '')}`
      }
      return acc
    }, '')
  }, [fileTypes])
  useEffect(() => {
    const objTestProps = Object.entries(testProps({ el: 'filePicker', name }))

    const inputElement = document.createElement('input')
    objTestProps.forEach(([key, value]) => {
      if (typeof key === 'string' && typeof value === 'string') {
        inputElement.dataset[lodash.camelCase(key.replace('data-', ''))] = value
      }
    })
    accept && inputElement.setAttribute('accept', accept)
    inputElement.setAttribute('multiple', `${multiple}`)
    inputElement.setAttribute('type', 'file')
    document.body.appendChild(inputElement)
    inputElement.addEventListener('change', (e) => {
      cbRef.current && cbRef.current(e as unknown as ChangeEvent<HTMLInputElement>)
    })
    inputElement.style.display = 'none'
    ref.current = inputElement
    return () => inputElement.remove()
  }, [ref, accept])

  const addFiles = useFileMeta({
    uppy,
    createdFor,
    createdById,
    type,
    targetUploadFile,
    fieldName: name,
    ...params,
  })

  cbRef.current = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event?.target
    if (target?.files) {
      const files = Array.from(target.files)
      const { validFiles, errorFiles } = getAllowedExtByFiles(files, fileTypes || [])

      if (validFiles.length) {
        addFiles(validFiles)
      }
      if (errorFiles.length) {
        const extensionsError = errorFilesExt(errorFiles)
        notify({
          type: NotificationType.warning,
          message: t('notify.file.uploadFileError', {
            count: errorFiles.length,
            extensions: extensionsError,
          }),
        })
      }
      /* <-------- FROM LIBRARY ---------> */
      // https://github.com/transloadit/uppy/blob/master/packages/%40uppy/file-input/src/index.js
      // We clear the input after a file is selected, because otherwise
      // change event is not fired in Chrome and Safari when a file
      // with the same name is selected.
      // ___Why not use value="" on <input/> instead?
      //    Because if we use that method of clearing the input,
      //    Chrome will not trigger change if we drop the same file twice (Issue #768).
      onChange && onChange(event)
      target!.value = null as any
    } else {
      onChange && onChange()
    }
  }

  const onClickUploadFile = () => {
    ref.current?.click()
  }

  return { onClickUploadFile }
}

export default useFilePicker
