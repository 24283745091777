import styled from 'styled-components'

import { NavButtonStyles } from '../../helpers'

const BaseNavigationButtonStyled = styled.button<{
  $hover: { backgroundColor?: string }
  $button: NavButtonStyles
}>`
  transition: 0.3s;
  background-color: ${({ $button }) => $button.backgroundColor};
  &:hover {
    background-color: ${({ $hover }) => $hover.backgroundColor};
  }
`

export default BaseNavigationButtonStyled
