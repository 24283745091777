import React, { useCallback, useEffect, useRef, useState } from 'react'

import Highlighter from 'components/uiKit/Highlighter'
import { IKitControl } from 'components/uiKit/KitTypes'
import { ITypographyProps, Typography } from 'components/uiKit/Typography'

import { restoreCaretPosition, saveCaretPosition, clearBreaks } from './caret'

export interface IEditableProps
  extends Omit<ITypographyProps, 'children' | 'name'>,
    IKitControl<string | null> {
  testData?: string
  max?: number
  highlight?: string
  error?: boolean
}

const Editable = React.forwardRef<HTMLSpanElement, IEditableProps>(
  (
    {
      name,
      value,
      onChange,
      onKeyDown,
      onBlur,
      onFocus,
      disabled,
      readOnly,
      max,
      highlight,
      tabIndex = 0,
      rows,
      error,
      autoFocus,
      ...rest
    },
    outerRef,
  ) => {
    const [focused, setFocused] = useState(false)
    const localRef = useRef<HTMLSpanElement>(null)
    const ref = (outerRef || localRef) as React.MutableRefObject<HTMLSpanElement | null>
    const [caret, setCaret] = useState(value?.length || 0)

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLSpanElement>) => {
        if (max && e.target.innerText.length > max) {
          e.target.innerText = value || ''
          onChange?.(clearBreaks((value || e.target.innerText).slice(0, max + 1)))
          restoreCaretPosition(ref, value?.length || 0)
        } else {
          onChange?.(clearBreaks(e.target.innerText))
        }
        const caret = saveCaretPosition(ref) || value?.length || 0
        setCaret(caret)
      },
      [ref, max, value, onChange],
    )

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLSpanElement>) => {
        onKeyDown?.(e)
        if (e.key === 'Enter') {
          e.preventDefault()
        }
      },
      [onKeyDown],
    )
    const handleFocus = useCallback(() => {
      onFocus?.()
      setFocused(true)
    }, [onFocus])

    const handleBlur = useCallback(() => {
      onBlur?.()
      setFocused(false)
    }, [onBlur])

    useEffect(() => {
      focused && restoreCaretPosition(ref, caret)
    }, [caret, focused, ref, value])

    useEffect(() => {
      autoFocus && ref.current?.focus()
    }, [autoFocus, ref])

    return (
      <Typography
        {...rest}
        name={name}
        contentEditable={!disabled && !readOnly}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onInput={handleChange}
        onKeyDown={handleKeyDown}
        ref={ref}
        rows={focused && !disabled && !readOnly ? 0 : rows}
        tabIndex={!disabled && !readOnly ? tabIndex : -1}
        focused={focused}
        suppressContentEditableWarning
      >
        {value && highlight ? <Highlighter search={highlight} text={value} breakWord /> : value}
      </Typography>
    )
  },
)

Editable.displayName = 'Editable'

export default Editable
