import { CourseStructureFontSchemaType, CourseStructureSchemaType } from '@leenda/editor/lib/brand'
import {
  CollapseIconPosition,
  ProgressIconPosition,
  SectionTypeEnum,
} from '@leenda/editor/lib/elements'

import { listToTree } from 'components/sections/ProjectStructure/utils'
import { NOOP } from 'constants/commonConstans'
import { Section } from 'services/Store/Project/types'
import { getEnumLabel } from 'utils/enum'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import CourseStructureTree from './CourseStructureTree/CourseStructureTree'
import CourseDemoStyled from './styled/CourseDemoStyled'

export const SECTIONS_DEMO_CONFIG = [
  {
    id: '1',
    progress: 50,
    closed: false,
  },
  {
    id: '2',
    progress: 100,
    closed: false,
  },
  {
    id: '3',
    progress: 0,
    closed: false,
  },
  {
    id: '4',
    progress: 50,
    closed: false,
  },
  {
    id: '5',
    progress: 0,
    closed: true,
  },
]

const SECTIONS_DEMO: Section[] = [
  {
    id: '1',
    name: getEnumLabel('SectionTypeEnum', SectionTypeEnum.chapter),
    type: SectionTypeEnum.chapter,
    blocks: [],
    lvl: 0,
    isChapter: true,
    blocksOrder: [],
    isDone: false,
    isHide: false,
    tags: [],
    publicLink: {
      __typename: 'Link',
      shortLink: '',
      isOpen: false,
    },
  },
  {
    id: '2',
    name: getEnumLabel('SectionTypeEnum', SectionTypeEnum.landing),
    type: SectionTypeEnum.landing,
    blocks: [],
    lvl: 1,
    isChapter: false,
    blocksOrder: [],
    isDone: false,
    isHide: false,
    tags: [],
    publicLink: {
      __typename: 'Link',
      shortLink: '',
      isOpen: false,
    },
  },
  {
    id: '3',
    name: getEnumLabel('SectionTypeEnum', SectionTypeEnum.landing),
    type: SectionTypeEnum.landing,
    blocks: [],
    lvl: 1,
    isChapter: false,
    blocksOrder: [],
    isDone: false,
    isHide: false,
    tags: [],
    publicLink: {
      __typename: 'Link',
      shortLink: '',
      isOpen: false,
    },
  },
  {
    id: '4',
    name: getEnumLabel('SectionTypeEnum', SectionTypeEnum.test),
    type: SectionTypeEnum.test,
    blocks: [],
    lvl: 0,
    isChapter: false,
    blocksOrder: [],
    isDone: false,
    isHide: false,
    tags: [],
    publicLink: {
      __typename: 'Link',
      shortLink: '',
      isOpen: false,
    },
  },
  {
    id: '5',
    name: getEnumLabel('SectionTypeEnum', SectionTypeEnum.landing),
    type: SectionTypeEnum.landing,
    blocks: [],
    lvl: 0,
    isChapter: false,
    blocksOrder: [],
    isDone: true,
    isHide: false,
    tags: [],
    publicLink: {
      __typename: 'Link',
      shortLink: '',
      isOpen: false,
    },
  },
]

const DEMO_VALUES = {
  showDescription: true,
  showChapterIcon: true,
  showLandingIcon: true,
  showTestIcon: true,
  initialOpen: true,
  progressPosition: ProgressIconPosition.left,
  collapsePosition: CollapseIconPosition.left,
  sectionsDefault: SECTIONS_DEMO,
}

const getDisabledSectionDemo = (id: string) =>
  !!SECTIONS_DEMO_CONFIG.find((item) => item.id === id)?.closed

const getSectionProgressDemo = (sectionId: string) =>
  SECTIONS_DEMO_CONFIG.find(({ id }) => id === sectionId)?.progress || 0

const SECTIONS_THREE = listToTree(SECTIONS_DEMO)

const CourseStructureDemo: React.FC<
  IElementDemoProps<CourseStructureSchemaType, CourseStructureFontSchemaType>
> = ({ styles, fonts }) => (
  <DemoWrapper center>
    <CourseDemoStyled>
      <CourseStructureTree
        font={fonts}
        getDisabledSection={getDisabledSectionDemo}
        getSectionProgress={getSectionProgressDemo}
        nodes={SECTIONS_THREE}
        onSectionPick={NOOP}
        section={SECTIONS_DEMO[0]}
        sections={SECTIONS_DEMO}
        styles={styles}
        value={DEMO_VALUES}
      />
    </CourseDemoStyled>
  </DemoWrapper>
)

export default CourseStructureDemo
