import ExternalFile from '../inputComponents/ExternalFile'
import FieldArray from '../inputComponents/FieldArray'
import FieldCheckbox from '../inputComponents/FieldCheckbox'
import FieldColorPicker from '../inputComponents/FieldColorPicker'
import FieldCustom from '../inputComponents/FieldCustom'
import FieldDatePicker from '../inputComponents/FieldDatePicker'
import FieldEditable from '../inputComponents/FieldEditable'
import FieldFile from '../inputComponents/FieldFile'
import FieldFileId from '../inputComponents/FieldFileId'
import FieldFiles from '../inputComponents/FieldFiles'
import FieldGroup from '../inputComponents/FieldGroup'
import FieldInput from '../inputComponents/FieldInput'
import FieldNumber from '../inputComponents/FieldNumber'
import FieldPalette from '../inputComponents/FieldPalette'
import FieldPassword from '../inputComponents/FieldPassword'
import FieldPhone from '../inputComponents/FieldPhone'
import FieldRadio from '../inputComponents/FieldRadio'
import FieldRichText from '../inputComponents/FieldRichText'
import FieldSegmented from '../inputComponents/FieldSegmented'
import FieldSelect from '../inputComponents/FieldSelect'
import FieldSlider from '../inputComponents/FieldSlider'
import FieldSwitcher from '../inputComponents/FieldSwitcher'
import FieldTag from '../inputComponents/FieldTag'
import FieldTextArea from '../inputComponents/FieldTextArea'
import FieldTime from '../inputComponents/FieldTime'
import FieldTimeRange from '../inputComponents/FieldTimeRange'

export type FieldsMapType = typeof FieldsMap

const FieldsMap = {
  text: FieldInput,
  editable: FieldEditable,
  textarea: FieldTextArea,
  richtext: FieldRichText,
  number: FieldNumber,
  select: FieldSelect,
  tag: FieldTag,
  checkbox: FieldCheckbox,
  radio: FieldRadio,
  segmented: FieldSegmented,
  phone: FieldPhone,
  password: FieldPassword,
  date: FieldDatePicker,
  time: FieldTime,
  timeRange: FieldTimeRange,
  slider: FieldSlider,
  externalFile: ExternalFile,
  switcher: FieldSwitcher,

  // Complex fields
  array: FieldArray,
  group: FieldGroup,
  custom: FieldCustom,

  // TODO: circularDeps (by storybook)
  palette: FieldPalette,
  color: FieldColorPicker,
  file: FieldFile,
  fileId: FieldFileId,
  files: FieldFiles,
}

export default FieldsMap
