import { CourseStructureItem } from '@leenda/editor/lib/elements'
import React from 'react'

import { useCourseSidebarData } from 'components/editor-v3/cource/controls/CourseSidebar/context/selectors'
import { getIconData } from 'components/editor-v3/cource/controls/CourseSidebar/utils'
import Highlighter from 'components/uiKit/Highlighter'
import { useProjectT } from 'services/ProjectLabels'
import { scrollProps } from 'services/Scroll/ScrollService'
import { testPropsEl } from 'utils/test/qaData'

import s from './StructureItemCourse.module.scss'

interface IItemsProps {
  item: CourseStructureItem
  onSelect?: (sectionId: string) => void
  searchValue?: string
  nestedOffset?: number
  disabled?: boolean
}

const StructureItemCourse: React.FC<IItemsProps> = ({
  item,
  onSelect,
  searchValue,
  nestedOffset,
  disabled,
}) => {
  const { styles, font } = useCourseSidebarData()
  const pt = useProjectT()
  const { showIcon, IconImage, styleFontSection, icon, sectionNameStyles, iconStyles } =
    getIconData({
      type: item.type,
      styles,
      font,
      nestedOffset,
      disabled,
    })

  return (
    <>
      {showIcon && (
        <div className={IconImage ? s.iconImage : s.itemIcon} style={iconStyles}>
          {!IconImage && icon}
        </div>
      )}
      <div
        {...testPropsEl('sectionItem', { name: item.name }, { id: item.id })}
        {...scrollProps(item.id)}
        className={s.item}
        onClick={() => onSelect?.(item.id)}
        style={sectionNameStyles}
      >
        <div className={s.itemContent}>
          <div style={styleFontSection}>
            {item.name ? (
              <Highlighter search={searchValue} text={item.name} breakWord />
            ) : (
              pt('courseStructure.chapterName')
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default StructureItemCourse
