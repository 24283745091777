import { DownloaderFontSchemaType, DownloaderSchemaType } from '@leenda/editor/lib/brand'
import styled from 'styled-components'

import { border, font, hover } from 'services/Branding/common.styled'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'

const DownloaderStyled = styled.div<{
  $styles: ElementStyleCss<DownloaderSchemaType>
  $fonts: ElementFontCss<DownloaderFontSchemaType>
}>`
  display: flex;
  height: 100%;
  align-items: center;
  text-decoration: none;
  cursor: pointer;

  background-color: ${({ $styles }) => $styles.downloader.backgroundColor};

  ${({ $fonts }) => font($fonts.base)}
  ${({ $styles }) => border($styles.border)}
  ${hover}
`

export default DownloaderStyled
