import { TestSingleChoiceSchemaType } from '@leenda/editor/lib/brand'
import styled, { css } from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

export const MarkStyled = styled.div<{
  $styles: ElementStyleCss<TestSingleChoiceSchemaType>
  $checked: boolean
  $disabled?: boolean
}>`
  position: relative;
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  margin-right: 8px;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  background-color: var(--color-background-main);
  border-color: ${({ $checked, $styles }) =>
    $checked ? $styles.radio.color : 'var(--color-placeholder)'};
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    display: ${({ $checked }) => ($checked ? 'block' : 'none')};
    width: 10px;
    height: 10px;
    top: 4px;
    left: 4px;
    border-radius: 50%;
    background-color: ${({ $styles }) => $styles.radio.color};
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed;
      border-color: var(--color-placeholder);
      background-color: var(--color-delimiter-main);

      &:hover {
        background-color: var(--color-delimiter-main);
      }

      &:after {
        background-color: var(--color-placeholder);
      }
    `}
`

export default MarkStyled
