import { LabeledGraphicSchemaType } from '@leenda/editor/lib/brand'
import styled, { keyframes } from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

const ring = keyframes` 
  0% {
    transform: translate(-50%, -50%) scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    transform: translate(-50%, -50%) scale(1, 1);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -50%) scale(1.3, 1.3);
    opacity: 0;
}`

const RingStyled = styled.div<{ $styles: ElementStyleCss<LabeledGraphicSchemaType> }>`
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0.3, 0.3);
  animation: ${ring} 3000ms ease-out infinite;
  opacity: 0;
  z-index: 1;

  background: ${({ $styles }) => $styles.point.color};
  width: ${({ $styles }) => `calc(${$styles.point.width} + 8px)`};
  height: ${({ $styles }) => `calc(${$styles.point.height} + 8px)`};

  &:nth-of-type(2) {
    animation-delay: 600ms;
  }

  &:nth-of-type(3) {
    animation-delay: 1200ms;
  }
`

export default RingStyled
