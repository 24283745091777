import { TabsFontSchemaType, TabsSchemaType } from '@leenda/editor/lib/brand'
import * as R from 'ramda'
import styled, { css } from 'styled-components'

import { border, font } from 'services/Branding/common.styled'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'

const active = ($styles: ElementStyleCss<TabsSchemaType>) => css`
  ${border(R.omit(['borderRadius'], $styles.border))}
  border-bottom-color: transparent;
  background: ${$styles.activeTab.backgroundColor};
  color: ${$styles.activeTab.color};
`

const TabItemStyled = styled.div<{
  $styles: ElementStyleCss<TabsSchemaType>
  $font: ElementFontCss<TabsFontSchemaType>
  $active: boolean
}>`
  transition: 0.2s;
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-right: 0;
  border-left: 0;
  cursor: pointer;
  padding: ${({ $styles }) => $styles.tabs.padding};
  margin-bottom: ${({ $styles }) => `calc(-${$styles.border.borderWidth} - 1px)`};

  ${({ $active, $styles: { splitter, border } }) =>
    !$active &&
    css`
      &:before {
        content: '';
        position: absolute;
        width: ${splitter.borderWidth};
        height: 16px;
        top: 50%;
        left: calc((-1 * ${border.borderWidth}) + (-1 * (${splitter.borderWidth} / 2)));
        border-right: ${`${splitter.borderWidth} ${splitter.borderStyle} ${splitter.borderColor}`};
        transform: translateY(-50%);
      }
    `}

  &[data-active="true"] + :not([data-active="true"])::before,
  &:nth-of-type(1):not([data-active="true"])::before {
    content: none;
  }

  border-width: ${({ $styles: { border } }) => border.borderWidth};
  border-style: ${({ $styles: { border } }) => border.borderStyle};
  border-color: transparent;
  border-top-left-radius: ${({ $styles: { border } }) => border.borderRadius};
  border-top-right-radius: ${({ $styles: { border } }) => border.borderRadius};

  ${({ $font: { base } }) => font(base)}
  ${({ $styles, $active }) => ($active ? active($styles) : '')}

  &:hover {
    background-color: ${({ $styles, $active }) => !$active && $styles.hover.backgroundColor};
    border-color: ${({ $active, $styles }) =>
      !$active && $styles.hover.backgroundColor && $styles.tabs.backgroundColor};
  }
  border-bottom-width: 0px;
`

export default TabItemStyled
