// GENERATE BY ./generate.js
// DO NOT EDIT IT MANUALLY
import { ReactComponent as Group } from '../icons/Group.svg'
import { ReactComponent as Add } from '../icons/add.svg'
import { ReactComponent as AlertCheck } from '../icons/alert-check.svg'
import { ReactComponent as AlertDangerTemp } from '../icons/alert-danger-temp.svg'
import { ReactComponent as AlertDanger } from '../icons/alert-danger.svg'
import { ReactComponent as AlertFailRed } from '../icons/alert-fail-red.svg'
import { ReactComponent as AlertFailWhite } from '../icons/alert-fail-white.svg'
import { ReactComponent as AlertInfo } from '../icons/alert-info.svg'
import { ReactComponent as AlignmentAlignBottom } from '../icons/alignment-align-bottom.svg'
import { ReactComponent as AlignmentAlignCenter } from '../icons/alignment-align-center.svg'
import { ReactComponent as AlignmentAlignFull } from '../icons/alignment-align-full.svg'
import { ReactComponent as AlignmentAlignLeft } from '../icons/alignment-align-left.svg'
import { ReactComponent as AlignmentAlignMid } from '../icons/alignment-align-mid.svg'
import { ReactComponent as AlignmentAlignRight } from '../icons/alignment-align-right.svg'
import { ReactComponent as AlignmentAlignTop } from '../icons/alignment-align-top.svg'
import { ReactComponent as Annotation } from '../icons/annotation.svg'
import { ReactComponent as ArrowTabs } from '../icons/arrow-tabs.svg'
import { ReactComponent as BlocksFill } from '../icons/blocks-fill.svg'
import { ReactComponent as Book } from '../icons/book.svg'
import { ReactComponent as BookmarkFill } from '../icons/bookmark-fill.svg'
import { ReactComponent as BookmarkStroke } from '../icons/bookmark-stroke.svg'
import { ReactComponent as BookmarksDoubleFill } from '../icons/bookmarks-double-fill.svg'
import { ReactComponent as BoxCollectionsArchive } from '../icons/box-collections-archive.svg'
import { ReactComponent as BuilderAbsolut } from '../icons/builder-absolut.svg'
import { ReactComponent as BuilderAddComponent } from '../icons/builder-add-component.svg'
import { ReactComponent as BuilderAdd } from '../icons/builder-add.svg'
import { ReactComponent as BuilderAi } from '../icons/builder-ai.svg'
import { ReactComponent as BuilderAlignCenter } from '../icons/builder-align-center.svg'
import { ReactComponent as BuilderAlignFullWidth } from '../icons/builder-align-full-width.svg'
import { ReactComponent as BuilderAlignLeft } from '../icons/builder-align-left.svg'
import { ReactComponent as BuilderAlignRight } from '../icons/builder-align-right.svg'
import { ReactComponent as BuilderArrowDown } from '../icons/builder-arrow-down.svg'
import { ReactComponent as BuilderArrowLeft } from '../icons/builder-arrow-left.svg'
import { ReactComponent as BuilderArrowRight } from '../icons/builder-arrow-right.svg'
import { ReactComponent as BuilderArrowScale } from '../icons/builder-arrow-scale.svg'
import { ReactComponent as BuilderArrowUp } from '../icons/builder-arrow-up.svg'
import { ReactComponent as BuilderBlockquote } from '../icons/builder-blockquote.svg'
import { ReactComponent as BuilderBold } from '../icons/builder-bold.svg'
import { ReactComponent as BuilderColumn } from '../icons/builder-column.svg'
import { ReactComponent as BuilderContentModeSettings } from '../icons/builder-content-mode-settings.svg'
import { ReactComponent as BuilderContent } from '../icons/builder-content.svg'
import { ReactComponent as BuilderDesktop } from '../icons/builder-desktop.svg'
import { ReactComponent as BuilderDrugAndDrop } from '../icons/builder-drug-and-drop.svg'
import { ReactComponent as BuilderDrug } from '../icons/builder-drug.svg'
import { ReactComponent as BuilderElement } from '../icons/builder-element.svg'
import { ReactComponent as BuilderH1 } from '../icons/builder-h-1.svg'
import { ReactComponent as BuilderH2 } from '../icons/builder-h-2.svg'
import { ReactComponent as BuilderH3 } from '../icons/builder-h-3.svg'
import { ReactComponent as BuilderH4 } from '../icons/builder-h-4.svg'
import { ReactComponent as BuilderInputBack } from '../icons/builder-input-back.svg'
import { ReactComponent as BuilderInputDown } from '../icons/builder-input-down.svg'
import { ReactComponent as BuilderInputForward } from '../icons/builder-input-forward.svg'
import { ReactComponent as BuilderInputUp } from '../icons/builder-input-up.svg'
import { ReactComponent as BuilderItalic } from '../icons/builder-italic.svg'
import { ReactComponent as BuilderLeftDown } from '../icons/builder-left-down.svg'
import { ReactComponent as BuilderLeftUp } from '../icons/builder-left-up.svg'
import { ReactComponent as BuilderListNumbers } from '../icons/builder-list-numbers.svg'
import { ReactComponent as BuilderList } from '../icons/builder-list.svg'
import { ReactComponent as BuilderLock } from '../icons/builder-lock.svg'
import { ReactComponent as BuilderLower } from '../icons/builder-lower.svg'
import { ReactComponent as BuilderMobile } from '../icons/builder-mobile.svg'
import { ReactComponent as BuilderProMode } from '../icons/builder-pro-mode.svg'
import { ReactComponent as BuilderPromode2 } from '../icons/builder-promode-2.svg'
import { ReactComponent as BuilderRightDown } from '../icons/builder-right-down.svg'
import { ReactComponent as BuilderRightUp } from '../icons/builder-right-up.svg'
import { ReactComponent as BuilderRow } from '../icons/builder-row.svg'
import { ReactComponent as BuilderSpace } from '../icons/builder-space.svg'
import { ReactComponent as BuilderSwitchLeft } from '../icons/builder-switch-left.svg'
import { ReactComponent as BuilderSwitchRight } from '../icons/builder-switch-right.svg'
import { ReactComponent as BuilderSymbol } from '../icons/builder-symbol.svg'
import { ReactComponent as BuilderTabBlocks } from '../icons/builder-tab-blocks.svg'
import { ReactComponent as BuilderTabLayers } from '../icons/builder-tab-layers.svg'
import { ReactComponent as BuilderTablet } from '../icons/builder-tablet.svg'
import { ReactComponent as BuilderText } from '../icons/builder-text.svg'
import { ReactComponent as BuilderTimeline } from '../icons/builder-timeline.svg'
import { ReactComponent as BuilderTooltip } from '../icons/builder-tooltip.svg'
import { ReactComponent as BuilderTypeParagraphCharacterAllCaps } from '../icons/builder-type-paragraph-character-all-caps.svg'
import { ReactComponent as BuilderTypeParagraphCharacterTextSizeSmall } from '../icons/builder-type-paragraph-character-text-size-small.svg'
import { ReactComponent as BuilderTypeParagraphCharacterTextSize } from '../icons/builder-type-paragraph-character-text-size.svg'
import { ReactComponent as BuilderUnderline } from '../icons/builder-underline.svg'
import { ReactComponent as BuilderUnderlinetextCross } from '../icons/builder-underlinetext-cross.svg'
import { ReactComponent as BuilderUpper } from '../icons/builder-upper.svg'
import { ReactComponent as BurgerMenu } from '../icons/burger-menu.svg'
import { ReactComponent as Cc } from '../icons/cc.svg'
import { ReactComponent as ChangeCompany } from '../icons/change-company.svg'
import { ReactComponent as Checkbox } from '../icons/checkbox.svg'
import { ReactComponent as Checkmark1 } from '../icons/checkmark-1.svg'
import { ReactComponent as CheckmarkCircle1 } from '../icons/checkmark-circle-1.svg'
import { ReactComponent as Collapse2Submenu2Reverse } from '../icons/collapse-2-submenu-2-reverse.svg'
import { ReactComponent as Collapse2Submenu2 } from '../icons/collapse-2-submenu-2.svg'
import { ReactComponent as CollapseOpen } from '../icons/collapse-open.svg'
import { ReactComponent as ContentsChapter } from '../icons/contents-chapter.svg'
import { ReactComponent as ContentsLanding } from '../icons/contents-landing.svg'
import { ReactComponent as ContentsSlides } from '../icons/contents-slides.svg'
import { ReactComponent as ContentsTest } from '../icons/contents-test.svg'
import { ReactComponent as CourseAnswerCorrect } from '../icons/course-answer-correct.svg'
import { ReactComponent as CourseAnswerIncorrect } from '../icons/course-answer-incorrect.svg'
import { ReactComponent as CourseArrowBack } from '../icons/course-arrow-back.svg'
import { ReactComponent as CourseArrowForward } from '../icons/course-arrow-forward.svg'
import { ReactComponent as Dash } from '../icons/dash.svg'
import { ReactComponent as Dots } from '../icons/dots.svg'
import { ReactComponent as EmptyColor } from '../icons/empty-color.svg'
import { ReactComponent as EyeCloseEye } from '../icons/eye-close-eye.svg'
import { ReactComponent as EyeOpenEye } from '../icons/eye-open-eye.svg'
import { ReactComponent as FacebookOld } from '../icons/facebookOld.svg'
import { ReactComponent as FixSpelling } from '../icons/fix-spelling.svg'
import { ReactComponent as Folder } from '../icons/folder.svg'
import { ReactComponent as Gear2 } from '../icons/gear-2.svg'
import { ReactComponent as HeadphonesSupport } from '../icons/headphones-support.svg'
import { ReactComponent as IconFillBell } from '../icons/icon-fill-bell.svg'
import { ReactComponent as IconFillFolder } from '../icons/icon-fill-folder.svg'
import { ReactComponent as IconFillOwner } from '../icons/icon-fill-owner.svg'
import { ReactComponent as IconFillStatusMarked } from '../icons/icon-fill-status-marked.svg'
import { ReactComponent as IconSendDisabled } from '../icons/icon-send-disabled.svg'
import { ReactComponent as IconSend } from '../icons/icon-send.svg'
import { ReactComponent as IconsBuilderLock } from '../icons/icons-builder-lock.svg'
import { ReactComponent as IconsExpandLeft } from '../icons/icons-expand-left.svg'
import { ReactComponent as IconsExpandRight } from '../icons/icons-expand-right.svg'
import { ReactComponent as IconsForElementsDown } from '../icons/icons-for-elements-down.svg'
import { ReactComponent as IconsForElementsLeftSlider } from '../icons/icons-for-elements-left-slider.svg'
import { ReactComponent as IconsForElementsLeft } from '../icons/icons-for-elements-left.svg'
import { ReactComponent as IconsForElementsRightSlider } from '../icons/icons-for-elements-right-slider.svg'
import { ReactComponent as IconsForElementsRight } from '../icons/icons-for-elements-right.svg'
import { ReactComponent as IconsForElementsRotateRight } from '../icons/icons-for-elements-rotate-right.svg'
import { ReactComponent as IconsOtherAddColumn } from '../icons/icons-other-add-column.svg'
import { ReactComponent as IconsOtherAddRow } from '../icons/icons-other-add-row.svg'
import { ReactComponent as IconsOtherAudio } from '../icons/icons-other-audio.svg'
import { ReactComponent as IconsOtherBoxHome } from '../icons/icons-other-box-home.svg'
import { ReactComponent as IconsOtherButton } from '../icons/icons-other-button.svg'
import { ReactComponent as IconsOtherCode } from '../icons/icons-other-code.svg'
import { ReactComponent as IconsOtherDistribution } from '../icons/icons-other-distribution.svg'
import { ReactComponent as IconsOtherElementCards } from '../icons/icons-other-element-cards.svg'
import { ReactComponent as IconsOtherEmbedCode } from '../icons/icons-other-embed-code.svg'
import { ReactComponent as IconsOtherFigure } from '../icons/icons-other-figure.svg'
import { ReactComponent as IconsOtherFolderCheckmark } from '../icons/icons-other-folder-checkmark.svg'
import { ReactComponent as IconsOtherGraphic } from '../icons/icons-other-graphic.svg'
import { ReactComponent as IconsOtherHeader } from '../icons/icons-other-header.svg'
import { ReactComponent as IconsOtherLanguageTranslateCircle } from '../icons/icons-other-language-translate-circle.svg'
import { ReactComponent as IconsOtherLine } from '../icons/icons-other-line.svg'
import { ReactComponent as IconsOtherMarginsLandingPages } from '../icons/icons-other-margins-landing-pages.svg'
import { ReactComponent as IconsOtherMarginsTestPages } from '../icons/icons-other-margins-test-pages.svg'
import { ReactComponent as IconsOtherMore } from '../icons/icons-other-more.svg'
import { ReactComponent as IconsOtherMoveTo } from '../icons/icons-other-move-to.svg'
import { ReactComponent as IconsOtherNavigationButtons } from '../icons/icons-other-navigation-buttons.svg'
import { ReactComponent as IconsOtherNullBg } from '../icons/icons-other-null-bg.svg'
import { ReactComponent as IconsOtherProgress } from '../icons/icons-other-progress.svg'
import { ReactComponent as IconsOtherResultDescription } from '../icons/icons-other-result-description.svg'
import { ReactComponent as IconsOtherResultMarkers } from '../icons/icons-other-result-markers.svg'
import { ReactComponent as IconsOtherRow } from '../icons/icons-other-row.svg'
import { ReactComponent as IconsOtherSidebar } from '../icons/icons-other-sidebar.svg'
import { ReactComponent as IconsOtherSingleChoose } from '../icons/icons-other-single-choose.svg'
import { ReactComponent as IconsOtherSlider } from '../icons/icons-other-slider.svg'
import { ReactComponent as IconsOtherSwitch } from '../icons/icons-other-switch.svg'
import { ReactComponent as IconsOtherTableEditTableEraser } from '../icons/icons-other-table-edit-table-eraser.svg'
import { ReactComponent as IconsOtherTable } from '../icons/icons-other-table.svg'
import { ReactComponent as IconsOtherTabs } from '../icons/icons-other-tabs.svg'
import { ReactComponent as IconsOtherVideo } from '../icons/icons-other-video.svg'
import { ReactComponent as IllustrationPageLock } from '../icons/illustration-page-lock.svg'
import { ReactComponent as ImagePicture } from '../icons/image-picture.svg'
import { ReactComponent as ImproveWriting } from '../icons/improve-writing.svg'
import { ReactComponent as InterfaceEssentialAnchor } from '../icons/interface-essential-anchor.svg'
import { ReactComponent as LayersFill } from '../icons/layers-fill.svg'
import { ReactComponent as LifeRingHelpSupportBug } from '../icons/life-ring-help-support-bug.svg'
import { ReactComponent as Link2 } from '../icons/link-2.svg'
import { ReactComponent as LinkedinOld } from '../icons/linkedinOld.svg'
import { ReactComponent as MainMenuBackButton } from '../icons/main-menu-back-button.svg'
import { ReactComponent as MainMenuBell } from '../icons/main-menu-bell.svg'
import { ReactComponent as MainMenuBranding } from '../icons/main-menu-branding.svg'
import { ReactComponent as MainMenuDashboard } from '../icons/main-menu-dashboard.svg'
import { ReactComponent as MainMenuFiles } from '../icons/main-menu-files.svg'
import { ReactComponent as MainMenuFont } from '../icons/main-menu-font.svg'
import { ReactComponent as MainMenuProjects } from '../icons/main-menu-projects.svg'
import { ReactComponent as MainMenuSettings } from '../icons/main-menu-settings.svg'
import { ReactComponent as MainMenuTags } from '../icons/main-menu-tags.svg'
import { ReactComponent as MainMenuTasks } from '../icons/main-menu-tasks.svg'
import { ReactComponent as MainMenuUsers } from '../icons/main-menu-users.svg'
import { ReactComponent as MakeLonger } from '../icons/make-longer.svg'
import { ReactComponent as MakeShorter } from '../icons/make-shorter.svg'
import { ReactComponent as MenuBurgerCircle2 } from '../icons/menu-burger-circle-2.svg'
import { ReactComponent as MenuBurgerSquare } from '../icons/menu-burger-square.svg'
import { ReactComponent as Menu } from '../icons/menu.svg'
import { ReactComponent as MessageFilterApplied } from '../icons/message-filter-applied.svg'
import { ReactComponent as MessageFilter } from '../icons/message-filter.svg'
import { ReactComponent as MessagesChatRegular } from '../icons/messages-chat-regular.svg'
import { ReactComponent as MessagesPickerApplied } from '../icons/messages-picker-applied.svg'
import { ReactComponent as MessagesPicker } from '../icons/messages-picker.svg'
import { ReactComponent as MessagesReply } from '../icons/messages-reply.svg'
import { ReactComponent as MissingFont } from '../icons/missing-font.svg'
import { ReactComponent as OpenLink } from '../icons/open-link.svg'
import { ReactComponent as OtherAdd } from '../icons/other-add.svg'
import { ReactComponent as OtherAlert } from '../icons/other-alert.svg'
import { ReactComponent as OtherArchive } from '../icons/other-archive.svg'
import { ReactComponent as OtherAsk } from '../icons/other-ask.svg'
import { ReactComponent as OtherBack } from '../icons/other-back.svg'
import { ReactComponent as OtherBg } from '../icons/other-bg.svg'
import { ReactComponent as OtherBlock } from '../icons/other-block.svg'
import { ReactComponent as OtherBrokenLink } from '../icons/other-broken-link.svg'
import { ReactComponent as OtherBurger } from '../icons/other-burger.svg'
import { ReactComponent as OtherCalendar } from '../icons/other-calendar.svg'
import { ReactComponent as OtherCards } from '../icons/other-cards.svg'
import { ReactComponent as OtherCircleCrop } from '../icons/other-circle-crop.svg'
import { ReactComponent as OtherClock } from '../icons/other-clock.svg'
import { ReactComponent as OtherClose } from '../icons/other-close.svg'
import { ReactComponent as OtherCopy } from '../icons/other-copy.svg'
import { ReactComponent as OtherCrop } from '../icons/other-crop.svg'
import { ReactComponent as OtherDown } from '../icons/other-down.svg'
import { ReactComponent as OtherDownload } from '../icons/other-download.svg'
import { ReactComponent as OtherEnter } from '../icons/other-enter.svg'
import { ReactComponent as OtherFile } from '../icons/other-file.svg'
import { ReactComponent as OtherFolder } from '../icons/other-folder.svg'
import { ReactComponent as OtherFont } from '../icons/other-font.svg'
import { ReactComponent as OtherFullScreen } from '../icons/other-full-screen.svg'
import { ReactComponent as OtherImage } from '../icons/other-image.svg'
import { ReactComponent as OtherLeft } from '../icons/other-left.svg'
import { ReactComponent as OtherLinkToResource } from '../icons/other-link-to-resource.svg'
import { ReactComponent as OtherList } from '../icons/other-list.svg'
import { ReactComponent as OtherLock } from '../icons/other-lock.svg'
import { ReactComponent as OtherMessages } from '../icons/other-messages.svg'
import { ReactComponent as OtherPause } from '../icons/other-pause.svg'
import { ReactComponent as OtherPlay } from '../icons/other-play.svg'
import { ReactComponent as OtherRefresh } from '../icons/other-refresh.svg'
import { ReactComponent as OtherReload } from '../icons/other-reload.svg'
import { ReactComponent as OtherReset } from '../icons/other-reset.svg'
import { ReactComponent as OtherRight } from '../icons/other-right.svg'
import { ReactComponent as OtherSmallScreen } from '../icons/other-small-screen.svg'
import { ReactComponent as OtherSort } from '../icons/other-sort.svg'
import { ReactComponent as OtherSquare } from '../icons/other-square.svg'
import { ReactComponent as OtherSwitch } from '../icons/other-switch.svg'
import { ReactComponent as OtherTask } from '../icons/other-task.svg'
import { ReactComponent as OtherText } from '../icons/other-text.svg'
import { ReactComponent as OtherTrash } from '../icons/other-trash.svg'
import { ReactComponent as OtherUndo } from '../icons/other-undo.svg'
import { ReactComponent as OtherUp } from '../icons/other-up.svg'
import { ReactComponent as OtherZoom } from '../icons/other-zoom.svg'
import { ReactComponent as PasswordFail } from '../icons/password-fail.svg'
import { ReactComponent as PasswordSuccess } from '../icons/password-success.svg'
import { ReactComponent as PenEdit } from '../icons/pen-edit.svg'
import { ReactComponent as PinFill } from '../icons/pin-fill.svg'
import { ReactComponent as Pin } from '../icons/pin.svg'
import { ReactComponent as Play2 } from '../icons/play-2.svg'
import { ReactComponent as PlayLoading } from '../icons/play-loading.svg'
import { ReactComponent as Play } from '../icons/play.svg'
import { ReactComponent as PortfolioOld } from '../icons/portfolioOld.svg'
import { ReactComponent as Prompt } from '../icons/prompt.svg'
import { ReactComponent as ProtectionSecurityKey } from '../icons/protection-security-key.svg'
import { ReactComponent as RightPanelCollapse } from '../icons/right panel collapse.svg'
import { ReactComponent as SearchField } from '../icons/search-field.svg'
import { ReactComponent as Send } from '../icons/send.svg'
import { ReactComponent as SimplifyLanguage } from '../icons/simplify-language.svg'
import { ReactComponent as SingleUserSales } from '../icons/single-user-sales.svg'
import { ReactComponent as Solid } from '../icons/solid.svg'
import { ReactComponent as Sort } from '../icons/sort.svg'
import { ReactComponent as Spinner } from '../icons/spinner.svg'
import { ReactComponent as Splitter } from '../icons/splitter.svg'
import { ReactComponent as StatusApply } from '../icons/status-apply.svg'
import { ReactComponent as StatusBug } from '../icons/status-bug.svg'
import { ReactComponent as StatusComments } from '../icons/status-comments.svg'
import { ReactComponent as StatusDanger20Px } from '../icons/status-danger-20-px.svg'
import { ReactComponent as StatusDeadline20Px } from '../icons/status-deadline-20-px.svg'
import { ReactComponent as StatusFail20Px } from '../icons/status-fail-20-px.svg'
import { ReactComponent as StatusFail } from '../icons/status-fail.svg'
import { ReactComponent as StatusGear24Px } from '../icons/status-gear-24-px.svg'
import { ReactComponent as StatusHide20Px } from '../icons/status-hide-20-px.svg'
import { ReactComponent as StatusLink20Px } from '../icons/status-link-20-px.svg'
import { ReactComponent as StatusLock20Px } from '../icons/status-lock-20-px.svg'
import { ReactComponent as StatusPending } from '../icons/status-pending.svg'
import { ReactComponent as StatusSuccess20Px } from '../icons/status-success-20-px.svg'
import { ReactComponent as StatusSumm20Px } from '../icons/status-summ-20-px.svg'
import { ReactComponent as StatusSumm24Px } from '../icons/status-summ-24-px.svg'
import { ReactComponent as StopPlay } from '../icons/stop-Play.svg'
import { ReactComponent as TaskBacklog } from '../icons/task-backlog.svg'
import { ReactComponent as TaskDone } from '../icons/task-done.svg'
import { ReactComponent as TaskInProgress } from '../icons/task-in-progress.svg'
import { ReactComponent as TaskNew } from '../icons/task-new.svg'
import { ReactComponent as TaskReview } from '../icons/task-review.svg'
import { ReactComponent as TestResultsNumberOfAttempts } from '../icons/test-results-number-of-attempts.svg'
import { ReactComponent as TestResultsPercent } from '../icons/test-results-percent.svg'
import { ReactComponent as TestResultsRightQuestions } from '../icons/test-results-right-questions.svg'
import { ReactComponent as TestResultsTestTime } from '../icons/test-results-test-time.svg'
import { ReactComponent as TextDown } from '../icons/text-down.svg'
import { ReactComponent as TwitterOld } from '../icons/twitterOld.svg'
import { ReactComponent as UserApply } from '../icons/user-apply.svg'
import { ReactComponent as UserBlock } from '../icons/user-block.svg'
import { ReactComponent as UserEmailSentOld } from '../icons/user-email-sentOld.svg'
import { ReactComponent as UserValidatedOld } from '../icons/user-validatedOld.svg'
import { ReactComponent as VolumeHalf } from '../icons/volume-half.svg'

export const ICON_MAP = {
  group: Group,
  add: Add,
  alertCheck: AlertCheck,
  alertDangerTemp: AlertDangerTemp,
  alertDanger: AlertDanger,
  alertFailRed: AlertFailRed,
  alertFailWhite: AlertFailWhite,
  alertInfo: AlertInfo,
  alignmentAlignBottom: AlignmentAlignBottom,
  alignmentAlignCenter: AlignmentAlignCenter,
  alignmentAlignFull: AlignmentAlignFull,
  alignmentAlignLeft: AlignmentAlignLeft,
  alignmentAlignMid: AlignmentAlignMid,
  alignmentAlignRight: AlignmentAlignRight,
  alignmentAlignTop: AlignmentAlignTop,
  annotation: Annotation,
  arrowTabs: ArrowTabs,
  blocksFill: BlocksFill,
  book: Book,
  bookmarkFill: BookmarkFill,
  bookmarkStroke: BookmarkStroke,
  bookmarksDoubleFill: BookmarksDoubleFill,
  boxCollectionsArchive: BoxCollectionsArchive,
  builderAbsolut: BuilderAbsolut,
  builderAddComponent: BuilderAddComponent,
  builderAdd: BuilderAdd,
  builderAi: BuilderAi,
  builderAlignCenter: BuilderAlignCenter,
  builderAlignFullWidth: BuilderAlignFullWidth,
  builderAlignLeft: BuilderAlignLeft,
  builderAlignRight: BuilderAlignRight,
  builderArrowDown: BuilderArrowDown,
  builderArrowLeft: BuilderArrowLeft,
  builderArrowRight: BuilderArrowRight,
  builderArrowScale: BuilderArrowScale,
  builderArrowUp: BuilderArrowUp,
  builderBlockquote: BuilderBlockquote,
  builderBold: BuilderBold,
  builderColumn: BuilderColumn,
  builderContentModeSettings: BuilderContentModeSettings,
  builderContent: BuilderContent,
  builderDesktop: BuilderDesktop,
  builderDrugAndDrop: BuilderDrugAndDrop,
  builderDrug: BuilderDrug,
  builderElement: BuilderElement,
  builderH1: BuilderH1,
  builderH2: BuilderH2,
  builderH3: BuilderH3,
  builderH4: BuilderH4,
  builderInputBack: BuilderInputBack,
  builderInputDown: BuilderInputDown,
  builderInputForward: BuilderInputForward,
  builderInputUp: BuilderInputUp,
  builderItalic: BuilderItalic,
  builderLeftDown: BuilderLeftDown,
  builderLeftUp: BuilderLeftUp,
  builderListNumbers: BuilderListNumbers,
  builderList: BuilderList,
  builderLock: BuilderLock,
  builderLower: BuilderLower,
  builderMobile: BuilderMobile,
  builderProMode: BuilderProMode,
  builderPromode2: BuilderPromode2,
  builderRightDown: BuilderRightDown,
  builderRightUp: BuilderRightUp,
  builderRow: BuilderRow,
  builderSpace: BuilderSpace,
  builderSwitchLeft: BuilderSwitchLeft,
  builderSwitchRight: BuilderSwitchRight,
  builderSymbol: BuilderSymbol,
  builderTabBlocks: BuilderTabBlocks,
  builderTabLayers: BuilderTabLayers,
  builderTablet: BuilderTablet,
  builderText: BuilderText,
  builderTimeline: BuilderTimeline,
  builderTooltip: BuilderTooltip,
  builderTypeParagraphCharacterAllCaps: BuilderTypeParagraphCharacterAllCaps,
  builderTypeParagraphCharacterTextSizeSmall: BuilderTypeParagraphCharacterTextSizeSmall,
  builderTypeParagraphCharacterTextSize: BuilderTypeParagraphCharacterTextSize,
  builderUnderline: BuilderUnderline,
  builderUnderlinetextCross: BuilderUnderlinetextCross,
  builderUpper: BuilderUpper,
  burgerMenu: BurgerMenu,
  cc: Cc,
  changeCompany: ChangeCompany,
  checkbox: Checkbox,
  checkmark1: Checkmark1,
  checkmarkCircle1: CheckmarkCircle1,
  collapse2Submenu2Reverse: Collapse2Submenu2Reverse,
  collapse2Submenu2: Collapse2Submenu2,
  collapseOpen: CollapseOpen,
  contentsChapter: ContentsChapter,
  contentsLanding: ContentsLanding,
  contentsSlides: ContentsSlides,
  contentsTest: ContentsTest,
  courseAnswerCorrect: CourseAnswerCorrect,
  courseAnswerIncorrect: CourseAnswerIncorrect,
  courseArrowBack: CourseArrowBack,
  courseArrowForward: CourseArrowForward,
  dash: Dash,
  dots: Dots,
  emptyColor: EmptyColor,
  eyeCloseEye: EyeCloseEye,
  eyeOpenEye: EyeOpenEye,
  facebookOld: FacebookOld,
  fixSpelling: FixSpelling,
  folder: Folder,
  gear2: Gear2,
  headphonesSupport: HeadphonesSupport,
  iconFillBell: IconFillBell,
  iconFillFolder: IconFillFolder,
  iconFillOwner: IconFillOwner,
  iconFillStatusMarked: IconFillStatusMarked,
  iconSendDisabled: IconSendDisabled,
  iconSend: IconSend,
  iconsBuilderLock: IconsBuilderLock,
  iconsExpandLeft: IconsExpandLeft,
  iconsExpandRight: IconsExpandRight,
  iconsForElementsDown: IconsForElementsDown,
  iconsForElementsLeftSlider: IconsForElementsLeftSlider,
  iconsForElementsLeft: IconsForElementsLeft,
  iconsForElementsRightSlider: IconsForElementsRightSlider,
  iconsForElementsRight: IconsForElementsRight,
  iconsForElementsRotateRight: IconsForElementsRotateRight,
  iconsOtherAddColumn: IconsOtherAddColumn,
  iconsOtherAddRow: IconsOtherAddRow,
  iconsOtherAudio: IconsOtherAudio,
  iconsOtherBoxHome: IconsOtherBoxHome,
  iconsOtherButton: IconsOtherButton,
  iconsOtherCode: IconsOtherCode,
  iconsOtherDistribution: IconsOtherDistribution,
  iconsOtherElementCards: IconsOtherElementCards,
  iconsOtherEmbedCode: IconsOtherEmbedCode,
  iconsOtherFigure: IconsOtherFigure,
  iconsOtherFolderCheckmark: IconsOtherFolderCheckmark,
  iconsOtherGraphic: IconsOtherGraphic,
  iconsOtherHeader: IconsOtherHeader,
  iconsOtherLanguageTranslateCircle: IconsOtherLanguageTranslateCircle,
  iconsOtherLine: IconsOtherLine,
  iconsOtherMarginsLandingPages: IconsOtherMarginsLandingPages,
  iconsOtherMarginsTestPages: IconsOtherMarginsTestPages,
  iconsOtherMore: IconsOtherMore,
  iconsOtherMoveTo: IconsOtherMoveTo,
  iconsOtherNavigationButtons: IconsOtherNavigationButtons,
  iconsOtherNullBg: IconsOtherNullBg,
  iconsOtherProgress: IconsOtherProgress,
  iconsOtherResultDescription: IconsOtherResultDescription,
  iconsOtherResultMarkers: IconsOtherResultMarkers,
  iconsOtherRow: IconsOtherRow,
  iconsOtherSidebar: IconsOtherSidebar,
  iconsOtherSingleChoose: IconsOtherSingleChoose,
  iconsOtherSlider: IconsOtherSlider,
  iconsOtherSwitch: IconsOtherSwitch,
  iconsOtherTableEditTableEraser: IconsOtherTableEditTableEraser,
  iconsOtherTable: IconsOtherTable,
  iconsOtherTabs: IconsOtherTabs,
  iconsOtherVideo: IconsOtherVideo,
  illustrationPageLock: IllustrationPageLock,
  imagePicture: ImagePicture,
  improveWriting: ImproveWriting,
  interfaceEssentialAnchor: InterfaceEssentialAnchor,
  layersFill: LayersFill,
  lifeRingHelpSupportBug: LifeRingHelpSupportBug,
  link2: Link2,
  linkedinOld: LinkedinOld,
  mainMenuBackButton: MainMenuBackButton,
  mainMenuBell: MainMenuBell,
  mainMenuBranding: MainMenuBranding,
  mainMenuDashboard: MainMenuDashboard,
  mainMenuFiles: MainMenuFiles,
  mainMenuFont: MainMenuFont,
  mainMenuProjects: MainMenuProjects,
  mainMenuSettings: MainMenuSettings,
  mainMenuTags: MainMenuTags,
  mainMenuTasks: MainMenuTasks,
  mainMenuUsers: MainMenuUsers,
  makeLonger: MakeLonger,
  makeShorter: MakeShorter,
  menuBurgerCircle2: MenuBurgerCircle2,
  menuBurgerSquare: MenuBurgerSquare,
  menu: Menu,
  messageFilterApplied: MessageFilterApplied,
  messageFilter: MessageFilter,
  messagesChatRegular: MessagesChatRegular,
  messagesPickerApplied: MessagesPickerApplied,
  messagesPicker: MessagesPicker,
  messagesReply: MessagesReply,
  missingFont: MissingFont,
  openLink: OpenLink,
  otherAdd: OtherAdd,
  otherAlert: OtherAlert,
  otherArchive: OtherArchive,
  otherAsk: OtherAsk,
  otherBack: OtherBack,
  otherBg: OtherBg,
  otherBlock: OtherBlock,
  otherBrokenLink: OtherBrokenLink,
  otherBurger: OtherBurger,
  otherCalendar: OtherCalendar,
  otherCards: OtherCards,
  otherCircleCrop: OtherCircleCrop,
  otherClock: OtherClock,
  otherClose: OtherClose,
  otherCopy: OtherCopy,
  otherCrop: OtherCrop,
  otherDown: OtherDown,
  otherDownload: OtherDownload,
  otherEnter: OtherEnter,
  otherFile: OtherFile,
  otherFolder: OtherFolder,
  otherFont: OtherFont,
  otherFullScreen: OtherFullScreen,
  otherImage: OtherImage,
  otherLeft: OtherLeft,
  otherLinkToResource: OtherLinkToResource,
  otherList: OtherList,
  otherLock: OtherLock,
  otherMessages: OtherMessages,
  otherPause: OtherPause,
  otherPlay: OtherPlay,
  otherRefresh: OtherRefresh,
  otherReload: OtherReload,
  otherReset: OtherReset,
  otherRight: OtherRight,
  otherSmallScreen: OtherSmallScreen,
  otherSort: OtherSort,
  otherSquare: OtherSquare,
  otherSwitch: OtherSwitch,
  otherTask: OtherTask,
  otherText: OtherText,
  otherTrash: OtherTrash,
  otherUndo: OtherUndo,
  otherUp: OtherUp,
  otherZoom: OtherZoom,
  passwordFail: PasswordFail,
  passwordSuccess: PasswordSuccess,
  penEdit: PenEdit,
  pinFill: PinFill,
  pin: Pin,
  play2: Play2,
  playLoading: PlayLoading,
  play: Play,
  portfolioOld: PortfolioOld,
  prompt: Prompt,
  protectionSecurityKey: ProtectionSecurityKey,
  rightPanelCollapse: RightPanelCollapse,
  searchField: SearchField,
  send: Send,
  simplifyLanguage: SimplifyLanguage,
  singleUserSales: SingleUserSales,
  solid: Solid,
  sort: Sort,
  spinner: Spinner,
  splitter: Splitter,
  statusApply: StatusApply,
  statusBug: StatusBug,
  statusComments: StatusComments,
  statusDanger20Px: StatusDanger20Px,
  statusDeadline20Px: StatusDeadline20Px,
  statusFail20Px: StatusFail20Px,
  statusFail: StatusFail,
  statusGear24Px: StatusGear24Px,
  statusHide20Px: StatusHide20Px,
  statusLink20Px: StatusLink20Px,
  statusLock20Px: StatusLock20Px,
  statusPending: StatusPending,
  statusSuccess20Px: StatusSuccess20Px,
  statusSumm20Px: StatusSumm20Px,
  statusSumm24Px: StatusSumm24Px,
  stopPlay: StopPlay,
  taskBacklog: TaskBacklog,
  taskDone: TaskDone,
  taskInProgress: TaskInProgress,
  taskNew: TaskNew,
  taskReview: TaskReview,
  testResultsNumberOfAttempts: TestResultsNumberOfAttempts,
  testResultsPercent: TestResultsPercent,
  testResultsRightQuestions: TestResultsRightQuestions,
  testResultsTestTime: TestResultsTestTime,
  textDown: TextDown,
  twitterOld: TwitterOld,
  userApply: UserApply,
  userBlock: UserBlock,
  userEmailSentOld: UserEmailSentOld,
  userValidatedOld: UserValidatedOld,
  volumeHalf: VolumeHalf,
}
