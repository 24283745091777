import { CardFontSchemaType, CardSchemaType, IconsAlignEnum } from '@leenda/editor/lib/brand'
import { CardElementValue } from '@leenda/editor/lib/elements'
import { RichTextValue } from '@leenda/rich-text'
import cn from 'classnames'
import lodash from 'lodash'
import * as R from 'ramda'
import { useCallback } from 'react'

import { parseStyleValue } from 'components/editor-v3/cource/controls/CourseSidebar/utils'
import RichText from 'components/form/RichText/RichText'
import { useGetRichTextProps } from 'components/form/RichText/useGetRichTextProps'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { IBlockMode } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { useImageWithCrop } from 'utils/files'
import { testProps } from 'utils/test/qaData'

import s from './CardSide.module.scss'
import { ReactComponent as Icon } from './assets/cardIcon.svg'
import { CardSidesEnum } from './enum'
import IconStyled from './styled/IconStyled'
import {
  getObjectFitDivMap,
  getPositionIcon,
  STYLES_ICON_SIDE_KEYS,
  STYLES_SIDE_KEYS,
} from './utils'

export const DEFAULT_TEXT = t('input.placeholder.addText')
const DEF_INDENT_FROM_TEXT = 16

interface ICardSideProps {
  side: CardSidesEnum
  styles: ElementStyleCss<CardSchemaType>
  value: CardElementValue
  active: boolean
  id: string
  mode: IBlockMode
  onChange?: (value: CardElementValue) => void
  onClick?: () => void
  onMouseEnter?: () => void
  waiting?: boolean
  font: ElementFontCss<CardFontSchemaType>
}

const CardSide = ({
  side,
  styles,
  value,
  active,
  id,
  mode,
  onChange,
  onClick,
  waiting,
  font,
  onMouseEnter,
}: ICardSideProps) => {
  const sideValues = value[side]
  const hasImg = Boolean(sideValues.image?.id)
  const stylesKey = STYLES_SIDE_KEYS[side]
  const stylesIconKey = STYLES_ICON_SIDE_KEYS[side]
  const iconAlign = styles[stylesIconKey].iconsAlign as IconsAlignEnum
  const iconBg = styles[stylesIconKey].backgroundImage
  const borderRadius = parseInt(String(styles.card.borderRadius))
  const borderWidth = parseInt(String(styles.border.borderWidth))
  const borderRadiusContent = borderRadius > borderWidth ? borderRadius - borderWidth : 0
  const maskStyles = { backgroundColor: hasImg ? sideValues.fillColor : undefined }
  const isTopIconPosition =
    iconAlign === IconsAlignEnum.leftUp || iconAlign === IconsAlignEnum.rightUp
  const isBottomIconPosition =
    iconAlign === IconsAlignEnum.leftDown || iconAlign === IconsAlignEnum.rightDown
  const iconPositionStyle = getPositionIcon(iconAlign)
  const iconStyle = {
    ...iconPositionStyle,
    backgroundImage: iconBg,
  }
  const cardName = `card.${side}`

  const { isActiveElement, isFill, onLabelSelect, activeIndex } = useGetRichTextProps({
    elementId: id,
    mode,
  })

  const img = useImageWithCrop(sideValues.image)
  const text = sideValues.label || (img?.path ? '' : DEFAULT_TEXT)
  const rootStyles = {
    ...styles.border,
    backgroundImage: img?.path && hasImg ? `url(${img?.path})` : undefined,
    ...lodash.omit(styles[stylesKey], ['alignItems', 'justifyContent', 'backgroundImage']),
    borderRadius: isNaN(borderRadius) ? styles.card.borderRadius : borderRadiusContent,
    backgroundSize: getObjectFitDivMap(sideValues.scaling),
    paddingTop:
      (isTopIconPosition ? parseStyleValue(styles.icon.height) : 0) + DEF_INDENT_FROM_TEXT,
    paddingBottom:
      (isBottomIconPosition ? parseStyleValue(styles.icon.height) : 0) + DEF_INDENT_FROM_TEXT,
  }

  const onChangeLabel = useCallback(
    (labelValue: RichTextValue) => onChange?.(R.assocPath([side, 'label'], labelValue, value)),
    [onChange, side, value],
  )

  return (
    <div
      aria-label={sideValues.image?.accessibility}
      className={cn(s.root, s[side], [s[styles.animation.cardFlip]], {
        [s.active]: active,
      })}
      style={rootStyles}
    >
      <div className={s.mask} style={maskStyles} />
      <div className={s.textContainer} style={{ justifyContent: styles[stylesKey].alignItems }}>
        <div className={s.textInner} onMouseDown={() => onLabelSelect(cardName)}>
          <RichText
            active={isActiveElement && activeIndex === cardName && active}
            disabled={!isFill}
            key={cardName}
            name={cardName}
            onChange={onChangeLabel}
            styles={font}
            value={text}
            waiting={waiting}
          />
        </div>
      </div>
      <IconStyled
        $hover={isFill}
        $isBack={side === CardSidesEnum.BACK}
        $styles={styles}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        style={iconStyle}
        {...testProps({ el: 'cardIcon', side })}
      >
        {!iconBg && <Icon />}
      </IconStyled>
    </div>
  )
}

export default CardSide
