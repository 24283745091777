import { LabeledGraphicSchemaType } from '@leenda/editor/lib/brand'
import styled, { css } from 'styled-components'

import { hover } from 'services/Branding/common.styled'
import { ElementStyleCss } from 'services/Branding/types'

const PointStyled = styled.div<{
  $styles: ElementStyleCss<LabeledGraphicSchemaType>
  $isImage: boolean
  $path?: string
  $editor: boolean
  $active: boolean
}>`
  transition: 0.3s;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
  z-index: 10;
  color: #fff;

  width: ${({ $styles }) => $styles.point.width};
  height: ${({ $styles }) => $styles.point.height};
  background-color: ${({ $styles }) => $styles.point.backgroundColor};
  background-image: ${({ $styles, $isImage, $path }) =>
    $isImage && ($path ? `url(${$path})` : $styles.point.backgroundImage)};

  ${hover}

  ${({ $active, $styles }) =>
    $active &&
    css`
      box-shadow: 0 0 0 4px ${$styles.point.color};
    `}

  ${({ $editor }) =>
    $editor &&
    css`
      &:hover:after {
        content: '';
        position: absolute;
        border-radius: 50%;
        outline: 1px dashed var(--color-secondary-default);
        pointer-events: none;
        inset: -4px;
      }
    `}
`

export default PointStyled
