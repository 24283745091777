import { FileUsageTypeEnum } from '@leenda/editor/lib/files'
import { FileTypeEnum } from 'utils'

import MediaPlayer from 'components/MediaPlayer'
import { getFileByUsage } from 'utils/files'

import s from './AudioPreview.module.scss'

const AudioPreview = ({ value }: any) => {
  const mediaFile = getFileByUsage(value, FileUsageTypeEnum.audio)

  return (
    <div className={s.root}>
      <MediaPlayer type={FileTypeEnum.AUDIO} url={mediaFile?.path || value || null} />
    </div>
  )
}

export default AudioPreview
