import { SidebarFontSchemaType, SidebarSchemaType } from '@leenda/editor/lib/brand'
import { useDebounceFn } from 'ahooks'
import cn from 'classnames'
import * as R from 'ramda'
import React from 'react'

import Icon from 'components/uiKit/Icon'
import Input, { AdditionWrapper } from 'components/uiKit/Input'
import { InputDefaultType } from 'components/uiKit/Input/Input'
import { KitSize } from 'components/uiKit/KitTypes'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { setSidebarSearch } from 'services/Store/Project/actions'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getSidebar } from 'services/Store/Project/selectors'
import { t } from 'services/Translation'

import s from './CourseSearch.module.scss'
import { searchTrim } from './searchTrim'

const isValuable = (value: unknown) => !R.isNil(value) && !R.isEmpty(value)
export const isEmptyKeys = (values: unknown) =>
  R.isEmpty(R.filter(isValuable, values as Record<string, unknown>))

interface ICourseSearchProps extends InputDefaultType {
  name: string
  fluid?: boolean
  autoFocus?: boolean
  size?: KitSize.S | KitSize.M
  wait?: number
  width?: number
  style?: ElementStyleCss<SidebarSchemaType>['search']
  font: ElementFontCss<SidebarFontSchemaType>['search']
}

const CourseSearch: React.FC<ICourseSearchProps> = ({
  name,
  fluid,
  autoFocus,
  size = KitSize.L,
  wait = 0,
  width,
  style,
  font,
  ...rest
}) => {
  const { search: value } = useProjectContext(getSidebar)
  const dispatch = useProjectDispatch()

  const { run } = useDebounceFn((value?: string) => dispatch(setSidebarSearch({ search: value })), {
    wait,
  })

  const searchStyles = {
    input: {
      ...font,
      backgroundColor: style?.backgroundColor,
      marginRight: 3,
    },
    placeholder: {
      ...style,
      ['--color-input-placeholder']: font.color,
      width,
    },
  }

  return (
    <div className={cn(s.root, { [s.fluid]: fluid })} style={searchStyles.placeholder}>
      <Input
        {...rest}
        autoFocus={autoFocus}
        name={`${name}.search`}
        onChange={run}
        parser={searchTrim}
        placeholder={t('input.placeholder.search')}
        prefix={
          <AdditionWrapper>
            <div className={s.prefixWrapper}>
              <Icon
                color={style?.color}
                name='searchField'
                size={KitSize.S}
                style={{ fontSize: font.fontSize }}
              />
            </div>
          </AdditionWrapper>
        }
        size={size}
        // eslint-disable-next-line react/forbid-component-props
        style={searchStyles.input}
        styleType='ghost'
        value={value}
      />
    </div>
  )
}

export default CourseSearch
