import { TabsSchemaType } from '@leenda/editor/lib/brand'
import styled from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

const TabListWrapStyled = styled.div<{ $styles: ElementStyleCss<TabsSchemaType> }>`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background: ${({ $styles }) => $styles.tabs.backgroundColor};
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  border-top-left-radius: ${({ $styles }) => $styles.border.borderRadius};
  border-top-right-radius: ${({ $styles }) => $styles.border.borderRadius};
`

export default TabListWrapStyled
