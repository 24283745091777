import lodash from 'lodash'
import * as R from 'ramda'
import { useMemo } from 'react'
import { useContextSelector } from 'use-context-selector'

import { getParams } from '../Field'
import { IFieldConfig, IFieldMeta } from '../Field/Field.types'
import { mergeParams } from '../Field/genField'
import { FormContext } from '../Form'

export const useFieldData = <Value>(name = '') => {
  const value: Value = useContextSelector(FormContext, (context) =>
    lodash.get(context.values, name),
  )
  const initialValue: Value = useContextSelector(FormContext, (context) =>
    lodash.get(context.initialValues, name),
  )
  const defaultValue: Value = useContextSelector(FormContext, (context) =>
    lodash.get(context.defaultValues, name),
  )
  const inheritValue: Value = useContextSelector(FormContext, (context) =>
    lodash.get(context.inheritValues, name),
  )
  const fullValues: Value = useContextSelector(FormContext, (context) =>
    lodash.get(context.fullValues, name),
  )
  const meta: IFieldMeta = useContextSelector(FormContext, (context) => context.metas[name]) || {}

  return { value, initialValue, meta, defaultValue, inheritValue, fullValues }
}

export const useFormData = () => useContextSelector(FormContext, (context) => context.values)

export const useFieldParams = <Data>(
  config: IFieldConfig<Data>,
  data: Data,
  defaultData?: Data,
) => {
  const hookParams = config.useParams?.(
    R.mergeDeepRight(defaultData || {}, data || {}) as Data,
    config,
  )

  return useMemo(
    () =>
      mergeParams(
        getParams(config, R.mergeDeepRight(defaultData || {}, data || {}) as Data),
        hookParams,
      )(R.mergeDeepRight(defaultData || {}, data || {}) as Data, config as IFieldConfig<unknown>),
    [data, defaultData, config, hookParams],
  )
}
