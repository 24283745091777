import { PermissionsKey } from '@leenda/crud'
import { t } from 'i18next'
import lodash from 'lodash'
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useHistory } from 'react-router'

import { IAsyncOnChange } from 'components/controls/Field/Field.types'
import { useRealtimeState } from 'components/controls/RealtimeForm/hooks'
import { ConfirmModal } from 'components/modals/ConfirmModal/ConfirmModal'
import { useOpenModal } from 'components/uiKit/Modal'
import { notify } from 'components/uiKit/Notification'
import { NotificationType } from 'components/uiKit/Notification/types'
import { NOOP } from 'constants/commonConstans'
import { PROJECT_HOME_PATHS } from 'constants/paths'
import { ProjectSchemaFragment, SectionEditorSchemaFragment } from 'gql/__generated__/graphql'
import {
  useSectionsDeleteById,
  useSectionsDuplicate,
  useSectionsUpdateById,
  useSectionUpdateHide,
} from 'gql/sections.apollo'
import generateLink from 'routes/generateLink'
import { usePathParams } from 'routes/hooks'
import { useGetSortedSections } from 'routes/routes/App/routes/Company/routes/Project/routes/hooks'
import { usePermissions } from 'services/Permission/PermissionContext'
import { SectionTypeEnum } from 'services/Store/Project/enums'
import { getMultipleValue } from 'utils/form'

import { SectionRealtimeDataType } from '../SectionToolbar/component/SingleSectionForm/SingleSectionForm'
import { useSetActiveSection } from '../hooks'
import {
  getDeepNode,
  getDeepNodesWithoutChaptersByTree,
  getNodesFromTreeByIds,
  getSectionsBetweenNodes,
  ISectionTreeNode,
  listToTree,
} from '../utils'

interface IDragInfo {
  id: string
  showDragLine: boolean
  showOverLine?: boolean | null
}

interface IProjectContentContextValue {
  sections: SectionEditorSchemaFragment[]
  nodes: ISectionTreeNode<SectionEditorSchemaFragment>[]
  project?: ProjectSchemaFragment | null
  loading: boolean

  selectedNodeIds: string[]
  query?: string
  setNewSelectedNodes: (newNodes: string[]) => void
  setQuery: Dispatch<SetStateAction<string | undefined>>

  multipleSectionsValues: { isDone: boolean | 'different'; isHide: boolean | 'different' }
  disabledActionsSection: boolean
  onShiftClick: (node: ISectionTreeNode, toggle?: (active?: boolean | undefined) => void) => void
  onCtrlClick: (node: ISectionTreeNode, toggle?: (active?: boolean | undefined) => void) => void
  onDelete: () => Promise<void>
  onDuplicate: () => Promise<void>

  sectionRealtimeData?: SectionRealtimeDataType | null
  handleSectionRealtimeChange: IAsyncOnChange<SectionRealtimeDataType | null | undefined>
  setSectionRealtimeData: Dispatch<SetStateAction<SectionRealtimeDataType | null | undefined>>
  setOpenSectionToolbar: Dispatch<SetStateAction<boolean>>
  openSectionToolbar: boolean
  onChange: IAsyncOnChange<SectionRealtimeDataType | undefined>
  dragInfo: IDragInfo | null
  setDragInfo: Dispatch<SetStateAction<IDragInfo | null>>
}

const defaultContextValue = {
  sections: [],
  nodes: [],
  loading: false,
  project: null,
  query: '',
  setQuery: NOOP,
  onShiftClick: NOOP,
  onCtrlClick: NOOP,
  multipleSectionsValues: { isDone: false, isHide: false },
  onDelete: () => new Promise(NOOP),
  onDuplicate: () => new Promise(NOOP),
  disabledActionsSection: false,
  setNewSelectedNodes: NOOP,
  handleSectionRealtimeChange: NOOP as unknown as IAsyncOnChange<
    SectionRealtimeDataType | null | undefined
  >,
  setSectionRealtimeData: NOOP,
  selectedNodeIds: [],
  setOpenSectionToolbar: NOOP,
  openSectionToolbar: false,
  onChange: NOOP as unknown as IAsyncOnChange<unknown | undefined | null>,
  dragInfo: null,
  setDragInfo: NOOP,
} as IProjectContentContextValue

const ProjectContentContext = createContext(defaultContextValue)

const isObjectValue = (value: unknown): value is Record<string, unknown> =>
  (value as Record<string, unknown>)?.isHide !== undefined

export const ProviderProjectContentContext: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const history = useHistory()
  const { companyId, projectId, sectionId = '' } = usePathParams(PROJECT_HOME_PATHS.sections)
  const { canDeleteSection, canUpdateSection, canDuplicateSection } = usePermissions({
    canDeleteSection: PermissionsKey.project_sections_d,
    canUpdateSection: PermissionsKey.project_sections_u,
    canDuplicateSection: PermissionsKey.project_sections_c,
  })

  const [query, setQuery] = useState<string | undefined>()
  const [openSectionToolbar, setOpenSectionToolbar] = useState(false)
  const [selectedNodeIds, setSelectedNodeIds] = useState<string[]>([])
  const [dragInfo, setDragInfo] = useState<IDragInfo | null>(null)

  const [sectionsDelete] = useSectionsDeleteById(projectId)
  const [sectionDuplicate] = useSectionsDuplicate(projectId)
  const [updateSectionHide] = useSectionUpdateHide()
  const [sectionUpdate] = useSectionsUpdateById()
  const linkToAll = generateLink(PROJECT_HOME_PATHS.sections, { companyId, projectId })
  const confirmModal = useOpenModal(ConfirmModal, {
    title: t('modal.delete.title'),
    info: t('modal.delete.description'),
    okButtonTitle: t('uiKit.button.delete'),
    cancelButtonTitle: t('uiKit.button.cancel'),
  })

  const { sections, project, loading } = useGetSortedSections()
  const section = sections.find((sec) => sec.id === sectionId)

  const setActiveSection = useSetActiveSection()
  const nodes = useMemo(() => listToTree(sections), [sections])
  const selectedNodes = getNodesFromTreeByIds(nodes, selectedNodeIds)
  const hasCoverSelected = selectedNodes.some((node) => node.item.type === SectionTypeEnum.cover)

  const setNewSelectedNodes = useCallback(
    (newNodeId: string[]) => {
      setSelectedNodeIds(() => newNodeId)
      const firstNodeId = newNodeId[0]
      setActiveSection(firstNodeId)
    },
    [setActiveSection],
  )

  const canMultipleSelection = useCallback(
    (node: ISectionTreeNode) =>
      node.item.type === SectionTypeEnum.cover || hasCoverSelected || !canUpdateSection,
    [canUpdateSection, hasCoverSelected],
  )

  const onShiftClick = useCallback(
    (node: ISectionTreeNode, _toggle?: (active?: boolean | undefined) => void) => {
      const nodeId = node.item.id
      if (canMultipleSelection(node)) {
        return
      }
      if (!selectedNodeIds.length) {
        setNewSelectedNodes([node.item.id])
        return
      }
      const sectionsBetween = getSectionsBetweenNodes(nodes, selectedNodeIds[0], nodeId).map(
        ({ item }) => item.id,
      )
      if (sectionsBetween?.length) {
        setNewSelectedNodes(sectionsBetween)
      }
    },
    [selectedNodeIds, canMultipleSelection],
  )

  const onCtrlClick = useCallback(
    (node: ISectionTreeNode, toggle?: (active?: boolean | undefined) => void) => {
      const nodeId = node.item.id
      if (canMultipleSelection(node)) {
        return
      }

      const excludeNodes: ISectionTreeNode[] = []
      if (node.item.isChapter) {
        toggle?.(false)
        excludeNodes.push(...getDeepNode(node.children))
      }

      let newNodes = selectedNodeIds.filter(
        (prevNodeId) => !excludeNodes.some(({ item }) => item.id === prevNodeId),
      )
      const isAlreadySelected = newNodes.includes(nodeId)
      if (isAlreadySelected) {
        newNodes = newNodes.filter((newId) => nodeId !== newId)
      } else {
        newNodes = [...newNodes, nodeId]
      }
      if (!newNodes.length) {
        setActiveSection()
      }

      setNewSelectedNodes(newNodes)
    },
    [selectedNodeIds, canMultipleSelection],
  )

  const selectedNodesWithChildren = useMemo(() => {
    const selectedNodes = getNodesFromTreeByIds(nodes, selectedNodeIds)
    return getDeepNodesWithoutChaptersByTree(selectedNodes)
  }, [nodes, selectedNodeIds])

  const multipleSectionsValues = useMemo(
    () => ({
      isDone: getMultipleValue(
        selectedNodesWithChildren.map((node) => node.item),
        'isDone',
        false,
      ),
      isHide: getMultipleValue(
        selectedNodesWithChildren.map((node) => node.item),
        'isHide',
        false,
      ),
    }),
    [selectedNodesWithChildren],
  )
  const disabledActionsSection = multipleSectionsValues.isDone !== false

  const onDelete = useCallback(async () => {
    if (!canDeleteSection) {
      notify({ type: NotificationType.error, message: t('common.error') })
      return
    }
    if (disabledActionsSection) {
      notify({ message: t('notify.sectionErrorDelete'), type: NotificationType.error })
      return
    }
    const confirm = await confirmModal.open()
    if (confirm && section) {
      const response = await sectionsDelete({ variables: { ids: selectedNodeIds, projectId } })
      if (response.errors?.length) {
        notify({ message: t('common.error'), type: NotificationType.error })
        return
      }
      if (response.data?.data.sectionIdsDeleted.length) {
        const countSections = sections.filter((sec) =>
          response.data?.data.sectionIdsDeleted.find((id) => id === sec.id && !sec.isChapter),
        ).length
        setNewSelectedNodes([])
        notify({
          type: NotificationType.success,
          message: t('notify.sectionSuccessDelete', { count: countSections }),
        })
      }
      history.push(linkToAll)
    }
  }, [
    canDeleteSection,
    disabledActionsSection,
    confirmModal,
    section,
    sectionsDelete,
    selectedNodeIds,
    projectId,
    history,
    linkToAll,
    sections,
    setNewSelectedNodes,
  ])

  const onDuplicate = useCallback(async () => {
    if (!selectedNodeIds.length) {
      return
    }
    const selectedNodes = getNodesFromTreeByIds(nodes, selectedNodeIds)
    const hasCoverSelected = selectedNodes.some((node) => node.item.type === SectionTypeEnum.cover)
    const sortedSelectedSectionIds = selectedNodes
      .sort((a, b) => a.serialNumber - b.serialNumber)
      .map(({ item }) => item.id)
    const disabledDuplicate = hasCoverSelected || !canDuplicateSection
    if (disabledDuplicate) {
      notify({ type: NotificationType.error, message: t('notify.sectionErrorDuplicate') })
      return
    }
    try {
      const res = await sectionDuplicate({
        variables: { companyId, ids: sortedSelectedSectionIds },
      })
      if (res?.data?.data?.data) {
        notify({
          type: NotificationType.success,
          message: t('notify.sectionDuplicate', { count: res.data.data.data.length }),
        })
        const newSections = res.data.data.data.map((item) => item.section)
        const minLvl = Math.min(...newSections.map((item) => item.lvl))
        const itemIds = newSections.filter((item) => item.lvl === minLvl).map((item) => item.id)
        setNewSelectedNodes(itemIds)
      } else {
        notify({ type: NotificationType.error, message: t('notify.sectionErrorDuplicate') })
      }
    } catch (err) {
      notify({ type: NotificationType.error, message: t('notify.sectionErrorDuplicate') })
    }
  }, [selectedNodeIds, sectionDuplicate, nodes, disabledActionsSection])

  const onChange: IAsyncOnChange<SectionRealtimeDataType | undefined> = useCallback(
    async ({ name, value, meta }) => {
      const id = (meta?.item as SectionRealtimeDataType | undefined)?.id || section?.id
      if (!id) {
        return
      }
      if (name === 'isHide') {
        await updateSectionHide({ variables: { id, isHide: value as boolean } })
        return
      }

      if (isObjectValue(value)) {
        await updateSectionHide({
          variables: { id, isHide: value.isHide as boolean },
        })
        return
      }

      let data = lodash.set({}, name, value)
      if (name === '') {
        data = lodash.omit(value as Record<string, unknown>, 'isHide')
      }

      const result = await sectionUpdate({ variables: { id, data, projectId } })
      return result.data?.data.section as SectionRealtimeDataType
    },
    [projectId, section?.id, sectionUpdate, updateSectionHide],
  )

  const [sectionRealtimeData, handleSectionRealtimeChange, setSectionRealtimeData] =
    useRealtimeState({
      defaultValue: section as SectionRealtimeDataType | undefined,
      onChange,
      wait: 500,
      leading: true,
    })

  useEffect(() => {
    const alreadySelectedSection = selectedNodeIds.some((id) => sectionId === id)
    if (!alreadySelectedSection && sectionId) {
      setNewSelectedNodes([sectionId])
    }
    setSectionRealtimeData(section)
  }, [sectionId, section?.publicLink])

  return (
    <ProjectContentContext.Provider
      value={{
        sections,
        nodes,
        selectedNodeIds,
        project,
        query,
        setQuery,
        loading,
        onShiftClick,
        onCtrlClick,
        onDelete,
        onDuplicate,
        disabledActionsSection,
        setNewSelectedNodes,
        multipleSectionsValues,
        sectionRealtimeData,
        handleSectionRealtimeChange,
        setSectionRealtimeData,
        openSectionToolbar,
        setOpenSectionToolbar,
        onChange,
        dragInfo,
        setDragInfo,
      }}
    >
      {children}
    </ProjectContentContext.Provider>
  )
}

export const useGetProjectContentContext = () => useContext(ProjectContentContext)
