"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFontGroupUsages = exports.getFontUsage = exports.getEmbeddedArchiveUsage = exports.getCaptionsUsage = exports.getVideoUsage = exports.getAudioUsage = exports.getImageUsage = exports.getCommonUsage = exports.getFileUsage = exports.imageUsageToKey = void 0;
const utils_1 = require("../brand/utils");
const types_1 = require("./types");
const stringFilter = (value) => typeof value === 'string';
const imageUsageToKey = (base) => {
    var _a;
    if (base && ((_a = base.params) === null || _a === void 0 ? void 0 : _a.crop)) {
        return `crop-${base.params.crop.x}-${base.params.crop.y}-${base.params.crop.width}-${base.params.crop.height}`;
    }
    return 'original';
};
exports.imageUsageToKey = imageUsageToKey;
const getKeyByUsage = (usage) => {
    switch (usage.type) {
        case types_1.FileUsageTypeEnum.common:
            return 'original';
        case types_1.FileUsageTypeEnum.image:
            return (0, exports.imageUsageToKey)(usage);
        case types_1.FileUsageTypeEnum.audio:
            return 'original';
        case types_1.FileUsageTypeEnum.video:
            return 'original';
        case types_1.FileUsageTypeEnum.embeddedArchive:
            return 'original';
        case types_1.FileUsageTypeEnum.fontGroup:
            return 'original';
        case types_1.FileUsageTypeEnum.font:
            return 'original';
        case types_1.FileUsageTypeEnum.captions:
            return 'original';
    }
};
const getFileUsage = (type) => (value) => {
    if (!value || !value.id)
        return null;
    const res = Object.assign(Object.assign({}, value), { type });
    res.key = getKeyByUsage(res);
    return res;
};
exports.getFileUsage = getFileUsage;
exports.getCommonUsage = (0, exports.getFileUsage)(types_1.FileUsageTypeEnum.common);
exports.getImageUsage = (0, exports.getFileUsage)(types_1.FileUsageTypeEnum.image);
exports.getAudioUsage = (0, exports.getFileUsage)(types_1.FileUsageTypeEnum.audio);
exports.getVideoUsage = (0, exports.getFileUsage)(types_1.FileUsageTypeEnum.video);
exports.getCaptionsUsage = (0, exports.getFileUsage)(types_1.FileUsageTypeEnum.captions);
exports.getEmbeddedArchiveUsage = (0, exports.getFileUsage)(types_1.FileUsageTypeEnum.embeddedArchive);
exports.getFontUsage = (0, exports.getFileUsage)(types_1.FileUsageTypeEnum.font);
const getFontGroupUsages = (styleSchema) => {
    const res = { type: types_1.FileUsageTypeEnum.fontGroup, id: '' };
    res.key = getKeyByUsage(res);
    const fontFamilyValues = (0, utils_1.getStyleSchemaFontFamily)(styleSchema);
    const fontGroups = fontFamilyValues
        .filter(stringFilter)
        .map((fontFamilyValue) => (0, utils_1.getFontValue)(fontFamilyValue));
    const fontUsages = fontGroups.map((fontGroup) => {
        const fontGroupOptions = 'id' in fontGroup
            ? { id: fontGroup.id, isSerif: fontGroup.isSerif }
            : { id: '', name: fontGroup.name };
        return Object.assign({}, res, fontGroupOptions);
    });
    return fontUsages;
};
exports.getFontGroupUsages = getFontGroupUsages;
