import { TabsSchemaType } from '@leenda/editor/lib/brand'
import styled from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

const TabContentsStyled = styled.div<{ $styles: ElementStyleCss<TabsSchemaType> }>`
  flex-grow: 1;
  border-bottom-right-radius: ${({ $styles }) => $styles.border.borderRadius};
  border-bottom-left-radius: ${({ $styles }) => $styles.border.borderRadius};
  overflow: hidden;
`

export default TabContentsStyled
