import React, { useCallback, useState } from 'react'
import { chainName } from 'utils'

import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import Divider from 'components/uiKit/Divider'
import Tabs, { TabPanel } from 'components/uiKit/Tabs'
import { Typography } from 'components/uiKit/Typography'
import { updateBlock } from 'services/Store/Project/actions'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getBlockId } from 'services/Store/Project/selectors'
import { t } from 'services/Translation'

import { MediaValueFormType } from '../types'
import s from './Form.module.scss'
import TimeLine from './TimeLine'
import {
  autoPlay,
  controls,
  file,
  coverImage,
  sourceType,
  url,
  volume,
  playbackRate,
  repeat,
  breakpoints,
  blackout,
  percent,
  accessibility,
  captions,
  enableCCByDefault,
} from './fieldsConfig'

const ValueForm: MediaValueFormType = ({ name, element: { value, type } }) => {
  const [tab, setTab] = useState('settings')
  const dispatch = useProjectDispatch()
  const blockId = useProjectContext(getBlockId)
  const handleChangeTab = (tabKey: string) => setTab(tabKey)

  const onChange = useCallback(
    (value: unknown) =>
      blockId && dispatch(updateBlock({ id: blockId, name: `${name}.timePoints`, value })),
    [blockId, dispatch, name],
  )

  return (
    <Padding sides>
      <Tabs activeKey={tab} name='mediaForm' onChange={handleChangeTab} styleType='clear'>
        <TabPanel tabKey='settings' title={t('page.editor.settings')}>
          <div className={s.tabContent}>
            <RealtimeField config={chainName(name, sourceType)} />
            <RealtimeField config={chainName(name, url(value))} />
            <RealtimeField config={chainName(name, file(value))} />
            <Divider />
            <RealtimeField config={chainName(name, controls)} />
            <RealtimeField config={chainName(name, autoPlay)} />
            <RealtimeField config={chainName(name, repeat)} />
            <RealtimeField config={chainName(name, volume)} />
            <RealtimeField config={chainName(name, playbackRate)} />
            <RealtimeField config={chainName(name, breakpoints)} />
            <Divider />
            <RealtimeField config={chainName(name, percent(type))} />
            <RealtimeField config={chainName(name, coverImage)} />
            <RealtimeField config={chainName(name, blackout)} />
            {type === 'video' && <RealtimeField config={chainName(name, accessibility)} />}
            <Typography indents={{ marginBottom: 16 }} styleType='hint'>
              {t('elements.media.form.ccHint')}
            </Typography>
            <RealtimeField config={chainName(name, captions)} />
            <RealtimeField config={chainName(name, enableCCByDefault)} />
          </div>
        </TabPanel>
        <TabPanel tabKey='timeline' title={t('page.editor.line')}>
          <TimeLine
            duration={value.duration}
            onChange={onChange}
            timePoints={value.timePoints}
            type={type}
          />
        </TabPanel>
      </Tabs>
    </Padding>
  )
}

export default ValueForm
