import { LinkFontSchemaType } from '@leenda/editor/lib/brand'
import styled from 'styled-components'

import { font } from 'services/Branding/common.styled'
import { ElementFontCss } from 'services/Branding/types'

const LinkStyled = styled.span<{ $font: ElementFontCss<LinkFontSchemaType>; $label?: string }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ $label, $font }) => $label && font($font.link)}
`

export default LinkStyled
