"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileUsageTypeEnum = void 0;
var FileUsageTypeEnum;
(function (FileUsageTypeEnum) {
    FileUsageTypeEnum["common"] = "common";
    FileUsageTypeEnum["audio"] = "audio";
    FileUsageTypeEnum["video"] = "video";
    FileUsageTypeEnum["image"] = "image";
    FileUsageTypeEnum["embeddedArchive"] = "embeddedArchive";
    FileUsageTypeEnum["fontGroup"] = "fontGroup";
    FileUsageTypeEnum["font"] = "font";
    FileUsageTypeEnum["captions"] = "captions";
})(FileUsageTypeEnum || (exports.FileUsageTypeEnum = FileUsageTypeEnum = {}));
