import { THEME } from '@leenda/editor/lib/brand'
import { TableElementValue } from '@leenda/editor/lib/elements'
import { MIN_CELL_WIDTH } from '@leenda/editor/lib/elements/elements/Table/constants'
import classNames from 'classnames'
import { produce } from 'immer'

import { IconButton } from 'components/uiKit/Button'
import ColorPicker from 'components/uiKit/ColorPicker'
import Dropdown, { Tooltip } from 'components/uiKit/Dropdown'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import Switcher from 'components/uiKit/Switcher/Switcher'
import { t } from 'services/Translation'

import { useTableContext } from '../../TableContext'
import s from './OptionsMenu.module.scss'

type OptionsMenuProps = {
  containerWidth?: number
}

const OptionsMenu = ({ containerWidth }: OptionsMenuProps) => {
  const { addRow, addColumn, tableValue, onTableChange, selectedCells, setSelected } =
    useTableContext()
  const handleHeaderRowChange = (value: boolean) => {
    onTableChange({
      ...tableValue,
      headerRow: value,
    })
  }

  const handleHeaderColumnChange = (value: boolean) => {
    onTableChange({
      ...tableValue,
      headerColumn: value,
    })
  }

  const handleResize = () => {
    if (!containerWidth) {
      return
    }
    const width = (containerWidth - 1) / tableValue.columns.length
    onTableChange({
      ...tableValue,
      columns: tableValue.columns.map((col) => ({
        ...col,
        width: Math.max(width, MIN_CELL_WIDTH),
      })),
    })
    setSelected(null)
  }

  const getColor = () => {
    if (selectedCells.length === 0) {
      return THEME.primaryBackgroundColor
    }
    const initColor = tableValue.cells[selectedCells[0].row][selectedCells[0].col]?.color

    if (selectedCells.some(({ row, col }) => tableValue.cells[row][col]?.color !== initColor)) {
      return THEME.primaryBackgroundColor
    }

    return initColor || THEME.primaryBackgroundColor
  }

  // const mergeCells = () => {
  //   const isRowSelected = selectedCells.reduce((acc, cell) => {
  //     if (!acc) {
  //       return false
  //     }
  //     return acc && cell.row === selectedCells[0].row
  //   }, true)

  //   const isColumnSelected = selectedCells.reduce((acc, cell) => {
  //     if (!acc) {
  //       return false
  //     }
  //     return acc && cell.col === selectedCells[0].col
  //   }, true)

  //   const newTableValue = {
  //     ...tableValue,
  //     cells: produce(tableValue.cells, (draft) => {
  //       if (isRowSelected) {
  //         const rowIndex = selectedCells[0].row
  //         draft[rowIndex] = draft[rowIndex].map((cell, index) => {
  //           if (index === selectedCells[0].col && cell) {
  //             return {
  //               ...cell,
  //               colspan: selectedCells.length,
  //             }
  //           }
  //           if (
  //             index > selectedCells[0].col &&
  //             index <= selectedCells[selectedCells.length - 1].col
  //           ) {
  //             return null
  //           }
  //           return cell
  //         })
  //       }

  //       if (isColumnSelected) {
  //         draft.forEach((row, rowIndex) => {
  //           if (rowIndex === selectedCells[0].row && row[selectedCells[0].col]) {
  //             row[selectedCells[0].col] = {
  //               ...(row[selectedCells[0].col] as Cell),
  //               rowspan: selectedCells.length,
  //             }
  //           }
  //           if (
  //             rowIndex > selectedCells[0].row &&
  //             rowIndex <= selectedCells[selectedCells.length - 1].row
  //           ) {
  //             row[selectedCells[0].col] = null
  //           }
  //         })
  //       }
  //     }),
  //   }
  //   onTableChange(newTableValue)
  // }

  // const hasMergedCells = selectedCells.reduce((acc, cell) => {
  //   if (!acc) {
  //     return false
  //   }

  //   const colspan = tableValue.cells[cell.row][cell.col]?.colspan ?? 1
  //   const rowspan = tableValue.cells[cell.row][cell.col]?.rowspan ?? 1
  //   return acc && (colspan > 1 || rowspan > 1)
  // }, true)

  const handleColorChange = (color: string | null) => {
    onTableChange(
      produce(tableValue, (draft: TableElementValue) => {
        selectedCells.forEach(({ row, col }) => {
          if (draft.cells[row][col] !== null && color) {
            draft.cells[row][col]!.color = color
          }
        })
      }),
    )
  }

  return (
    <div className={s.options}>
      <Dropdown
        overlay={
          <div>
            <div className={s.dropdownItem}>
              <span className={s.label}>
                <Icon name='iconsOtherLine' size={KitSize.XS} />
                {t('elements.table.options.row')}
              </span>
              <Switcher
                name='rowHeader'
                onChange={() => handleHeaderRowChange(!tableValue.headerRow)}
                value={tableValue.headerRow}
              />
            </div>
            <div className={s.dropdownItem}>
              <span className={s.label}>
                <Icon name='iconsOtherRow' size={KitSize.XS} />
                {t('elements.table.options.column')}
              </span>
              <Switcher
                name='columnHeader'
                onChange={() => handleHeaderColumnChange(!tableValue.headerColumn)}
                value={tableValue.headerColumn}
              />
            </div>
          </div>
        }
        overlayStyle={{ width: 280 }}
        placement='bottomLeft'
        styleType='clear'
      >
        <div className={s.settings}>
          {t('common.settings')}
          <Icon name='otherDown' />
        </div>
      </Dropdown>
      <div className={classNames(s.actions, s.borders)}>
        <Tooltip overlay={t('elements.table.backgroundColor')}>
          <ColorPicker
            icon='otherBg'
            name='cellBackgroundColor'
            onChange={handleColorChange}
            size={KitSize.S}
            value={getColor()}
            transparent
          />
        </Tooltip>
        {/* {!!selectedCells.length && (
          <Icon
            name='iconsOtherTableEditTableEraser'
            onClick={mergeCells}
            size={KitSize.medium}
            wrapperClassName={classNames(s.eraseIcon, { [s.iconActive]: hasMergedCells })}
          />
        )} */}
      </div>
      <div className={s.actions}>
        <Tooltip overlay={t('elements.table.align')}>
          <IconButton
            icon='builderArrowScale'
            name='resize-table-button'
            onClick={handleResize}
            size={KitSize.S}
            styleType='ghost'
          />
        </Tooltip>
        <Tooltip overlay={t('elements.table.addColumn')}>
          <IconButton
            icon='iconsOtherAddColumn'
            name='add-table-column-button'
            onClick={addColumn}
            size={KitSize.S}
            styleType='ghost'
          />
        </Tooltip>
        <Tooltip overlay={t('elements.table.addRow')}>
          <IconButton
            icon='iconsOtherAddRow'
            name='add-table-row-button'
            onClick={addRow}
            size={KitSize.S}
            styleType='ghost'
          />
        </Tooltip>
      </div>
    </div>
  )
}

export default OptionsMenu
