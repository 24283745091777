import { useMutation, useQuery } from '@apollo/client'
import { getOperationName } from '@apollo/client/utilities'

import { FontsGroupAllQueryVariables, FontGroupByIdQueryVariables } from 'gql/__generated__/graphql'

import { fontGroupArchive, fontGroupCreate, fontGroupEdit } from './gql/mutations'
import { fontsGroupAll, fontGroupById } from './gql/queries'

export const useFontGroupById = (variables: FontGroupByIdQueryVariables) =>
  useQuery(fontGroupById, {
    variables,
    onError: (err) =>
      console.error('"useFontGroupById" fn is crashed on operation: "useQuery"', err),
  })

export const useFontsGroupAll = (variables: FontsGroupAllQueryVariables) =>
  useQuery(fontsGroupAll, {
    variables,
    fetchPolicy: 'cache-and-network',
    onError: (err) =>
      console.error('"useFontsGroupAll" fn is crashed on operation: "useQuery"', err),
  })

export const useFontGroupCreate = () =>
  useMutation(fontGroupCreate, {
    onError: (err) =>
      console.error('"useFontGroupCreate" fn is crashed on operation: "useMutation"', err),
    refetchQueries: [String(getOperationName(fontsGroupAll))],
  })

export const useFontGroupEdit = () =>
  useMutation(fontGroupEdit, {
    onError: (err) =>
      console.error('"useFontGroupEdit" fn is crashed on operation: "useMutation"', err),
    refetchQueries: [String(getOperationName(fontsGroupAll))],
  })

export const useFontGroupArchive = () =>
  useMutation(fontGroupArchive, {
    onError: (err) =>
      console.error('"useFontGroupArchive" fn is crashed on operation: "useMutation"', err),
    refetchQueries: [String(getOperationName(fontsGroupAll))],
  })
