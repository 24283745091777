import { useContextSelector } from 'use-context-selector'

import SidebarContext, { ISidebarContext } from './SidebarContext'

export const useCourseSidebarData = (): ISidebarContext => {
  const font = useContextSelector(SidebarContext, ({ font }) => font)
  const sectionId = useContextSelector(SidebarContext, ({ sectionId }) => sectionId)
  const styles = useContextSelector(SidebarContext, ({ styles }) => styles)
  const search = useContextSelector(SidebarContext, ({ search }) => search)
  const sectionTree = useContextSelector(SidebarContext, ({ sectionTree }) => sectionTree)
  const sections = useContextSelector(SidebarContext, ({ sections }) => sections)
  const onSearchResultSectionPick = useContextSelector(
    SidebarContext,
    ({ onSearchResultSectionPick }) => onSearchResultSectionPick,
  )
  const onSelectSection = useContextSelector(
    SidebarContext,
    ({ onSelectSection }) => onSelectSection,
  )
  const getSectionProgress = useContextSelector(
    SidebarContext,
    ({ getSectionProgress }) => getSectionProgress,
  )
  const getActiveSection = useContextSelector(
    SidebarContext,
    ({ getActiveSection }) => getActiveSection,
  )
  const getDisabledSection = useContextSelector(
    SidebarContext,
    ({ getDisabledSection }) => getDisabledSection,
  )

  return {
    sectionId,
    font,
    styles,
    search,
    sectionTree,
    sections,
    onSearchResultSectionPick,
    onSelectSection,
    getSectionProgress,
    getActiveSection,
    getDisabledSection,
  }
}
