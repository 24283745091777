import lodash from 'lodash'
import * as R from 'ramda'
import { useMemo } from 'react'
import { useContextSelector } from 'use-context-selector'

import { RealtimeFormContext } from 'components/controls/RealtimeForm'
import { selectRealtimeUsers } from 'services/Store/Users/selectors'
import { useAppSelector } from 'services/Store/hooks'

import { getParams } from '../Field'
import { IFieldConfig, IFieldMeta, IOnChangeData } from '../Field/Field.types'
import { mergeParams } from '../Field/genField'
import { validateRules } from '../Field/rules'

export const useRealtimeOnChange = <Data>(config: IFieldConfig<Data>, data: Data) => {
  const onChangeContext = useContextSelector(RealtimeFormContext, (c) => c.onChange)

  const onChangeEffect = async (changeData: IOnChangeData, e?: unknown) => {
    const newChangeData = await config.effect?.(data, changeData)
    return onChangeContext(newChangeData || changeData, e)
  }

  if (config.effect) {
    return onChangeEffect
  }

  return onChangeContext
}

export const useRealtimeFieldSize = () => useContextSelector(RealtimeFormContext, (c) => c.size)

export const useRealtimeData = () => useContextSelector(RealtimeFormContext, (c) => c.data)

export const useRealtimeDefaultData = () =>
  useContextSelector(RealtimeFormContext, (c) => c.defaultData)

export const useRealtimeDefaultValue = <Data>(config: IFieldConfig<Data>, defaultData?: Data) => {
  return useMemo(() => lodash.get(defaultData, config.name || ''), [defaultData, config])
}

export const useRealtimeValue = <Data>(config: IFieldConfig<Data>, data: Data) =>
  useMemo(() => lodash.get(data, config.name || ''), [data, config])

export const useFieldParams = <Data>(
  config: IFieldConfig<Data>,
  data: Data,
  defaultData?: Data,
) => {
  const hookParams = config.useParams?.(
    R.mergeDeepRight(defaultData || {}, data || {}) as Data,
    config,
  )

  return useMemo(
    () =>
      mergeParams(
        getParams(config, R.mergeDeepRight(defaultData || {}, data || {}) as Data),
        hookParams,
      )(R.mergeDeepRight(defaultData || {}, data || {}) as Data, config as IFieldConfig<unknown>),
    [data, defaultData, config, hookParams],
  )
}

export const useRealtimeMeta = <Data>(
  config: IFieldConfig<Data>,
  data: Data,
  defaultData: Data | undefined,
  value: unknown,
  touched: boolean,
): IFieldMeta => {
  const user = useAppSelector((state) => selectRealtimeUsers(state, { field: config.name }))[0]
  const rules = config.rules
  const validateError = validateRules(value, rules)
  const params = useFieldParams(config, data, defaultData)

  return {
    disabled: params.disabled || false,
    hidden: params.hidden || false,
    valid: !validateError,
    error: validateError,
    touched,
    // TODO: move it outside
    ...(user && {
      focus: true,
      user: { ...user.employee, name: user.employee.kUser.name },
    }),
  }
}

export const useOnFocusField = () => useContextSelector(RealtimeFormContext, (c) => c.onFocusField)

export const useOnBlurField = () => useContextSelector(RealtimeFormContext, (c) => c.onBlurField)
