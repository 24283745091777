import { CourseStructureFontSchemaType, CourseStructureSchemaType } from '@leenda/editor/lib/brand'
import { CourseStructureItem } from '@leenda/editor/lib/elements'
import cn from 'classnames'
import React from 'react'

import Highlighter from 'components/uiKit/Highlighter'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { BrandType, ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { useProjectT } from 'services/ProjectLabels'
import { scrollProps } from 'services/Scroll/ScrollService'
import { BlockMode, SectionTypeEnum } from 'services/Store/Project/enums'
import { getEnumLabel } from 'utils/enum'
import { testPropsEl } from 'utils/test/qaData'

import s from './StructureItemElement.module.scss'

const IconMap = {
  [SectionTypeEnum.landing]: <Icon name='contentsLanding' size={KitSize.S} />,
  [SectionTypeEnum.test]: <Icon name='contentsTest' size={KitSize.S} />,
  [SectionTypeEnum.cover]: <Icon name='contentsSlides' size={KitSize.S} />,
  [SectionTypeEnum.chapter]: <Icon name='contentsChapter' size={KitSize.S} />,
}

const IconImageMap = (styles: ElementStyleCss<CourseStructureSchemaType>) => ({
  [SectionTypeEnum.chapter]: styles?.chapterIcons.backgroundImage,
  [SectionTypeEnum.landing]: styles?.sectionIcons.backgroundImage,
  [SectionTypeEnum.test]: styles?.testIcons.backgroundImage,
  [SectionTypeEnum.cover]: undefined,
})

const NameMap = {
  [SectionTypeEnum.landing]: (pt: (key: keyof BrandType['labels']) => string) =>
    pt('courseStructure.landing'),
  [SectionTypeEnum.test]: (pt: (key: keyof BrandType['labels']) => string) =>
    pt('courseStructure.test'),
  [SectionTypeEnum.cover]: (pt: (key: keyof BrandType['labels']) => string) =>
    pt('courseStructure.cover'),
  [SectionTypeEnum.chapter]: (pt: (key: keyof BrandType['labels']) => string) =>
    pt('courseStructure.chapter'),
}

export const useDescription = (
  font: ElementFontCss<CourseStructureFontSchemaType>,
  item: CourseStructureItem,
  sectionCount?: number,
) => {
  const pt = useProjectT()
  if (item.type === SectionTypeEnum.landing) {
    return null
  }
  const { blocks, type } = item
  const name = NameMap[type as SectionTypeEnum]

  const blocksCount = blocks.length
  // TODO: Separate logic for editor and preview to the random constraint
  const questionsCount = blocks.filter(({ mode }) => mode === BlockMode.questions).length

  const countDetails = `${
    type === SectionTypeEnum.test
      ? `${questionsCount} ${pt('courseStructure.questions')}`
      : `${sectionCount} ${pt('courseStructure.sections')}`
  }`
  return (
    blocksCount !== undefined && (
      <div className={cn(s.details)} style={font?.description}>
        <span>{name(pt) || getEnumLabel('SectionTypeEnum', item.type)}</span>
        <span className={s.separator}>&#45;</span>
        <span>{countDetails}</span>
      </div>
    )
  )
}

interface IItemsProps {
  item: CourseStructureItem
  onSelect?: (sectionId: string) => void
  searchValue?: string
  font: ElementFontCss<CourseStructureFontSchemaType>
  styles: ElementStyleCss<CourseStructureSchemaType>
  showIcon?: boolean
  marginLeft?: number
  disabled?: boolean
}

const StructureItemElement: React.FC<IItemsProps> = ({
  item,
  onSelect,
  searchValue,
  font,
  styles,
  showIcon,
  marginLeft,
  disabled,
}) => {
  const pt = useProjectT()
  const type = item.type as SectionTypeEnum
  const iconBg = IconImageMap(styles)[type]
  const titleStyle = item.isChapter ? font?.chapter : font?.section
  const icon = IconMap[type]

  const styleFontSection = {
    ...(item.isChapter ? font?.chapter : font?.section),
    ...(disabled && { color: styles.item.color }),
  }

  const iconStyles = {
    ...(showIcon && { backgroundImage: iconBg }),
    marginRight: styles.offsetFromIcon.marginRight,
    marginLeft,
  }

  return (
    <>
      {showIcon && (
        <div className={s.iconImage} style={iconStyles}>
          {!iconBg && icon}
        </div>
      )}
      <div
        {...testPropsEl('sectionItem', { label: item.name }, { id: item.id })}
        {...scrollProps(item.id)}
        className={s.item}
        onClick={() => onSelect?.(item.id)}
        style={{ color: titleStyle?.color, ...(!showIcon && { marginLeft }) }}
      >
        <div className={s.itemContent}>
          <div className={s.itemTitle} style={styleFontSection}>
            {item.name ? (
              <Highlighter search={searchValue} text={item.name} />
            ) : (
              pt('courseStructure.chapterName')
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default StructureItemElement
