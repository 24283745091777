import { AccordionSchemaType, SCHEMA } from '@leenda/editor/lib/brand'

import { genField } from 'components/controls/Field'
import { IArrayOption } from 'components/controls/inputComponents/FieldNumber/FieldNumber'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import {
  getBrandParent,
  OFFSET_ARRAY,
  simplifyStyleValue,
} from 'services/Branding/constants/fields'
import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

import accordionIndents from './assets/accordionIndents.svg'
import componentIndents from './assets/componentIndents.svg'
import contentIndents from './assets/contentIndents.svg'
import iconIndents from './assets/iconIndents.svg'

const INTERVAL_ARRAY: IArrayOption[] = [
  {
    icon: (
      <div>
        <Icon name='builderInputUp' />
        <Icon name='builderInputDown' />
      </div>
    ),
  },
]

const ICON_INDENTS_ARRAY: IArrayOption[] = [
  { icon: <Icon name='builderInputForward' /> },
  { icon: <Icon name='builderInputBack' /> },
]

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.accordion.style, {
  collapse: {
    META: { label: t('elements.accordion.tags.accordion') },
    backgroundColor: {},
  },
  hover: {
    META: { hidden: true },
    backgroundColor: {
      label: t('style.hoverColor'),
      params: {
        resettable: true,
      },
    },
  },
  border: {
    META: { hidden: true },
    borderRadius: {},
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
  },
  borderTop: {
    META: { hidden: true },
    borderTopStyle: {
      params: (data, { name }) => {
        const { parent } = getBrandParent<AccordionSchemaType>(name, data, 2)
        const borderStyle = simplifyStyleValue(parent?.border?.borderStyle)

        return {
          hidden: borderStyle !== 'none',
        }
      },
    },
    borderTopColor: {
      params: (data, { name }) => {
        const { parent } = getBrandParent<AccordionSchemaType>(name, data, 2)
        const borderStyle = simplifyStyleValue(parent?.border?.borderStyle)
        const borderTopStyle = simplifyStyleValue(parent?.borderTop?.borderTopStyle)

        return {
          hidden: borderTopStyle === 'none' || borderStyle !== 'none',
        }
      },
    },
    borderTopWidth: {
      params: (data, { name }) => {
        const { parent } = getBrandParent<AccordionSchemaType>(name, data, 2)
        const borderStyle = simplifyStyleValue(parent?.border?.borderStyle)
        const borderTopStyle = simplifyStyleValue(parent?.borderTop?.borderTopStyle)

        return {
          hidden: borderTopStyle === 'none' || borderStyle !== 'none',
        }
      },
    },
  },
  icon: {
    META: { label: t('elements.accordion.tags.icon') },
    position: genField({
      type: 'segmented',
      layout: ['horizontal', 'solid'],
      label: t('elements.accordion.schema.place'),
      params: {
        options: [
          { label: <Icon name='builderArrowLeft' size={KitSize.S} />, value: 'left' },
          { label: <Icon name='builderArrowRight' size={KitSize.S} />, value: 'right' },
        ],
      },
    }),
    backgroundImage: {
      label: t('elements.accordion.schema.closed'),
      params: { preview: false },
    },
    backgroundOpen: genField({
      type: 'fileId',
      layout: 'vertical',
      params: { fileType: 'image' },
      label: t('elements.accordion.schema.open'),
    }),
  },
  indents: {
    META: { label: t('elements.accordion.tags.indents') },
    accordion: genField({
      type: 'number',
      label: t('elements.accordion.schema.accordionIndents'),
      layout: 'vertical',
      info: <img alt='image' src={accordionIndents} />,
      params: { array: OFFSET_ARRAY },
    }),
    content: genField({
      type: 'number',
      label: t('elements.accordion.schema.contentIndents'),
      layout: 'vertical',
      info: <img alt='image' src={contentIndents} />,
      params: { array: OFFSET_ARRAY },
    }),
    gap: {
      label: t('elements.accordion.schema.componentInterval'),
      layout: 'vertical',
      info: <img alt='image' src={componentIndents} />,
      params: { array: INTERVAL_ARRAY },
    },
    icon: genField({
      type: 'number',
      label: t('input.label.iconIndents'),
      layout: 'vertical',
      info: <img alt='image' src={iconIndents} />,
      params: { array: ICON_INDENTS_ARRAY },
    }),
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.accordion.font, FONT_SCHEMA_FIELDS)
