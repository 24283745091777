export type Params = typeof PARAMS

export const PARAMS = {
  companyId: ':companyId',
  projectId: ':projectId',
  brandId: ':brandId',
  brandTab: ':tab',
  brandElement: ':brandElement',
  categoryId: ':categoryId',
  editableCategory: ':editableCategory',
  fileMetaGroupId: ':fileMetaGroupId',
  sectionId: ':sectionId',
  blockId: ':blockId',
  nodeId: ':nodeId',
  taskId: ':taskId',
  fileId: ':fileId',
  previewSize: ':previewSize',
  activeTab: ':activeTabEnum',
  userId: ':userId',
  employeeId: ':employeeId',
  collectionId: ':collectionId',
  tagId: ':tagId',
  publicLinkId: ':publicLinkId',
  dashboardTag: ':dashboardTag',
  courseId: ':courseId',
  version: ':version',
  employeeTab: ':employeeTab',
  ssoId: ':ssoId',
  settingsTag: ':settingsTag',
  projectSettingsTab: ':projectSettingsTab',
} as const

export const PATHS = {
  app: '/app',
  auth: '/auth',
  error: '/error',
  public: '/public',
  lms: '/lms',
  dev: '/dev',
} as const

export const APP_PATHS = {
  home: `${PATHS.app}/h`,
  company: `${PATHS.app}/${PARAMS.companyId}`,
  admin: `${PATHS.app}/admin`,
} as const

export const LMS_PATHS = {
  home: `${PATHS.lms}`,
  course: `${PATHS.lms}/course/${PARAMS.courseId}`,
  player: `${PATHS.lms}/player/${PARAMS.courseId}`,
} as const

export const DEV_PATHS = {
  main: `${PATHS.dev}`,
  testData: `${PATHS.dev}/test-data`,
} as const

export const AUTH_PATHS = {
  login: `${PATHS.auth}/login`,
  logout: `${PATHS.auth}/logout`,
  signUp: `${PATHS.auth}/signup`,
  // policy: `${PATHS.auth}/privacy-policy`,
  // terms: `${PATHS.auth}/term-of-use`,
  // support: `${PATHS.auth}/support`,
  regSuccess: `${PATHS.auth}/registration-success`,
  regFailure: `${PATHS.auth}/registration-failure`,
  regConfirm: `${PATHS.auth}/registration-confirm`,
  restorePass: `${PATHS.auth}/restore-password`,
  newPass: `${PATHS.auth}/new-password`,
  createPass: `${PATHS.auth}/create-password`,
  welcomeBack: `${PATHS.auth}/welcome-back`,
  sso: `${PATHS.auth}/sso/${PARAMS.ssoId}?`,
} as const

export const ERROR_PATHS = {
  main: PATHS.error,
  notFound: `${PATHS.error}/404`,
  invalidLink: `${PATHS.error}/invalid-link`,
} as const

export const PUBLIC_PATHS = {
  main: `${PATHS.public}/${PARAMS.publicLinkId}/${PARAMS.sectionId}?/${PARAMS.blockId}?`,
} as const

export const HOME_PATHS = {
  home: `${APP_PATHS.home}`,
  companies: `${APP_PATHS.home}/companies`,
  user: `${APP_PATHS.home}/user`,
} as const

export const COMPANY_PATHS = {
  main: `${APP_PATHS.company}`,
  project: `${APP_PATHS.company}/project/${PARAMS.projectId}`,
  catalog: `${APP_PATHS.company}/projects/${PARAMS.categoryId}`,
  transfer: `${APP_PATHS.company}/projects/${PARAMS.categoryId}/transfer`,
  files: `${APP_PATHS.company}/sources/${PARAMS.fileMetaGroupId}`,
  employees: `${APP_PATHS.company}/employees`,
  trello: `${APP_PATHS.company}/trello`,
  dashboard: `${APP_PATHS.company}/dashboard/${PARAMS.dashboardTag}?`,
  tags: `${APP_PATHS.company}/tags`,
  brands: `${APP_PATHS.company}/brands`,
  fonts: `${APP_PATHS.company}/fonts`,
  settings: `${APP_PATHS.company}/settings/${PARAMS.settingsTag}?`,
  // profile: `${APP_PATHS.company}/profile`,
  collections: `${APP_PATHS.company}/collections`,
} as const

export const COMPANY_EMPLOYEES_PATHS = {
  main: `${COMPANY_PATHS.employees}`,
  employee: `${COMPANY_PATHS.employees}/employee/${PARAMS.employeeId}/${PARAMS.employeeTab}?`,
} as const

export const COMPANY_BRANDS_PATHS = {
  brands: `${COMPANY_PATHS.brands}`,
  brand: `${COMPANY_PATHS.brands}/brand/${PARAMS.brandId}/${PARAMS.brandTab}/${PARAMS.brandElement}?`,
  element: `${COMPANY_PATHS.brands}/brand/${PARAMS.brandId}/${PARAMS.brandTab}/${PARAMS.brandElement}`,
} as const

export const PROJECT_PATHS = {
  main: `${COMPANY_PATHS.project}`,
  home: `${COMPANY_PATHS.project}/home`,
  editor: `${COMPANY_PATHS.project}/editor/${PARAMS.sectionId}/block/${PARAMS.blockId}?/node/${PARAMS.nodeId}?`,
  preview: `${COMPANY_PATHS.project}/preview/${PARAMS.sectionId}?/block/${PARAMS.blockId}?`,
  previewPdf: `${COMPANY_PATHS.project}/pdf/${PARAMS.sectionId}?/block/${PARAMS.blockId}?`,
} as const

export const PROJECT_HOME_PATHS = {
  main: PROJECT_PATHS.home,
  info: `${PROJECT_PATHS.home}/info`,
  files: `${PROJECT_PATHS.home}/sources/${PARAMS.fileMetaGroupId}`,
  users: `${PROJECT_PATHS.home}/users`,
  export: `${PROJECT_PATHS.home}/export`,
  cover: `${PROJECT_PATHS.home}/cover`,
  trello: `${PROJECT_PATHS.home}/trello`,
  sections: `${PROJECT_PATHS.home}/sections/${PARAMS.sectionId}?`,
  chat: `${PROJECT_PATHS.home}/chat`,
} as const

export const PROJECT_TRELLO_PATHS = {
  main: `${PROJECT_HOME_PATHS.trello}`,
  task: `${PROJECT_HOME_PATHS.trello}/${PARAMS.taskId}`,
} as const

export const PROJECT_INFO_PATHS = {
  main: `${PROJECT_HOME_PATHS.info}/${PARAMS.projectSettingsTab}?`,
} as const

export const PROJECT_EXPORT_PATHS = {
  main: `${PROJECT_HOME_PATHS.export}`,
  history: `${PROJECT_HOME_PATHS.export}/history`,
} as const

export const COMPANY_FILES_PATHS = filesPath(COMPANY_PATHS.files)
export const PROJECT_FILES_PATHS = filesPath(PROJECT_HOME_PATHS.files)
export const COMPANY_TRELLO_PATHS = {
  main: `${COMPANY_PATHS.trello}`,
  task: `${COMPANY_PATHS.trello}/${PARAMS.taskId}`,
} as const

export const COMPANY_COLLECTION_PATHS = {
  collections: `${COMPANY_PATHS.collections}`,
  collection: `${COMPANY_PATHS.collections}/collection/${PARAMS.collectionId}`,
  preview: `${COMPANY_PATHS.collections}/preview/${PARAMS.collectionId}`,
} as const

export const EDITOR_PATHS = {
  main: `${PROJECT_PATHS.editor}`,
} as const

export const SCORM_PATHS = {
  mainV2: `/static-scorm-v2/${PARAMS.projectId}/${PARAMS.version}/(section)?/${PARAMS.sectionId}?/(block)?/${PARAMS.blockId}?`,
  mainV1: `/static-scorm/${PARAMS.projectId}/(section)?/${PARAMS.sectionId}?/(block)?/${PARAMS.blockId}?`,
  fallback: `/static-scorm-fallback/${PARAMS.projectId}`,
} as const

export const PDF_PATHS = {
  main: `/static-pdf/${PARAMS.projectId}`,
} as const

export const ADMIN_PATHS = {
  main: `${APP_PATHS.admin}`,
  companies: `${APP_PATHS.admin}/companies`,
  company: `${APP_PATHS.admin}/company/${PARAMS.companyId}`,
  users: `${APP_PATHS.admin}/users`,
} as const

export const ADMIN_COMPANIES = {
  main: ADMIN_PATHS.companies,
  company: `${ADMIN_PATHS.companies}/${PARAMS.companyId}`,
  users: `${ADMIN_PATHS.companies}/${PARAMS.companyId}/users`,
} as const

export const ADMIN_USERS = {
  main: ADMIN_PATHS.users,
  user: `${ADMIN_PATHS.users}/${PARAMS.userId}`,
} as const

function filesPath<T extends string>(match: T) {
  return {
    main: `${match}`,
    preview: `${match}/preview/${PARAMS.fileId}`,
    // add: `${match}/add`,
    edit: `${match}/edit/${PARAMS.editableCategory}`,
    move: `${match}/move`,
    delete: `${match}/delete`,
  } as const
}
