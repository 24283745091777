import { TabsFontSchemaType, TabsSchemaType } from '@leenda/editor/lib/brand'
import { useSize } from 'ahooks'
import cn from 'classnames'
import React, { useState, useEffect, ReactNode, useRef, useCallback } from 'react'

import { Tooltip } from 'components/uiKit/Dropdown'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { testPropsEl } from 'utils/test/qaData'

import TabContentStyled from '../styled/TabContentStyled'
import TabContentsStyled from '../styled/TabContentsStyled'
import TabControlsStyled from '../styled/TabControlsStyled'
import TabItemStyled from '../styled/TabItemStyled'
import TabListStyled from '../styled/TabListStyled'
import TabListWrapStyled from '../styled/TabListWrapStyled'
import { ITabPanelProps } from './TabPanel/TabPanel'
import s from './Tabs.module.scss'
import iconLeftDefault from './icon_left.svg'
import iconRightDefault from './icon_right.svg'
import { useMobileEvents } from './useMobileEvents'

export interface ITabsProps {
  children: ReactNode
  name: string
  defaultActiveKey?: string
  onChange: (tabKey: string) => void
  styles: ElementStyleCss<TabsSchemaType>
  activeKey?: string
  mobile: boolean
  font: ElementFontCss<TabsFontSchemaType>
}

const OFFSET_TAB_OF_CONTAINER = 70

const Tabs: React.FC<ITabsProps> = ({
  children,
  name,
  onChange,
  styles,
  defaultActiveKey,
  activeKey: activeKeyProps,
  mobile,
  font,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const iconLeft = styles?.iconLeft.backgroundImage
  const iconRight = styles?.iconRight.backgroundImage
  const [activeKeyState, setActiveKeyState] = useState(defaultActiveKey || '')
  const [drawArrowIconRight, setDrawArrowIconRight] = useState(false)
  const [drawArrowIconLeft, setDrawArrowIconLeft] = useState(false)
  const scrollableTabsContainer = useRef<HTMLDivElement>(null)
  const activeTabItemRef = useRef<HTMLDivElement>(null)
  const size = useSize(ref)

  const tabList: string[] = []
  React.Children.forEach(children, (child: React.ReactNode) => {
    if (!React.isValidElement<ITabPanelProps>(child)) {
      return
    }
    const element: React.ReactElement<ITabPanelProps> = child

    tabList.push(element.props.tabKey)
  })

  const activeKey = tabList.includes(activeKeyState)
    ? activeKeyState
    : defaultActiveKey && tabList.includes(defaultActiveKey)
      ? defaultActiveKey
      : tabList[0]

  const handleNext = (isRight: boolean) => {
    const directionScroll = isRight ? 1 : -1

    const indexItem = tabList.indexOf(activeKey)

    if (tabList[indexItem + directionScroll]) {
      setActiveKeyState(tabList[indexItem + directionScroll])
    }
  }

  const onChangeTab = (tabKey: string) => {
    setActiveKeyState(tabKey)
    onChange && onChange(tabKey)
  }

  const checkShowArrows = useCallback(
    (scrollLeft: number, scrollWidth: number, clientWidth: number) => {
      if (scrollLeft > 0) {
        setDrawArrowIconLeft(true)
      }
      if (scrollLeft === 0) {
        setDrawArrowIconLeft(false)
      }

      if (scrollWidth > Math.ceil(scrollLeft + clientWidth)) {
        setDrawArrowIconRight(true)
      }
      if (Math.ceil(scrollLeft + clientWidth) >= scrollWidth) {
        setDrawArrowIconRight(false)
      }
    },
    [],
  )

  const onScroll = (e: React.UIEvent<HTMLElement>) => {
    const target = e.target as HTMLElement
    const scrollLeft = target.scrollLeft || 0
    const scrollWidth = target.scrollWidth || 0
    const clientWidth = target.clientWidth || 0
    checkShowArrows(scrollLeft, scrollWidth, clientWidth)
  }

  const { onTouchEnd, onTouchMove, onTouchStart } = useMobileEvents((isRight: boolean) =>
    handleNext(isRight),
  )

  useEffect(() => {
    if (ref.current && activeTabItemRef.current && scrollableTabsContainer.current) {
      const { left: scrollContainerLeft, width: scrollContainerWidth } =
        ref.current.getBoundingClientRect()
      const { left: activeTabLeft, width: activeTabWidth } =
        activeTabItemRef.current.getBoundingClientRect()

      const shiftScroll =
        activeTabLeft -
        scrollContainerLeft -
        scrollContainerWidth +
        activeTabWidth +
        OFFSET_TAB_OF_CONTAINER
      // auto-scroll on change active tab
      scrollableTabsContainer.current.scrollLeft += shiftScroll
    }
  }, [activeKey])

  useEffect(() => {
    if (activeKeyProps !== undefined) {
      setActiveKeyState(activeKeyProps)
    }
  }, [activeKeyProps])

  useEffect(() => {
    const clientWidth = scrollableTabsContainer.current?.clientWidth || 0
    const scrollWidth = scrollableTabsContainer.current?.scrollWidth || 0
    const scrollLeft = scrollableTabsContainer.current?.scrollLeft || 0
    checkShowArrows(scrollLeft, scrollWidth, clientWidth)
  }, [size, activeKey, children, checkShowArrows])

  return (
    <div className={s.root} {...testPropsEl('tabs', { name })} ref={ref} style={styles.root}>
      <TabControlsStyled $styles={styles}>
        {drawArrowIconLeft && (
          <span className={cn(s.arrowLeft, mobile && s.mobile)} onClick={() => handleNext(false)}>
            {iconLeft ? (
              <div className={s.leftIcon} style={{ backgroundImage: iconLeft }} />
            ) : (
              <img src={iconLeftDefault} />
            )}
          </span>
        )}
        <TabListWrapStyled $styles={styles} onScroll={onScroll} ref={scrollableTabsContainer}>
          <TabListStyled $styles={styles}>
            {React.Children.map(children, (child: React.ReactNode) => {
              if (!React.isValidElement<ITabPanelProps>(child)) {
                return null
              }
              const element: React.ReactElement<ITabPanelProps> = child
              const { title, tabKey, disabled } = element.props

              const active = activeKey === tabKey

              return (
                <TabItemStyled
                  {...testPropsEl('tabItem', { value: tabKey })}
                  $active={active}
                  $font={font}
                  $styles={styles}
                  data-active={active}
                  onMouseDown={() => !disabled && onChangeTab(tabKey)}
                  ref={ref}
                >
                  {mobile ? (
                    <div className={s.title}>{title || '\u00A0'}</div>
                  ) : (
                    <Tooltip mouseEnterDelay={1} overlay={title}>
                      <div className={s.title}>{title || '\u00A0'}</div>
                    </Tooltip>
                  )}
                </TabItemStyled>
              )
            })}
          </TabListStyled>
        </TabListWrapStyled>
        {drawArrowIconRight && (
          <span className={cn(s.arrowRight, mobile && s.mobile)} onClick={() => handleNext(true)}>
            {iconRight ? (
              <div className={s.rightIcon} style={{ backgroundImage: iconRight }} />
            ) : (
              <img src={iconRightDefault} />
            )}
          </span>
        )}
      </TabControlsStyled>
      <TabContentsStyled
        $styles={styles}
        onTouchEnd={onTouchEnd}
        onTouchMove={onTouchMove}
        onTouchStart={onTouchStart}
      >
        {React.Children.map(children, (child: React.ReactNode) => {
          if (!React.isValidElement<ITabPanelProps>(child)) {
            return null
          }
          const element: React.ReactElement<ITabPanelProps> = child
          const { children, tabKey } = element.props
          if (tabKey !== activeKey) {
            return null
          }
          return (
            <TabContentStyled $hidden={tabKey !== activeKey} $styles={styles} key={tabKey}>
              {/* styles={styleContent} */}
              {children}
            </TabContentStyled>
          )
        })}
      </TabContentsStyled>
    </div>
  )
}

export default Tabs
