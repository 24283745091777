import { ApolloError } from '@apollo/client'
import cn from 'classnames'
import React, { useCallback, useRef, useState } from 'react'

import { LayoutScroll } from 'components/LayoutPage'
import { useToolbarPosition } from 'components/form/RichText/Toolbar/useToolbarPosition'
import Button, { IconButton } from 'components/uiKit/Button'
import { Tooltip } from 'components/uiKit/Dropdown'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import Loader from 'components/uiKit/Loader'
import TextArea from 'components/uiKit/TextArea'
import { Typography } from 'components/uiKit/Typography'
import { t } from 'services/Translation'

import { TEXT_AREA_CONFIG } from '../AiForm'
import s from './Response.module.scss'
import AlertIcon from './alert-404.svg'

interface IResponseProps {
  value: string
  setValue: (value: string) => void
  onSendPrompt: () => void
  onInsert: () => void
  onReplace: () => void
  responseText: React.ReactNode
  loading: boolean
  error?: ApolloError
  disabled: boolean
}

const Response: React.FC<IResponseProps> = ({
  value,
  setValue,
  onSendPrompt,
  responseText,
  loading,
  error,
  onInsert,
  onReplace,
  disabled,
}) => {
  const [prevValue, setPrevValue] = useState<string>(value)
  const [edit, setEdit] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLTextAreaElement>(null)
  useToolbarPosition(ref)

  const handleEdit = useCallback(() => {
    setEdit(true)
    setPrevValue(value)
    inputRef.current?.focus()
    inputRef.current?.setSelectionRange(
      inputRef.current.value.length,
      inputRef.current.value.length,
    )
  }, [value])

  const handleConfirmEdit = useCallback(() => {
    setEdit(false)
    onSendPrompt()
  }, [onSendPrompt])

  const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = useCallback(
    (e) => {
      if (e.key === 'Escape') {
        setValue(prevValue)
        setEdit(false)
      }
    },
    [prevValue, setValue],
  )

  return (
    <div className={cn(s.root, s.responseContainer)} ref={ref}>
      <div className={s.resultTitle}>
        <div className={s.promtTextCard}>
          <TextArea
            autoFocus={edit}
            autoSize={TEXT_AREA_CONFIG}
            lineHeight={18}
            name='editValue'
            onChange={setValue}
            onKeyDown={handleKeyDown}
            placeholder={t('elements.richText.ai.placeholder')}
            readOnly={!edit}
            ref={inputRef}
            size='mini'
            styleType='ghost'
            value={value}
          />
          {edit ? (
            <IconButton
              disabled={!value}
              icon='checkmark1'
              name='confirm'
              onClick={handleConfirmEdit}
              size={KitSize.S}
              styleType='ghost'
            />
          ) : (
            <IconButton
              disabled={loading}
              icon='penEdit'
              name='edit'
              onClick={handleEdit}
              size={KitSize.S}
              styleType='ghost'
            />
          )}
        </div>
      </div>
      {error && (
        <div className={s.errorAlert}>
          <img alt='alertIcon' src={AlertIcon} />
          <Typography styleType='hint'>{t('notify.somethingWentWrong')}</Typography>
        </div>
      )}

      {loading ? (
        <Loader name='aiResponseLoader' />
      ) : (
        <div className={s.result}>
          <LayoutScroll sizeAutoCapable>
            <div className={s.title}>
              <Icon name='builderAi' size={KitSize.S} />
              <Typography weight='medium'>{t('elements.richText.ai.result')}</Typography>
            </div>
            <div className={s.resultText}>{responseText}</div>
          </LayoutScroll>
          <div className={s.shadow} />
        </div>
      )}
      <div className={s.bottomControls}>
        <Tooltip disabled={!disabled} overlay={t('elements.richText.ai.tooltip')} placement='top'>
          <Button
            disabled={disabled || loading || edit}
            name='rewrite'
            onClick={onSendPrompt}
            size={KitSize.S}
            styleType='secondary'
          >
            {t('uiKit.button.retry')}
          </Button>
        </Tooltip>
        <div className={s.buttonsRight}>
          <Button
            disabled={loading || edit}
            name='confirm'
            onClick={onReplace}
            size={KitSize.S}
            styleType='ghost'
          >
            {t('uiKit.button.replace')}
          </Button>
          <Button
            disabled={loading || edit}
            name='insert'
            onClick={onInsert}
            size={KitSize.S}
            styleType='primary'
          >
            {t('uiKit.button.insertBelow')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Response
