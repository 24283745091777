import React, { useMemo } from 'react'
import { ThemeProvider } from 'styled-components'

import { BrandContext } from './BrandContext'
import { useBrandVars } from './hooks'
import { mergeBrands } from './mergeBrands'
import { BrandType } from './types'

interface IProps {
  companyBrand?: BrandType | null
  brand?: BrandType
  children: React.ReactNode
}

const BrandProvider: React.FC<IProps> = ({ children, companyBrand, brand }) => {
  const finalBrand = useMemo(
    () => brand || mergeBrands(companyBrand as BrandType),
    [brand, companyBrand],
  )

  useBrandVars(finalBrand)

  return (
    <BrandContext.Provider value={finalBrand}>
      <ThemeProvider theme={finalBrand.theme}>{children}</ThemeProvider>
    </BrandContext.Provider>
  )
}

export default React.memo(BrandProvider)
