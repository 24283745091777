import {
  InitialOpen,
  ProgressDescriptionPosition,
  SidebarSchemaType,
} from '@leenda/editor/lib/brand'
import cn from 'classnames'

import { LayoutScroll } from 'components/LayoutPage'
import { StructureSearchResult } from 'components/editor-v3/cource/controls/CourseSidebar/components/CourseStructure/components/StructureSearch'
import { useCourseSidebarData } from 'components/editor-v3/cource/controls/CourseSidebar/context/selectors'
import { ElementStyleCss } from 'services/Branding/types'
import ScrollService from 'services/Scroll/ScrollService'
import { ScrollContainerEnum } from 'services/Scroll/enums'
import { Project } from 'services/Store/Project/types'

import Progress, { ProgressType } from '../../components/Progress'
import { CourseHeaderLogo } from '../CourseHeader/components/CourseHeaderLogo'
import s from './CourseSidebar.module.scss'
import { CourseSearch } from './components/CourseSearch'
import CourseStructure from './components/CourseStructure/CourseStructure'
import { CourseTitle } from './components/CourseTitle'

interface ICourseSidebarProps {
  projectName?: string
  project?: Project | null
  percent: number
}

const getProgressStyle = (styles: ElementStyleCss<SidebarSchemaType>['progress']) =>
  ({
    ['--progress-borderWidth']: styles.borderWidth,
    ['--progress-borderStyle']: styles.borderStyle,
    ['--progress-borderColor']: styles.borderColor,
    ['--progress-borderRadius']: styles.borderRadius,
  }) as React.CSSProperties

const CourseSidebar: React.FC<ICourseSidebarProps> = ({ projectName, project, percent }) => {
  const {
    font,
    styles,
    search: searchValue,
    onSearchResultSectionPick,
    onSelectSection,
    sectionTree,
  } = useCourseSidebarData()

  return (
    <>
      {styles.value.showLogo && (
        <div className={s.row}>
          <CourseHeaderLogo projectLogoId={project?.company?.logo?.id} />
        </div>
      )}
      <div className={s.row}>
        <CourseTitle projectName={projectName} titleStyles={font.title} />
      </div>
      {styles.search.showSearch && (
        <div className={s.row}>
          <CourseSearch
            font={font.search}
            name='structureSearchInput'
            // eslint-disable-next-line react/forbid-component-props
            style={styles.search}
            fluid
          />
        </div>
      )}
      {!searchValue && styles.progress.showProgress && (
        <div className={s.row}>
          <div
            className={cn(s.progress, {
              [s.reverseProgress]:
                styles.progress.showDescriptionProgress === ProgressDescriptionPosition.right,
            })}
            style={getProgressStyle(styles.progress)}
          >
            {styles.progress.showDescriptionProgress !== ProgressDescriptionPosition.none && (
              <div className={s.progressDescription} style={font.progress}>
                {Number(percent.toFixed(1))}&#37;
              </div>
            )}
            <Progress
              customStyles={{
                stroke: styles.progress.color,
                trail: styles.progress.backgroundColor,
              }}
              name='curseProgress'
              percent={percent}
              size='mini'
              styleType={ProgressType.line}
              fluid
            />
          </div>
        </div>
      )}
      <div className={cn(s.row, s.resetOffset)}>
        <LayoutScroll ref={ScrollService.createContainerSetter(ScrollContainerEnum.sectionNav)}>
          <div className={s.content}>
            {searchValue ? (
              <StructureSearchResult
                onSelect={onSearchResultSectionPick}
                searchValue={searchValue}
              />
            ) : (
              <div className={s.defaultStructure} style={styles.root}>
                <CourseStructure
                  initialOpen={styles.collapse.initialOpen === InitialOpen.Expanded}
                  nodes={sectionTree}
                  onSectionPick={onSelectSection}
                />
              </div>
            )}
          </div>
        </LayoutScroll>
      </div>
    </>
  )
}

export default CourseSidebar
