import cn from 'classnames'
import React from 'react'

import { IOption, KitSize } from 'components/uiKit/KitTypes'
import { OptionLabel } from 'components/uiKit/KitUtils'
import { testProps } from 'utils/test/qaData'

import { ArrayElement, ICheckboxOption, Value } from '../Checkbox'
import { isChecked } from '../isChecked'
import s from './CheckboxItem.module.scss'
import { ReactComponent as CheckIcon } from './check.svg'

export type CheckboxDefaultType = Omit<
  React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>,
  'onChange' | 'type' | 'prefix' | 'size' | 'ref'
>

interface IProps<T extends Value | Value[] = Value> {
  name: string
  item: IOption<ArrayElement<T>>
  value?: T
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  hideCheckbox?: boolean
  disabled?: boolean
  indeterminate?: boolean
  option?: ICheckboxOption<T>
  size?: KitSize
  style?: React.CSSProperties
  testData?: string
  activeColor?: string
}

const CheckboxItem = <T extends Value | Value[] = Value>({
  item,
  value,
  onChange,
  hideCheckbox,
  disabled,
  name,
  indeterminate,
  option,
  size,
  style,
  testData,
  activeColor,
  ...rest
}: IProps<T>) => {
  const checked = isChecked(value, item, option)

  const cnRoot = cn(s.root, size && s[size], {
    [s.checked]: checked,
    [s.disabled]: disabled,
    [s.hideCheckbox]: hideCheckbox,
  })

  return (
    <label
      {...rest}
      className={cnRoot}
      {...testProps({ el: 'checkboxItem', name, value: item.value, testData })}
    >
      <input
        checked={checked}
        className={s.input}
        disabled={disabled}
        name={name}
        onChange={onChange}
        type='checkbox'
        value={String(item.value)}
      />
      {!hideCheckbox && (
        <span className={s.checkbox}>
          {indeterminate ? (
            <div className={s.indeterminate} />
          ) : (
            <span className={s.checkIcon}>{checked && <CheckIcon />}</span>
          )}
        </span>
      )}
      <span className={s.label} style={{ ...style, color: checked ? activeColor : undefined }}>
        <OptionLabel
          disabled={disabled}
          option={item.label ? item : (option as any)}
          size={KitSize.M}
        />
      </span>
    </label>
  )
}

export default CheckboxItem
