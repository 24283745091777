import { AudioFontSchemaType, AudioSchemaType } from '@leenda/editor/lib/brand'
import { VideoFontSchemaType, VideoSchemaType } from '@leenda/editor/lib/brand'
import { VideoElementValue } from '@leenda/editor/lib/elements'
import React from 'react'

import DemoWrapper from 'components/editor-v2/EditorElements/decorators/DemoWrapper'
import { IElementDemoProps } from 'components/editor-v2/EditorElements/elements.types'
import { DEMO_MODE } from 'services/Store/Project/constants'

import Element from './Element'

const DEMO_VALUE_VIDEO: VideoElementValue = {
  sourceType: 'url',
  // isCover: false,
  blackout: 'var(--brand-neutralColor)',
  url: null,
  duration: 0,
  playbackRate: 1,
  controls: true,
  autoPlay: false,
  repeat: false,
  volume: 50,
  file: null,
  coverImage: null,
  captions: null,
}

const Demo =
  (
    TYPE: 'audio' | 'video',
  ): React.FC<
    IElementDemoProps<AudioSchemaType | VideoSchemaType, AudioFontSchemaType | VideoFontSchemaType>
  > =>
  // eslint-disable-next-line react/display-name
  ({ styles, fonts }) => {
    const Demo = (
      <DemoWrapper>
        <Element
          element={{
            id: `${TYPE}-demo`,
            type: TYPE,
            value: DEMO_VALUE_VIDEO,
            style: {},
            font: {},
          }}
          font={fonts}
          mode={DEMO_MODE}
          styles={styles}
        />
      </DemoWrapper>
    )
    return Demo
  }
export default Demo
