import { PreviewMode } from 'services/Store/Project/enums'

import { TestMultipleChoiceElementType } from '../TestMultipleChoice.types'
import TestMultipleChoiceElementEditor from './TestMultipleChoiceElementEditor'
import TestMultipleChoiceElementPreview from './TestMultipleChoiceElementPreview'

const COMPONENTS_MAP: Partial<Record<PreviewMode, TestMultipleChoiceElementType>> = {
  editor: TestMultipleChoiceElementEditor,
}

const TestMultipleChoiceElement: TestMultipleChoiceElementType = ({ mode, ...rest }) => {
  const Component = COMPONENTS_MAP[mode.previewMode] || TestMultipleChoiceElementPreview
  return <Component mode={mode} {...rest} />
}

export default TestMultipleChoiceElement
