import styled from 'styled-components'

const LeftStyled = styled.div`
  display: flex;
  flex: 1 1 160px;
  align-items: center;
  margin-right: 24px;
`

export default LeftStyled
