import { AccordionSchemaType } from '@leenda/editor/lib/brand'
import styled from 'styled-components'

import { hover } from 'services/Branding/common.styled'
import { ElementStyleCss } from 'services/Branding/types'

const AccordionTitleStyled = styled.div<{
  $styles: ElementStyleCss<AccordionSchemaType>
  $open: boolean
}>`
  transition: 0.2s;
  padding: ${({ $styles }) => `${$styles.indents.accordion?.map((num) => num + 'px').join(' ')}`};
  border-radius: ${({ $styles, $open }) =>
    `${$styles.border.borderRadius} ${$styles.border.borderRadius} ${$open ? 0 : $styles.border.borderRadius} ${$open ? 0 : $styles.border.borderRadius}`};
  ${hover}
`

export default AccordionTitleStyled
