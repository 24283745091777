import { HeaderSchemaType } from '@leenda/editor/lib/brand'
import { motion } from 'framer-motion'
import styled from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

const LineStyled = styled(motion.div)<{
  $styles: ElementStyleCss<HeaderSchemaType>
  $disabled?: boolean
}>`
  position: absolute;
  width: 8px;
  height: 1px;
  left: 6px;
  background-color: ${({ $styles: { icon, disabled }, $disabled }) =>
    $disabled ? disabled.color : icon.color};
  &:nth-last-of-type(2) {
    width: 12px;
  }

  &:nth-last-of-type(3) {
    width: 15px;
  }
`

export default LineStyled
