import { TestHotspotSchemaType } from '@leenda/editor/lib/brand'
import styled, { css } from 'styled-components'

import { hover } from 'services/Branding/common.styled'
import { ElementStyleCss } from 'services/Branding/types'

const correct = ($styles: ElementStyleCss<TestHotspotSchemaType>) => css`
  background-color: ${$styles.correct.backgroundColor};
  background-image: ${$styles.correct.backgroundImage};
`

const incorrect = ($styles: ElementStyleCss<TestHotspotSchemaType>) => css`
  background-color: ${$styles.incorrect.backgroundColor};
  background-image: ${$styles.incorrect.backgroundImage};
`

const spot = ($styles: ElementStyleCss<TestHotspotSchemaType>) => css`
  background-color: ${$styles.spot.backgroundColor};
  background-image: ${$styles.spot.backgroundImage};
`

const SpotStyled = styled.div<{
  $styles: ElementStyleCss<TestHotspotSchemaType>
  $result: unknown
  $isTrue: boolean
}>`
  transition: 0.2s;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 24px;
  transform: translate(-50%, -50%);
  z-index: 10;

  width: ${({ $styles }) => $styles.spot.width};
  height: ${({ $styles }) => $styles.spot.height};
  color: ${({ $styles }) => $styles.spot.color};

  ${({ $result, $isTrue, $styles }) =>
    $result ? ($isTrue ? correct($styles) : incorrect($styles)) : spot($styles)}

  ${hover}
`

export default SpotStyled
