import { SCHEMA } from '@leenda/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.tabs.style, {
  tabs: {
    META: { label: t('elements.tabs.tags.tabs') },
    padding: { label: t('elements.tabs.schema.activeTabPaddings') },
    backgroundColor: {},
  },
  hover: {
    META: { hidden: true },
    backgroundColor: {
      label: t('style.hoverColor'),
      params: {
        resettable: true,
      },
    },
  },
  activeTab: {
    META: { label: t('elements.tabs.tags.activeTab') },
    backgroundColor: {},
    color: { label: t('input.label.textColor') },
  },
  border: {
    META: { hidden: true },
    borderRadius: {},
    borderStyle: {},
    borderColor: {},
    borderWidth: { params: { max: 8 } },
  },
  content: {
    META: { label: t('elements.tabs.tags.content') },
    backgroundColor: {},
    padding: { label: t('elements.tabs.schema.contentPaddings') },
  },
  splitter: {
    META: { label: t('elements.tabs.tags.splitter') },
    borderStyle: { label: t('input.label.showDivider') },
    borderColor: { label: t('input.label.dividerColor') },
    borderWidth: { params: { max: 8 } },
  },
  iconLeft: {
    META: { label: t('elements.tabs.tags.icon') },
    backgroundImage: { label: t('elements.tabs.schema.prev') },
  },
  iconRight: {
    META: { hidden: true },
    backgroundImage: { label: t('elements.tabs.schema.next') },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.tabs.font, {
  base: {
    ...FONT_SCHEMA_FIELDS.base,
  },
})
